import React, { useState, useContext, useEffect, useRef } from 'react';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import CartItem from '../../custom/components/molecules/ProductCard/CartItem';
import { useHistory } from 'react-router-dom';
import ProductContext from '../../custom/context/product/productContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Popup from '../../custom/components/organisms/Popup';
import ProductView from '../../custom/components/organisms/ProductView';
import { handleRedirectInternal, currencyFormat } from '../../custom/common/components';
import aes from 'crypto-js/aes';
import './Cart.css';
import { requirePropFactory } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

const Cart = () => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { getCartItems, cart_items, cancelItem, responseStatus } = useContext(ProductContext);
  const { isAuthenticated, user } = authContext;
  const { setAlert } = alertContext;
  const [carts, setCarts] = useState({});
  const [noncarts, setNoncarts] = useState([]);
  const [drawer, setDrawer] = useState({
    right: false,
    bottom: false,
    data: {},
  });

  useEffect(() => {
    if (isAuthenticated) {
      getCartItems();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (cart_items.items) {
      let locations = [];
      let cart = {};
      cart_items.items.map((item) => {
        if (locations.indexOf(item.location_id) === -1) {
          locations.push(item.location_id);
        }
        if (cart[item.location_id]) {
          cart[item.location_id].items.push(item);
        } else {
          cart[item.location_id] = {};
          cart[item.location_id].taxPercent = item.tax_percent;
          cart[item.location_id].premiumPercent = item.buyer_premium_percent;
          cart[item.location_id].items = [item];
          cart[item.location_id].city = item.locationCity;
          cart[item.location_id].locationName = item.locationName;
        }
      });
      setCarts(cart);
    }
  }, [cart_items]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'cart') {
        getCartItems();
      }
    }
  }, [responseStatus]);

  const removeFromCart = (buynowId, locationId) => {
    let cartCopy = { ...carts };
    let cartItemIndex = cartCopy[locationId].items.findIndex((item) => {
      return item.buynowId == buynowId;
    });
    if (cartItemIndex !== -1) {
      let nonCartsCopy = [...noncarts];
      nonCartsCopy.push(cartCopy[locationId].items[cartItemIndex]);
      setNoncarts(nonCartsCopy);
      cartCopy[locationId].items.splice(cartItemIndex, 1);
      setCarts(cartCopy);
    }
  };

  const addToCart = (item, locationId) => {
    let nonCartsCopy = [...noncarts];
    let nonCartIndex = nonCartsCopy.findIndex((i) => {
      return i.buynowId == item.buynowId;
    });
    nonCartsCopy.splice(nonCartIndex, 1);
    setNoncarts(nonCartsCopy);

    let cartCopy = { ...carts };
    cartCopy[locationId].items.push(item);
    setCarts(cartCopy);
  };

  const cancelItemBuynowId = useRef(null);

  const cancelItemHandler = (buynowId) => {
    cancelItemBuynowId.current = { buynowId };
    setModalOpen(true);
  };

  const cancelItemAction = () => {
    cancelItem(cancelItemBuynowId.current);
    setModalOpen(false);
    getCartItems();
  };

  const toggleFullScreenPopup = () => {
    cancelItemBuynowId.current = null;
    setModalOpen(!modalOpen);
  };

  const getTotal = (cartData, type) => {
    let totalBeforeTax = 0;
    let totalBuyerPremium = 0;
    let totalTax = 0;
    cartData.map((data) => {
      totalBeforeTax += data.amount;
      const premiumAmt = data.amount * (data.buyer_premium_percent / 100);
      totalBuyerPremium += premiumAmt;
      totalTax += (data.amount + premiumAmt) * (data.tax_percent / 100);
    });

    if (type === 'totalBeforeTax') {
      return totalBeforeTax;
    } else if (type === 'totalBuyerPremium') {
      return totalBuyerPremium;
    } else if (type === 'totalTax') {
      return totalTax;
    } else if (type === 'total') {
      return totalBeforeTax + totalBuyerPremium + totalTax;
    }
    return;
  };

  const moveToCheckout = (locationId) => {
    let checkoutCart = { ...carts[locationId] };
    checkoutCart.billingFirstName = user.first_name;
    checkoutCart.locationId = locationId;
    checkoutCart.total = getTotal(checkoutCart.items, 'total');
    checkoutCart.totalBeforeTax = getTotal(checkoutCart.items, 'totalBeforeTax');
    checkoutCart.totalBuyerPremium = getTotal(checkoutCart.items, 'totalBuyerPremium');
    checkoutCart.totalTax = getTotal(checkoutCart.items, 'totalTax');
    var ciphertext = aes
      .encrypt(JSON.stringify(checkoutCart), process.env.REACT_APP_AES_ENCRYPTION_SECRET)
      .toString();
    sessionStorage.setItem('checkout', ciphertext);
    handleRedirectInternal(history, `checkout`);
  };

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setDrawer({ ...drawer, [anchor]: open, data: data });
    } else {
      setDrawer({ ...drawer, [anchor]: open, data: {} });
    }
  };

  return (
    <DashboardLayout>
      {noncarts.length || Object.keys(carts).length ? (
        <>
          {Object.keys(carts).length ? (
            <>
              {Object.keys(carts).map((locationId, index) => (
                <React.Fragment key={index}>
                  {carts[locationId].items.length ? (
                    <>
                      <div className="cart">
                        <div className="cartCnt d-flex justify-content-between">
                          <div className="cartLt">
                            <div className="carts">
                              <div className="cartHead d-flex justify-content-start align-items-center">
                                <h1>Shopping Cart </h1>
                                <div className="ml-4">{carts[locationId].locationName}</div>
                                <span>
                                  <span>{carts[locationId].items.length} Results Found</span>
                                </span>
                              </div>

                              <div>
                                {carts[locationId].items.map((data, index) => (
                                  <React.Fragment key={index}>
                                    {/* Product View Drawer */}
                                    <Drawer
                                      className="rightDrawer"
                                      anchor={'right'}
                                      open={drawer['right']}
                                      onClose={toggleDrawer('right', false)}
                                    >
                                      <ProductView
                                        data={drawer.data}
                                        drawerHandler={toggleDrawer('right', false)}
                                      />
                                    </Drawer>
                                    <CartItem
                                      data={data}
                                      from="cart"
                                      cancelItem={cancelItemHandler}
                                      removeFromCart={removeFromCart}
                                      toggleDrawer={toggleDrawer('right', true, data)}
                                    />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="cartRt">
                            <div className="cartSummary">
                              <div>
                                <h3>Shopping Cart Summary</h3>
                                <div className="csInfo">
                                  <label>Items:</label>
                                  <h5>{carts[locationId].items.length}</h5>
                                </div>
                                <div className="csInfo">
                                  <label>Total before taxes:</label>
                                  <h5>
                                    {currencyFormat(
                                      getTotal(carts[locationId].items, 'totalBeforeTax')
                                    )}
                                  </h5>
                                </div>
                                <div className="csInfo">
                                  <label>
                                    Buyer Premium ({carts[locationId].premiumPercent}
                                    %):
                                  </label>
                                  <h5>
                                    {currencyFormat(
                                      getTotal(carts[locationId].items, 'totalBuyerPremium')
                                    )}
                                  </h5>
                                </div>
                                <div className="csInfo">
                                  <label>
                                    Estimated taxes ({carts[locationId].taxPercent}
                                    %):
                                  </label>
                                  <h5>
                                    {currencyFormat(getTotal(carts[locationId].items, 'totalTax'))}
                                  </h5>
                                </div>
                                <div className="csInfo">
                                  <label>Total:</label>
                                  <h5>
                                    {currencyFormat(getTotal(carts[locationId].items, 'total'))}
                                  </h5>
                                </div>
                              </div>
                              <PrimaryButton
                                label="Proceed to checkout"
                                onClick={() => moveToCheckout(locationId)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : null}
          <div className="cart">
            <div className="d-flex justify-content-between">
              <div className="cartLt">
                {noncarts.length ? (
                  <div className="carts">
                    <div className="cartHead d-flex justify-content-start align-items-center">
                      <h1>Your Items</h1>
                      <span>
                        {noncarts.length ? <span>{noncarts.length} Results Found</span> : null}
                      </span>
                    </div>
                    <div>
                      {noncarts.map((data) => (
                        <CartItem
                          nonCart={true}
                          data={data}
                          from="cart"
                          cancelItem={cancelItem}
                          addToCart={addToCart}
                          toggleDrawer={toggleDrawer('right', true, data)}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="cart">
          <div className="d-flex justify-content-between">
            <div className="emptyCart">
              <object data="/assets/svg/emptyCart.svg" type="image/svg+xml" />
              <h4>You don't have any products added to your cart</h4>
              <PrimaryButton
                onClick={() => handleRedirectInternal(history, 'search')}
                label="Browse Products"
              />
            </div>
          </div>
        </div>
      )}

      <Popup
        modaltitle="Cancel Confirmation"
        open={modalOpen}
        handleClose={() => toggleFullScreenPopup(false)}
      >
        <div>
          <div className="mb-3">
            <h6>Are you sure you want to cancel this item?</h6>
          </div>
          <div className="w-25 m-auto">
            <PrimaryButton
              className="input-group-prepend"
              label="Yes, cancel this item"
              onClick={() => cancelItemAction()}
            />
          </div>
        </div>
      </Popup>
    </DashboardLayout>
  );
};

export default Cart;
