import React, { useReducer } from 'react';
import AutoPilotContext from './AutoPilotContext';
import AutoPilotReducer from './autoPilotReducer';
import axios from 'axios';
import { response } from '../common';

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './autoPilotTypes';

const AutoPilotState = (props) => {
  const initialState = {
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(AutoPilotReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const addCustomer = async (formData) => {
    try {
      try {
        const URL = 'https://api2.autopilothq.com/v1/contact';
        const data = {
          contact: formData,
        };
        const config = {
          headers: {
            'content-type': 'application/json',
            autopilotapikey: 'c655265afd50419cb1cf5c16b8e4430d',
          },
        };
        delete axios.defaults.headers.common['ipaddress'];
        const res = await axios.post(`${URL}`, data, config);
        console.log('RESPONSE: ', res);
        dispatch({
          type: RESPONSE_STATUS,
          payload: {
            status: 'success',
            message: 'Subscribed to newsletter successfully',
            from: 'addCustomer',
          },
        });
      } catch (err) {
        resp.commonErrorResponse('addCustomer');
      }
    } catch (err) {
      resp.commonErrorResponse('addCustomer');
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });
  return (
    <AutoPilotContext.Provider
      value={{
        responseStatus: state.responseStatus,
        clearResponse,
        addCustomer,
      }}
    >
      {props.children}
    </AutoPilotContext.Provider>
  );
};

export default AutoPilotState;
