import React, { useContext } from 'react';
import './FilterPanel.css';
import { RatingMenu } from 'react-instantsearch-dom';
import ConnectedRefinementCheckbox from './connected-refinement-checkbox';
import UnconnectedRefinement from './unconnected-refinement';
import SelectedFilters from './selected-filters';
import RefinementNumericMenu from './refinement-numeric-menu';
import CommonContext from '../../../../custom/context/common/commonContext';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';

const InstantSearchFilterPanel = ({ classes, children }) => {
  const commonContext = useContext(CommonContext);
  const { currentLocation } = commonContext;

  return (
    <div className="filterPanel">
      <div className="d-flex flex-column align-items-center filterTitleCnt">
        <h4 className="fpTitle">Filters</h4>
        <SelectedFilters
          showMore
          limit={4}
          showMoreLimit={100}
          translations={{
            showMore(extended) {
              return extended ? 'See Less' : 'See More';
            },
          }}
        />
      </div>
      <div>
        <ConnectedRefinementCheckbox
          attribute={'Brand'}
          searchable
          isSearchable
          isExpanded
          limit={4}
          showMore
          showMoreLimit={100}
          title={'Brand'}
          inputPlaceholder={'Search Brands'}
        />
        <UnconnectedRefinement title={'Star Rating'}>
          <RatingMenu
            attribute="Star Rating"
            className={classes.ratingMenu}
            defaultRefinement={{ min: 0 }}
            min={0}
            max={5}
            translations={{
              ratingLabel: '',
            }}
          />
        </UnconnectedRefinement>
        <RefinementNumericMenu
          attribute={'Current Bid'}
          items={[
            { label: 'Any Price' },
            { label: 'Under $10', end: 10 },
            { label: '$10 - $50', start: 10, end: 50 },
            { label: '$50 - $200', start: 50, end: 200 },
            { label: '$200 - $500', start: 200, end: 500 },
            { label: 'Over $500', start: 500 },
          ]}
          title={'Current Price'}
          name={'currentBid'}
          color={'primary'}
        />
        <RefinementNumericMenu
          attribute={'Suggested Retail'}
          items={[
            { label: 'Any Price' },
            { label: 'Under $10', end: 10 },
            { label: '$10 - $100', start: 10, end: 100 },
            { label: '$100 - $500', start: 100, end: 500 },
            { label: 'Over $500', start: 500 },
          ]}
          title={'Suggested Retail Price'}
          name={'suggestedRetail'}
          color={'primary'}
        />
        <ConnectedRefinementCheckbox
          attribute={'Location Name'}
          title={`Locations within: ${currentLocation.city}, ${currentLocation.state}`}
          isExpanded={false}
        />
        {children}
      </div>
    </div>
  );
};
export default withStyles(style)(InstantSearchFilterPanel);
