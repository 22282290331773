import { Button } from '@material-ui/core';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './ProductCard.css';
import CommonContext from '../../../context/common/commonContext';

const SimilarItem = (props) => {
  const commonContext = useContext(CommonContext);
  const { setBidHistoryValue } = commonContext;

  const [product, setProduct] = useState();
  const history = useHistory();

  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);

  return (
    <div className="similarItem d-flex justify-content-start align-items-start">
      {product ? (
        <div
          onClick={() =>
            document.getElementById('productView').scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Button onClick={() => props.drawerHandler(props.data)}>
            <div className="pclImg">
              <img src={product.avatarorg} alt={product.leadDescription} />
            </div>
            <div className="listContent">
              <h2 onClick={props.drawerHandler} className="listProdTitle">
                {product.leadDescription}
              </h2>
              {/* <div className="listLotInfo d-flex justify-content-start align-items-center">
                                <h5>Lot # {product.item_id}</h5>
                                <span className="listDivider">|</span>
                                <h5>{product.allattachmentlist.length} Photos</h5>
                            </div> */}
              <div className="listLotDetails">
                {product.nellisnotes ? <p>NOTES: {product.nellisnotes}</p> : <p> </p>}
              </div>
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default SimilarItem;
