import React, { useState, useContext, useEffect, useRef } from 'react';
import openSocket from 'socket.io-client';
import './Dashboard.css';
import SideNav from './SideNav';
import FilterPanel from '../../custom/components/organisms/FilterPanel';
import GridView from '../../custom/components/molecules/ProductCard/GridView';
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect';
import { Button, ListItem } from '@material-ui/core';
import ListView from '../../custom/components/molecules/ProductCard/ListView';
import Drawer from '@material-ui/core/Drawer';
import ProductView from '../../custom/components/organisms/ProductView';
import ProductContext from '../../custom/context/product/productContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { messageHandler } from '../Common/socketHandler';
import { useCustomMediaQuery } from '../../custom/common/components';
import { useFormik } from 'formik';

const useStyles = makeStyles({
  fullList: {
    width: 'auto',
  },
});

const MyAuctions = (props) => {
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const classes = useStyles();

  const toggleBottomDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const { search_alldashboardproducts, getAllDashboardProducts } = productContext;
  const { user, isAuthenticated, responseStatus, clearResponse, saveSearch } = authContext;

  const { setAlert } = alertContext;
  const [loading, setLoading] = useState(true);
  const [auctionView, setAuctionView] = useState('List');
  const [state, setState] = useState({
    right: false,
    bottom: false,
    data: {},
  });
  let [viewProduct, setViewProduct] = useState([]);

  const pageOptions = [
    {
      value: '120',
      show: '120 Results per page',
    },
    {
      value: '240',
      show: '240 Results per page',
    },
    {
      value: '360',
      show: '360 Results per page',
    },
  ];

  const sortShow = [
    {
      value: 'p.date_closed,desc',
      show: 'Closing Date (New - Old)',
    },
    {
      value: 'p.date_closed,asc',
      show: 'Closing Date (Old - New)',
    },
    {
      value: 'p.wprice,asc',
      show: 'Price (Low - High)',
    },
    {
      value: 'p.wprice,desc',
      show: 'Price (High - Low)',
    },
    {
      value: 'it.retailPrice,asc',
      show: 'Retail Price (Low - High)',
    },
    {
      value: 'it.retailPrice,desc',
      show: 'Retail Price (High - Low)',
    },
  ];

  const formik = useFormik({
    initialValues: {
      searchbar: '',
    },
  });

  const [search, setSearch] = useState({
    limit: 120,
    page: 1,
    orderby: 'p.date_closed,desc',
    order: '',
    filters: {
      category: {
        value: [],
        type: 'array',
        field: 'it.categoryTypeId',
      },
      condition: {
        value: [],
        type: 'array',
        field: 'it.conditionTypeId',
      },
      location: {
        value: [],
        type: 'array',
        field: 'p.location_id',
      },
      price: {
        value: '',
        type: 'greaterequal',
        field: 'p.wprice',
      },
      searchbar: {
        value: '',
        type: 'like',
        field: 'it.leadDescription',
      },
      auctionlotId: {
        value: [],
        type: 'array',
        field: 'p.auctionlotId',
      },
      starRating: {
        value: [],
        type: 'array',
        field: 'it.starRating',
      },
    },
    action: props.match.params.type,
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearch({
        ...search,
        filters: {
          ...search.filters,
          searchbar: {
            ...search.filters.searchbar,
            value: formik.values.searchbar,
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllDashboardProducts(search);
  }, [search]);

  useEffect(() => {
    setSearch({ ...search, action: props.match.params.type });
  }, [props.match.params.type]);

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  const initialLoad = useRef(true);
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setLoading(false);
      setViewProduct(
        search_alldashboardproducts.records.length ? search_alldashboardproducts.records : []
      );
    }
  }, [search_alldashboardproducts]);

  const viewProductRef = useRef(viewProduct);
  const userRef = useRef(user);
  useEffect(() => {
    viewProductRef.current = viewProduct;
    userRef.current = user;
  });

  const handler = (message, type) => {
    messageHandler(
      message,
      viewProductRef.current,
      userRef.current,
      setAlert,
      setViewProduct,
      type
    );
  };

  useEffect(() => {
    const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`);
    socket.on('realclosedupdates', (data) => {
      handler(data, 'realclosedupdates');
    });
    socket.on('bidAddtime', (data) => {
      handler(data, 'bidAddtime');
    });
    return () => {
      socket.off('realclosedupdates', (data) => {
        handler(data, 'realclosedupdates');
      });
      socket.off('bidAddtime', (data) => {
        handler(data, 'bidAddtime');
      });
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (state.right) {
      const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10));
      if (index !== -1) {
        setState({ ...state, data: viewProduct[index] });
      }
    }
  }, [viewProduct]);

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setState({ ...state, [anchor]: open, data: data });
    } else {
      setState({ ...state, [anchor]: open, data: {} });
    }
  };

  const clearSearchFilter = () => {
    setSearch({
      ...search,
      limit: 120,
      page: 1,
      orderby: 'p.date_closed,desc',
      order: '',
      filters: {
        category: {
          ...search.filters.category,
          value: [],
        },
        condition: {
          ...search.filters.condition,
          value: [],
        },
        location: {
          ...search.filters.location,
          value: [],
        },
        price: {
          ...search.filters.price,
          value: '',
        },
        searchbar: {
          ...search.filters.searchbar,
          value: '',
        },
        auctionlotId: {
          ...search.filters.auctionlotId,
          value: [],
        },
        starRating: {
          ...search.filters.starRating,
          value: [],
        },
      },
    });
    formik.values.searchbar = '';
  };

  const renderHeading = () => {
    switch (props.match.params.type) {
      case 'auction':
        return 'All Auctions';
      case 'watchlist':
        return 'Watchlist Auctions';
      case 'winning':
        return 'Winning Auctions';
      case 'outbid':
        return 'Outbid Auctions';
      case 'won':
        return 'Won Auctions';

      default:
        return 'My Auctions';
    }
  };
  return (
    <div className="dashboard customContainer">
      <div className="d-flex justify-content-start">
        <div className="dashboardLt">
          <div className="respSort">
            <SideNav />
            <div class="input-group mt-3 mb-3 searchInput">
              <div class="input-group-prepend">
                <span class="input-group-text" id="searchIcon">
                  <span className="material-icons">search</span>
                </span>
              </div>
              <input
                onKeyDown={handleKeyDown}
                type="text"
                name="searchbar"
                onChange={formik.handleChange}
                className="form-control"
                placeholder="Search"
                value={formik.values.searchbar}
                aria-label="Search"
                aria-describedby="searchIcon"
              />
            </div>

            <CustomSelect
              label="Results per page"
              value={search.limit}
              size="small"
              name="limit"
              shrink={search.limit !== '' ? true : false}
              onChange={(event, editor) => {
                setSearch({ ...search, limit: event.target.value });
              }}
            >
              {pageOptions.map((opt, optindex) => (
                <option value={opt.value}>{opt.show}</option>
              ))}
            </CustomSelect>

            <div className="mt-2 mb-3 gridListToggle">
              <Button
                className={auctionView === 'Grid' ? 'active' : ''}
                onClick={() => setAuctionView('Grid')}
              >
                <i class="fas fa-th-large"></i> Grid
              </Button>
              <Button
                className={auctionView === 'List' ? 'active' : ''}
                onClick={() => setAuctionView('List')}
              >
                <i class="fas fa-th-list"></i>List
              </Button>
            </div>

            <div className="mb-4">
              <CustomSelect
                label="Sort By"
                value={search.orderby}
                size="small"
                name="orderby"
                shrink={search.orderby !== '' ? true : false}
                onChange={(event, editor) => {
                  setSearch({ ...search, orderby: event.target.value });
                }}
              >
                {sortShow.map((opt, optindex) => (
                  <option value={opt.value}>{opt.show}</option>
                ))}
              </CustomSelect>
            </div>
          </div>

          {useCustomMediaQuery('(min-width: 1024px)') ? (
            <div className="deskFilter">
              <FilterPanel
                setSearch={setSearch}
                clearSearch={clearSearchFilter}
                sorts={search_alldashboardproducts.sorts}
                search={search}
                from={1}
              />
            </div>
          ) : (
            <Drawer
              anchor="bottom"
              open={state['bottom']}
              className="responsiveFilterDrawer"
              onClose={toggleBottomDrawer('bottom', false)}
              onOpen={toggleBottomDrawer('bottom', true)}
            >
              <div
                className={clsx(classes.list, 'bottom')}
                role="presentation"
                // onClick={toggleBottomDrawer('bottom', false)}
                onKeyDown={toggleBottomDrawer('bottom', false)}
              >
                <div>
                  <h4 class="sbTitle">Sort By</h4>
                  <div class="searchAuctions">
                    <div class="d-flex justify-content-start align-items-center flex-wrap">
                      {sortShow.map((opt, optindex) => (
                        <Button
                          className={search.orderby === opt.value ? 'active' : null}
                          onClick={(event, editor) => {
                            setSearch({
                              ...search,
                              orderby: opt.value,
                            });
                          }}
                        >
                          {opt.show}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <h4 class="sbTitle">Results Per Page</h4>
                  <div class="searchAuctions">
                    <div class="d-flex justify-content-start align-items-center flex-wrap">
                      {pageOptions.map((opt, optindex) => (
                        <>
                          <Button
                            className={search.limit == opt.value ? 'active' : null}
                            onClick={(event, editor) => {
                              setSearch({
                                ...search,
                                limit: opt.value,
                              });
                            }}
                          >
                            {opt.show}
                          </Button>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <FilterPanel
                  setSearch={setSearch}
                  clearSearch={clearSearchFilter}
                  sorts={search_alldashboardproducts.sorts}
                  search={search}
                  from={1}
                />
              </div>
            </Drawer>
          )}
        </div>
        <div className="dashboardRt">
          <div className="myAuctions">
            <div className="d-flex justify-content-between align-items-start w-100">
              <div>
                <h2 className="dashTitle">
                  {renderHeading()}
                  <span>
                    {viewProduct.length ? (
                      <h6>{search_alldashboardproducts.totalRecords} Auctions</h6>
                    ) : null}
                  </span>
                </h2>
              </div>
              <Button
                className="toggleRespDrawer"
                variant="outlined"
                onClick={toggleBottomDrawer('bottom', true)}
              >
                <span className="material-icons">tune</span>Filters
              </Button>
            </div>

            {props.location.pathname !== '/dashboard/watchlist' && (
              <div className="auctionStatus">
                <ListItem button>
                  <NavLink activeClassName="active" to="/dashboard/auction">
                    All
                  </NavLink>
                </ListItem>

                <ListItem button>
                  <NavLink activeClassName="active" to="/dashboard/winning">
                    Winning
                  </NavLink>
                </ListItem>

                <ListItem button>
                  <NavLink activeClassName="active" to="/dashboard/outbid">
                    Currently Outbid
                  </NavLink>
                </ListItem>

                <ListItem button>
                  <NavLink activeClassName="active" to="/dashboard/won">
                    Won
                  </NavLink>
                </ListItem>
              </div>
            )}

            {!loading ? (
              viewProduct.length ? (
                <div className="searchResults d-flex justify-content-start flex-wrap">
                  <Drawer
                    className="rightDrawer"
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                  >
                    <ProductView data={state.data} drawerHandler={toggleDrawer('right', false)} />
                  </Drawer>
                  {viewProduct.map((data, index) => (
                    <>
                      {auctionView === 'Grid' ? (
                        <>
                          <GridView
                            key={index}
                            data={data}
                            favId={`searchProd_${index}`}
                            drawerHandler={toggleDrawer('right', true, data)}
                          />
                        </>
                      ) : (
                        <ListView
                          key={index}
                          data={data}
                          favId={`searchProd_${index}`}
                          drawerHandler={toggleDrawer('right', true, data)}
                        />
                      )}
                    </>
                  ))}
                  <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                    <h6>
                      Showing {search_alldashboardproducts.setDisp} of{' '}
                      {search_alldashboardproducts.totalRecords}
                    </h6>
                    <Pagination
                      count={Math.ceil(search_alldashboardproducts.totalRecords / search.limit)}
                      page={search.page}
                      onChange={onHandlePage}
                      siblingCount={3}
                      showFirstButton
                      showLastButton
                      boundaryCount={2}
                    />
                  </div>
                </div>
              ) : (
                <div className="np-pro-box">
                  <img src="/assets/svg/noResults.svg" alt="No results image" />
                  <h5>No Records Found!</h5>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAuctions;
