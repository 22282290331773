import React, { useContext, useEffect } from 'react';
import Slider from 'react-slick';
import './HomeBanner.css';
import CommonContext from '../../../context/common/commonContext';

function HomeBanner() {
  const bannerCarousel = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  const { banners, getBanners } = useContext(CommonContext);
  useEffect(() => {
    getBanners();
  }, []);
  return (
    <div className="homeSponsoredBanner">
      {banners.length ? (
        <Slider {...bannerCarousel}>
          {banners.map((data, index) => (
            <div key={index}>
              <a href={data.url ? data.url : null}>
                <img src={data.filePath} alt="Sponsored Banner" />
              </a>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
}

export default HomeBanner;
