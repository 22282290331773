import React, { useState, useContext, useEffect } from 'react';
import CommonContext from '../../custom/context/common/commonContext';
import './StaticPages.css';

const Static = (props) => {
  const commonContext = useContext(CommonContext);

  const { static_page, getStaticPage } = commonContext;
  let [viewStatic, setviewStatic] = useState([]);

  const [search, setSearch] = useState({
    id: 0,
  });

  useEffect(() => {
    if (props.location.pathname === '/estate-sales') {
      setSearch({ id: 'estate-sales' });
    } else if (props.location.pathname === '/business-bankruptcy') {
      setSearch({ id: 'business-bankruptcy' });
    } else if (props.location.pathname === '/about-us') {
      setSearch({ id: 'about-us' });
    } else if (props.location.pathname === '/careers') {
      setSearch({ id: 'careers' });
    } else if (props.location.pathname === '/location-hours') {
      setSearch({ id: 'location-hours' });
    } else if (props.location.pathname === '/contact') {
      setSearch({ id: 'contact' });
    } else if (props.location.pathname === '/terms') {
      setSearch({ id: 'terms' });
    } else if (props.location.pathname === '/privacy') {
      setSearch({ id: 'privacy' });
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (search.id !== 0) {
      getStaticPage(search);
    }
  }, [search]);

  useEffect(() => {
    setviewStatic(static_page.record ? static_page.record : null);
  }, [static_page]);

  return (
    <div className="staticPages">
      <div className="container">
        <div className="d-flex justify-content-center align-items-start">
          <div dangerouslySetInnerHTML={{ __html: viewStatic.content }} />
        </div>
      </div>
    </div>
  );
};

export default Static;
