import React, { useState } from 'react';
import { connectNumericMenu } from 'react-instantsearch-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { handleRedirectInternal } from '../../../common/components';

const RefinementNumericMenu = ({ items, refine, title, name, color }) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState('');
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    refine(event.target.value);
    if (location.pathname !== '/reactinstantsearch') {
      handleRedirectInternal(history, `reactinstantsearch`);
    }
  };

  return (
    <Accordion square expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <h6 className={'accTitle'}>{title}</h6>
      </AccordionSummary>
      <AccordionDetails className={'filterCheck flex-column d-flex align-items-center'}>
        <RadioGroup aria-label={title} value={value} onChange={handleChange}>
          {items.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                disabled={item.noRefinement}
                label={item.label}
                control={
                  <Radio value={item.value} name={name} checked={item.isRefined} color={color} />
                }
              />
            );
          })}
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default connectNumericMenu(RefinementNumericMenu);
