import React, { useState, useContext, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Button } from '@material-ui/core';
import './ProductView.css';
import SimilarItem from '../../molecules/ProductCard/SimilarItems';
import Timer from '../../../../product/common/timer';
import Bidding from '../../molecules/Bidding/BiddingItem';
import CommonContext from '../../../context/common/commonContext';
import ProductContext from '../../../../custom/context/product/productContext';
import AuthContext from '../../../../custom/context/auth/authContext';
import { dateFormatFront, currencyFormat } from '../../../common/components';

const ProductView = (props) => {
  const [expanded, setExpanded] = React.useState();
  const [images, setImages] = React.useState([]);
  const commonContext = useContext(CommonContext);
  const productContext = useContext(ProductContext);
  const { search_allsimilar, getAllSimilarProducts } = productContext;
  const { isAuthenticated } = useContext(AuthContext);
  let [viewProduct, setViewProduct] = useState([]);
  const {
    allCategory,
    allCondition,
    allDamageTypes,
    allPackageTypes,
    allLocations,
    setBidHistoryValue,
    currentLocation,
  } = commonContext;
  const [product, setProduct] = React.useState();

  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);

  useEffect(() => {
    if (product) {
      const search = {
        limit: 3,
        page: 1,
        orderby: '(p.score_proj + p.score_price) / 2, desc',
        order: '',
        filters: {
          product_id: {
            value: product.id,
            type: 'notin',
            field: 'p.id',
          },
          currentLocation: {
            state: currentLocation.state,
            city: currentLocation.city,
          },
        },
      };
      getAllSimilarProducts(search);
    }
  }, [product]);

  useEffect(() => {
    setViewProduct(search_allsimilar.records.length ? search_allsimilar.records : []);
  }, [search_allsimilar]);

  useEffect(() => {
    setViewProduct([]);
    return () => {
      setViewProduct([]);
    };
  }, []);

  useEffect(() => {
    if (product) {
      if (product.allattachmentlist) {
        if (product.allattachmentlist.length) {
          product.allattachmentlist.forEach(function (data) {
            data['original'] = global.images_url + data['file_name'];
            data['thumbnail'] = global.images_url + data['file_name'];
          });
          setImages(product.allattachmentlist);
        } else {
          setImages([]);
        }
      } else {
        setImages([]);
      }
    } else {
      setImages([]);
    }
  }, [product]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="productView" id="productView">
      {product ? (
        <>
          <Button className="closeSlider" onClick={props.drawerHandler}>
            <span className="material-icons">close</span>
          </Button>
          <ImageGallery
            items={images}
            thumbnailPosition="bottom"
            showNav={true}
            lazyLoad={true}
            showBullets={false}
            showFullscreenButton={true}
            showPlayButton={false}
          />
          {isAuthenticated && (
            <>
              {product.bidtopstatus === 'outbid' && <h4 className="outBid">OUTBID</h4>}
              {product.bidtopstatus === 'winner' && <h4 className="winning">WINNING</h4>}
              {product.bidtopstatus === 'won' && <h4 className="winning">WON</h4>}
              {product.bidtopstatus === 'lost' && <h4 className="outBid">LOST</h4>}
            </>
          )}
          <div>
            <h3 className="pvHead">Auction Details</h3>
            <div className="pvActBtn d-flex justify-content-around align-items-center">
              {product.market_status === 'open' ? (
                <>
                  <div className="pabChild">
                    <label>Time Left</label>
                    <h4>
                      <Timer
                        date_added={product.date_added}
                        date_closed={product.date_closed}
                        withText={0}
                      ></Timer>
                    </h4>
                  </div>

                  <div className="pabChild pvCurrentBid">
                    <label>{product.cbidtext}</label>
                    <h4>{currencyFormat(product.wprice)}</h4>
                  </div>

                  <div className="pabChild">
                    {product.bidtopstatus === 'winner' &&
                    parseInt(product.maxamount) > parseInt(product.wprice) ? (
                      <>
                        <label>Current Max Bid</label>
                        <h4>{currencyFormat(product.maxamount)}</h4>
                      </>
                    ) : (
                      <>
                        <label>Minimum Bid</label>
                        <h4>{currencyFormat(product.next_bid)}</h4>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <div className="pabChild">
                    <label>Closed on</label>
                    <h4>{dateFormatFront(product.date_closed)}</h4>
                  </div>
                  <div className="pabChild pvCurrentBid">
                    <label>{product.cbidtext}</label>
                    <h4>{currencyFormat(product.buynowamount)}</h4>
                  </div>
                </>
              )}
            </div>
            <Button className="pvBidHistBtn" onClick={() => setBidHistoryValue(product.id)}>
              View Bid History
            </Button>
            <div className="pvBidBox">
              <Bidding data={product} />
            </div>
            <div className="pvProductInfo mt-4">
              <p>{product.leadDescription}</p>
            </div>
            <div className="pvProductDesc mt-3 mb-3">
              <div className="pvDescChild">
                <label>Inventory Number:</label>
                <h6>{product.inventoryNumber}</h6>
              </div>
              <div className="pvDescChild">
                <label>Local Pickup:</label>
                <h6>
                  {allLocations
                    .filter((inner) => inner.id === product.location_id)
                    .map(
                      (filtered) =>
                        `${filtered.address}, ${filtered.city} ${filtered.state}  ${filtered.zipCode}`
                    )}{' '}
                </h6>
              </div>
              <div className="pvDescChild">
                <label>Auction Ends:</label>
                <h6>{dateFormatFront(product.date_closed)}</h6>
              </div>
              <div className="pvDescChild">
                <label>Description:</label>
                <h6>
                  {product.leadDescription}
                  <br />
                  {product.categoryTypeId && (
                    <>
                      CATEGORY:{' '}
                      {allCategory
                        .filter((inner) => inner.id === parseInt(product.categoryTypeId))
                        .map((filtered) => filtered.description)}
                      <br />
                    </>
                  )}
                  {product.conditionTypeId && (
                    <>
                      CONDITION:{' '}
                      {allCondition
                        .filter((inner) => inner.id === product.conditionTypeId)
                        .map((filtered) => filtered.description)}
                      <br />
                    </>
                  )}
                  {product.packageTypeId && (
                    <>
                      PACKAGING:{' '}
                      {allPackageTypes
                        .filter((inner) => inner.id === product.packageTypeId)
                        .map((filtered) => filtered.description)}
                      <br />
                    </>
                  )}
                  {product.damageTypeId && (
                    <>
                      DAMAGE:{' '}
                      {allDamageTypes
                        .filter((inner) => inner.id === product.damageTypeId)
                        .map((filtered) =>
                          filtered.description === 'Major' || filtered.description === 'Minor'
                            ? 'Yes'
                            : filtered.description === 'None'
                            ? 'No'
                            : filtered.description
                        )}
                      <br />
                    </>
                  )}
                </h6>
              </div>
              <div className="pvDescChild">
                <label>Suggested Retail Price:</label>
                <h6>{currencyFormat(product.retailPrice)}</h6>
              </div>
              <div className="pvDescChild">
                <label>Star Rating:</label>

                <h6>{product.starRating} </h6>
                <div className="material-icons starRating">star_rate</div>
              </div>
              {product.nellisnotes && (
                <>
                  <div className="pvDescChild">
                    <label>Notes:</label>
                    <h6>{product.nellisnotes}</h6>
                  </div>
                </>
              )}
              <div className="pvDescChild">
                <label>Inventoried by:</label>
                <h6>
                  {product.empFirstName} {product.empLastName ? product.empLastName[0] : ''}
                </h6>
              </div>
            </div>
            {/* <div className="pvAccordian mt-2 mb-2 w-100">
                            {accordianData.map((data, index) => (
                                <Accordion
                                    square
                                    expanded={expanded === `panel${index}`}
                                    onChange={handleChange(`panel${index}`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1d-content"
                                        id={`panel${index}d-header`}
                                    >
                                        <h6 className="accTitle">{data.title}</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>{data.details}</AccordionDetails>
                                </Accordion>
                            ))}
                        </div> */}
            {viewProduct.length ? (
              <div className="row">
                <div className="col-12">
                  <h4 className="cartHead">Trending Now</h4>
                  {viewProduct.map((data, index) => (
                    <SimilarItem data={data} drawerHandler={setProduct} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};
export default ProductView;
