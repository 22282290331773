import React, { useState } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Button, FormControlLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import './FilterPanel.css';
import ClearRefinements from './clear-refinements';

const SelectedFilters = ({ items = [], refine, showMore, translations, limit }) => {
  const [extended, setExtended] = useState(false);

  const selectedFilters = items.flatMap((item) => {
    if (item.items) {
      return item.items.map(({ label, value }) => {
        const { items, ...rest } = item;
        return { ...rest, label, value };
      });
    }
    return item;
  });

  const handleLabel = (item) => {
    if (item.attribute === 'Current Bid' || item.attribute === 'Suggested Retail') {
      return item.label;
    }
    if (item.attribute === 'Star Rating') {
      return `${item.currentRefinement.min} Stars ${
        item.currentRefinement.min < 5 ? 'or Higher' : ''
      }`;
    }
    return `${item.attribute}: ${item.label}`;
  };

  return (
    <>
      <div>
        {selectedFilters &&
          selectedFilters.map((item, i) => {
            return (
              (i < limit || extended) && (
                <FormControlLabel
                  key={i}
                  control={
                    <Button
                      size={'small'}
                      variant={'outlined'}
                      color={'primary'}
                      onClick={(event) => {
                        event.preventDefault();
                        refine(item.value);
                      }}
                      endIcon={<ClearIcon color={'primary'} />}
                    >
                      {handleLabel(item)}
                    </Button>
                  }
                />
              )
            );
          })}
        {showMore && selectedFilters.length > 4 && (
          <Button onClick={() => setExtended(!extended)}>{translations.showMore(extended)}</Button>
        )}
      </div>
      {selectedFilters.length > 0 && <ClearRefinements />}
    </>
  );
};

export default connectCurrentRefinements(SelectedFilters);
