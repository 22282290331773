import { Button } from '@material-ui/core';
import React, { useState, useRef, useEffect, useContext } from 'react';
import Timer from '../../../../custom/common/timer';
import './ProductCard.css';
import { currencyFormat } from '../../../common/components';

const CartItem = (props) => {
  const [product, setProduct] = useState();
  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);
  return (
    <>
      {product ? (
        <>
          <div className="cartItem d-flex justify-content-between align-items-start">
            <div className="itemInfo d-flex justify-content-start">
              <div className="pclImg">
                <img
                  className="cursorPointer"
                  onClick={props.toggleDrawer}
                  src={product.avatarorg}
                  alt={product.leadDescription}
                />
              </div>
              <div className="listContent">
                <h2 className="listProdTitle" onClick={props.toggleDrawer}>
                  {product.title}
                </h2>
                {/* <div className="listLotInfo d-flex justify-content-start align-items-center flex-direction-column">
                                    <h5>Inv # {product.inventoryNumber}</h5>
                                </div> */}
                <div className="listLotInfo d-flex justify-content-start align-items-center flex-direction-column">
                  {props.from === 'invoice' ? (
                    <div>
                      {product.returned === 1 ? (
                        <>
                          <span className="listDivider">|</span>
                          <span>Returned</span>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {props.from === 'cart' && (
                    <div>
                      Pay before:
                      <Timer
                        date_closed={new Date(product.buynowdate_added).setTime(
                          new Date(product.buynowdate_added).getTime() + 7 * 24 * 60 * 60 * 1000
                        )}
                        withText={0}
                      ></Timer>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="listActBtn">
              <div className="listBidInfo d-flex justify-content-between align-items-center">
                <p className="d-flex justify-content-center align-items-center">
                  <span>{currencyFormat(product.amount)}</span>
                  {props.from === 'cart' ? (
                    <>
                      <span className="listDivider">|</span>
                      <span>Unpaid</span>
                    </>
                  ) : props.from === 'return' ? (
                    <>
                      <span className="listDivider">|</span>
                      <span>Unpaid</span>
                    </>
                  ) : null}
                </p>
              </div>
              {props.from == 'cart' ? (
                <>
                  {props.nonCart ? (
                    <Button
                      className="removeCart"
                      onClick={() => props.addToCart(product, product.location_id)}
                    >
                      Add to cart
                    </Button>
                  ) : (
                    <Button
                      className="removeCart"
                      onClick={() => props.removeFromCart(product.buynowId, product.location_id)}
                    >
                      Remove from cart
                    </Button>
                  )}
                  {/* <Button
                                        className="removeCart"
                                        onClick={() => props.cancelItem(product.buynowId)}
                                    >
                                        Cancel Item
                                    </Button> */}
                </>
              ) : props.from === 'return' ? (
                <>
                  {product.returncart_id === 0 ? (
                    <Button
                      className="removeCart"
                      onClick={() => props.changeOnCart({ cart: 1, id: product.id })}
                    >
                      Add to cart
                    </Button>
                  ) : (
                    <Button
                      className="removeCart"
                      onClick={() => props.changeOnCart({ cart: 0, id: product.id })}
                    >
                      Remove from cart
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default CartItem;
