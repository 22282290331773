import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './CustomDialog.css';

function CustomDialog(props) {
  const open = props.open;
  const openFunction = props.function;
  const title = props.title;
  return (
    <div>
      <Dialog
        className={`customDialog ${props.className}`}
        open={open}
        onClose={openFunction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}
export default CustomDialog;
