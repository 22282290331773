import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  handleRedirectInternal,
  dateTimeFormatFunction,
  currencyFormat,
} from '../../../common/components';
import './InvoiceCard.css';

const ReceiptCard = (props) => {
  const [receipt, setReceipt] = useState();
  const history = useHistory();
  useEffect(() => {
    setReceipt(props.data);
  }, [props.data]);
  return (
    <>
      <div className="invoiceCard">
        {receipt ? (
          <>
            <h3 className="custName">Receipt # {receipt.id}</h3>

            <div className="icPaid d-flex justify-content-center align-items-center">
              <div>Nellis Auction - {receipt.locationName}</div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex">TEL: {receipt.phone}</div>
            </div>
            <div className="icPaid d-flex justify-content-center align-items-center">
              {receipt.locationAddress +
                ', ' +
                receipt.locationCity +
                ', ' +
                receipt.locationState +
                ', ' +
                receipt.locationZip}
            </div>

            <div className="d-flex justify-content-center align-items-center mb-3">
              <div className="d-flex">{dateTimeFormatFunction(receipt.createdAt)}</div>
            </div>

            <div className="icPaid d-flex justify-content-between align-items-center">
              <span>Subtotal:</span> <span>{currencyFormat(receipt.subtotal)}</span>
            </div>
            <div className="icPaid d-flex justify-content-between align-items-center">
              <span>Tax:</span> <span>{currencyFormat(receipt.taxAmount)}</span>
            </div>
            <div className="icPaid d-flex justify-content-between align-items-center">
              <span>Buyer Premium:</span>
              <span>{currencyFormat(receipt.premiumAmount)}</span>
            </div>
            <div className="icPaid d-flex justify-content-between align-items-center">
              <span>
                <strong>Total:</strong>
              </span>
              <span>
                <strong>{currencyFormat(receipt.total)}</strong>
              </span>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ReceiptCard;
