import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { socket } from './socket.js';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CustomSelect from '../components/atoms/Inputs/CustomSelect';
import CustomMultiSelect from '../components/atoms/Inputs/CustomMultiSelect';
import RadioBox from '../components/atoms/RadioBox';

import MenuItem from '@material-ui/core/MenuItem';
import CustomInput from '../components/atoms/Inputs/CustomInput';
import GooglePlaceAutoComplete from '../components/atoms/Inputs/GooglePlaceAutoComplete';
import CustomPhone from '../components/atoms/Inputs/CustomPhone.js';
import PasswordStrength from './passwordStrength';
import './productCommon.css';

import CheckBox from '../components/atoms/CheckBox';
import CustomTextArea from '../components/atoms/Inputs/CustomTextArea';
import CKEditor from 'ckeditor4-react';
import Uploader from '../../product/common/uploader';

import FormHelperText from '@material-ui/core/FormHelperText';
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

let serverTime = new Date();
const monthFormat = 'YYYY-MM';
const dateFormat = 'MM-DD-YYYY';
const dateTimeFormat = 'MM-DD-YYYY h:mm a';
const timeFormat = 'h:mm a';

if (socket) {
  socket.on('sliservertime', (data) => {
    if (moment(data.dTime).isValid()) {
      serverTime = new Date(data.dTime);
    }
  });
}
export const mapData = (page) => {
  let data = page.map((data, index) => (
    <div key={index} className={data.class}>
      {data.type === 'check' && data.label && <h5 className="checkboxHeading">{data.label}</h5>}
      {data.type === 'select' ? (
        <>
          <CustomSelect
            label={data.label}
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            size={data.size}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            placeholder={data.placeholder}
            disabled={data.disabled}
            onBlur={data.formik.handleBlur}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          >
            {!data.isNoDefaultOptionValue && <option value="">{data.placeholder}</option>}
            {data.options &&
              data.options.map((opt, optindex) => (
                <option key={optindex} value={opt.value}>
                  {opt.show}
                </option>
              ))}

            {data.groupoptions &&
              data.groupoptions.map((opt, optindex) => (
                <>
                  <optgroup label={opt.head}>
                    <>
                      {opt.options.map((opt, optindex) => (
                        <option key={optindex} value={opt.value}>
                          {opt.show}
                        </option>
                      ))}
                    </>
                  </optgroup>
                </>
              ))}
          </CustomSelect>
        </>
      ) : data.type === 'misc' ? (
        <>{data.content}</>
      ) : data.type === 'switch' ? (
        <>
          <FormGroup row className="partialPaymentToggle">
            <FormControlLabel
              label={data.label}
              control={
                <Switch
                  checked={data.formik.values[data.name]}
                  onChange={data.formik.handleChange}
                  name={data.name}
                  color={data.color}
                />
              }
            />
          </FormGroup>
        </>
      ) : data.type === 'check' ? (
        <>
          {data.options &&
            data.options.map((opt, optindex) => (
              <CheckBox
                key={optindex}
                name={data.name}
                disabled={data.disabled}
                label={opt.description}
                checked={
                  data.formik.values[data.name].indexOf(opt.id.toString()) !== -1 ? true : false
                }
                value={opt.id.toString()}
                onChange={data.formik.handleChange}
              />
            ))}
          <div className="checkboxError">
            <FormHelperText>
              {data.formik.errors[data.name] &&
                data.formik.touched[data.name] &&
                data.formik.errors[data.name]}
            </FormHelperText>
          </div>
        </>
      ) : data.type === 'uploadDropZone' ? (
        <>
          <Uploader
            formik={data.formik}
            name={data.name}
            icon={data.icon}
            titleText={data.titleText}
            innerText={data.innerText}
            folder={data.folder}
            multiple={data.multiple}
            accept={data.accept}
          ></Uploader>
        </>
      ) : data.type === 'phone' ? (
        <>
          <CustomPhone
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={(phone) => data.formik.setFieldValue(data.name, phone)}
            label={data.label}
            placeholder={data.placeholder}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
            countryCodeEditable={data.countryCodeEditable}
          />
        </>
      ) : data.type === 'multiselect' ? (
        <>
          <CustomMultiSelect
            label={data.label}
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            placeholder={data.placeholder}
            onBlur={data.formik.handleBlur}
            disabled={data.disabled}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          >
            {data.options.map((opt, optindex) => (
              <MenuItem key={optindex} value={opt.value}>
                {opt.show}
              </MenuItem>
            ))}
          </CustomMultiSelect>
        </>
      ) : data.type === 'ckeditor' ? (
        <>
          {/* <CKEditor
                        editor={ClassicEditor}
                        data={data.formik.values[data.name] ? data.formik.values[data.name] : ''}
                        onChange={(event, editor) => {
                            data.formik.setFieldValue(data.name, editor.getData())
                        }}
                    /> */}
          <CKEditor
            config={{
              allowedContent: true,
            }}
            type="classic"
            data={data.formik.values[data.name]}
            onChange={(event, editor) => {
              data.formik.setFieldValue(data.name, event.editor.getData());
            }}
          />
        </>
      ) : data.type === 'password_checker' ? (
        <>
          <PasswordStrength data={data} />
        </>
      ) : data.type === 'date' ? (
        <>
          <KeyboardDatePicker
            margin="0"
            autoOk={true}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            format={dateFormat}
            disabled={data.disabled}
            disableFuture={data.disableFuture || false}
            disablePast={data.disablePast || false}
            maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
            maxDateMessage={data.maxDateMessage || 'Date should not be after maximal date'}
            minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
            minDateMessage={data.minDateMessage || 'Date should not be before minimal date'}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(dateFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      ) : data.type === 'month' ? (
        <>
          <KeyboardDatePicker
            margin="0"
            autoOk={true}
            openTo="year"
            views={['year', 'month']}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            disablePast={data.disablePast || false}
            maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
            maxDateMessage={data.maxDateMessage || 'Date should not be after maximal date'}
            minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
            minDateMessage={data.minDateMessage || 'Date should not be before minimal date'}
            format={monthFormat}
            disabled={data.disabled}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(monthFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      ) : data.type === 'datetime' ? (
        <>
          <KeyboardDateTimePicker
            margin="0"
            autoOk={true}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            format={dateTimeFormat}
            disabled={data.disabled}
            disablePast={data.disablePast || false}
            maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
            maxDateMessage={data.maxDateMessage || 'Date should not be after maximal date'}
            minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
            minDateMessage={data.minDateMessage || 'Date should not be before minimal date'}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(dateTimeFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'time' ? (
        <>
          <KeyboardTimePicker
            margin="0"
            autoOk={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            disabled={data.disabled}
            disablePast={data.disablePast || false}
            className="customDatepicker"
            value={data.formik.values[data.name]}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(timeFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'textarea' ? (
        <>
          <CustomTextArea
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            label={data.label}
            placeholder={data.placeholder}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'checkbox' ? (
        <>
          <CheckBox
            checked={data.formik.values[data.name]}
            label={data.label}
            value={true}
            disabled={data.disabled}
            onChange={() => {
              data.formik.setFieldValue(data.name, !data.formik.values[data.name]);
            }}
            name={data.name}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'checkboxarray' ? (
        data.item.map((d, i) => (
          <>
            <CheckBox
              name={data.name}
              label={d.description}
              checked={data.formik.values[data.name].indexOf(d.id.toString()) !== -1 ? true : false}
              value={d.id.toString()}
              onChange={data.formik.handleChange}
            />
          </>
        ))
      ) : data.type === 'google_place_autocomplete' ? (
        <GooglePlaceAutoComplete
          id={data.id}
          name={data.name}
          value={data.formik.values[data.name]}
          formik={data.formik}
          label={data.label}
          googlePlaceType={data.googlePlaceType}
          error={data.formik.touched[data.name] && data.formik.errors[data.name]}
          helperText={
            data.formik.errors[data.name] &&
            data.formik.touched[data.name] &&
            data.formik.errors[data.name]
          }
        />
      ) : data.type === 'radio' ? (
        <>
          <RadioBox
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
            title={data.title}
            name={data.name}
            items={data.item}
            value={data.formik.values[data.name]}
            onChange={data.formik.handleChange}
            int={data.int}
          />
        </>
      ) : data.type === 'emptyCol' ? (
        <div className={`${data.col ? data.col : 'col'}`}></div>
      ) : (
        <>
          <CustomInput
            id={data.id}
            value={data.value ? data.value : data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            label={data.label}
            placeholder={data.placeholder}
            upperLabel={data.upperLabel}
            type={data.type}
            size={data.size}
            startAdornment={data.startAdornment}
            endAdornment={data.endAdornment}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
            inputStyle={data.inputStyle}
            tooltiptitle={data.tooltiptitle}
          />
        </>
      )}
    </div>
  ));
  return data;
};

export const handleRedirectInternal = (history, path) => {
  history.push(`/${path}`);
  window.scrollTo(0, 0);
};

export const dateFormatFront = (data) => {
  return moment(data).isValid()
    ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')} PT`
    : '-';
};

export const dateFormatFrontDay = (data) => {
  return `${moment(data).format('MMM Do YYYY')}`;
};

export const checkProductOpen = (date_added, user_role) => {
  let startDate = new Date(serverTime);
  let addDate = new Date(date_added);
  let milliSeconds = 0;
  // if (parseInt(user_role) !== 3) {
  //   milliSeconds = 1 * 3600000;
  // } else {
  // }
  let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
  let newaddDate = new Date(incrementedTime);
  if (newaddDate > startDate) {
    return false;
    // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
    // let timerTime = incrementedTime - startDate.getTime();
    // if (timerTime <= 0) {
    //   return true;
    // } else {
    //   return false;
    // }
  } else {
    return true;
  }
};

export const maskEmailFront = (data) => {
  data = data.toString();
  var first4 = data.substring(0, 4);
  var last5 = data.substring(data.length - 5);
  var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*');
  return first4 + mask + last5;
};

export const capitalize = (data) => {
  let dataReturn = '-';
  if (data) {
    data = data.replace(/_/g, ' ').toLowerCase();
    data.split(' ');
    if (data instanceof Array) {
      dataReturn = data
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    } else {
      dataReturn = data.charAt(0).toUpperCase() + data.slice(1);
    }
  }
  return dataReturn;
};

export const currencyFormat = (data) => {
  let dataReturn = '-';
  if (data) {
    dataReturn = `US $${parseFloat(data).toLocaleString('en-US')}`;
  }
  return dataReturn;
};

export const dateFormatFunction = (data) => {
  let dataReturn = '-';
  if (data) {
    if (moment(data).isValid()) {
      dataReturn = `${moment(data).format(dateFormat)}`;
    }
  }
  return dataReturn;
};

export const dateTimeFormatFunction = (data) => {
  let dataReturn = '-';
  if (data) {
    if (moment(data).isValid()) {
      dataReturn = `${moment(data).format(dateTimeFormat)}`;
    }
  }
  return dataReturn;
};
export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const DirectAPICAll = (method, url, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (method === 'get') {
        let res = await axios.get(`${url}`);
        console.log('responsode from api', res);
        resolve(res);
      } else if (method === 'post') {
        if (data) {
          let res = await axios.post(`${url}`, data);
          resolve(res);
        } else {
          let res = await axios.post(`${url}`);
          resolve(res);
        }
      }
    } catch (err) {
      console.log('responsode error from api', err);
      resolve(err);
    }
  });
};

export const useCustomMediaQuery = (queryString) => {
  const [isMatch, setIsMatch] = useState(false);
  const mqChange = (mq) => {
    setIsMatch(mq.matches);
  };

  useEffect(() => {
    const mq = window.matchMedia(queryString);
    mq.addListener(mqChange);
    mqChange(mq);

    return () => {
      mq.removeListener(mqChange);
    };
  }, [queryString]);

  return isMatch;
};
