import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './Registration.css';

import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import { mapData, handleRedirectInternal } from '../../product/common/components';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginTop: '10px',
    width: '100%',
  },
}));

const Registration = () => {
  const classes = useStyles();
  const history = useHistory();

  const authContext = useContext(AuthContext);

  const { isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      handleRedirectInternal(history, '');
    }
  }, [isAuthenticated]);

  return (
    <div className="registration">
      <div className="regBox container">
        <h1>Welcome to Nellis Auction</h1>
        {/* <h2>The Fastest Growing Online Auction Site In North America.</h2> */}
        <div className={classes.root}>
          <div>
            <div className="stepCompletion">
              <h3>You have successfully registered!</h3>
              <p>
                You must verify your email before bidding on items. Please check your email and
                verify your account using the provided link.
              </p>
              <object
                aria-label="Success"
                data="/assets/svg/successfulReg.svg"
                type="image/svg+xml"
              />
              {/* <PrimaryButton
                                label="Browse Products"
                                onClick={() => handleRedirectInternal(history, 'search')}
                            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
