import qs from 'qs';

export const createURL = (state) => `?${qs.stringify(state, { encode: false })}`;

export const searchStateToUrl = ({ location }, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : '';

export const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

export const DEBOUNCE_TIME = 700;
