import React, { useState, useContext, useEffect, useRef } from 'react';
import PrimaryButton from '../../custom/components/atoms/PrimaryButton';
import ReturnItemList from '../../custom/components/molecules/ReturnItemList';
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../Dashboard/DashboardLayout';
import ReturnContext from '../../custom/context/return/returnContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import { handleRedirectInternal } from '../../custom/common/components';
import ListViewSkeleton from '../../custom/components/molecules/ProductCard/ProductCardSkeletons/ListViewSkeleton';
import ProductView from '../../custom/components/organisms/ProductView';
import Drawer from '@material-ui/core/Drawer';
import { Pagination } from '@material-ui/lab';
import './Return.css';

const Return = () => {
  const returnContext = useContext(ReturnContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const history = useHistory();
  const { returns, getReturnEligibleItems, responseStatus, clearResponse } = returnContext;
  const { isAuthenticated } = authContext;

  const { setAlert } = alertContext;
  const [state, setState] = useState({
    right: false,
    bottom: false,
    data: {},
  });
  const [returnItems, setReturnItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    limit: 20,
    page: 1,
  });

  useEffect(() => {
    if (returns.items.length > 0) {
      setReturnItems(returns.items);
    }
    setLoading(false);
  }, [returns]);

  useEffect(() => {
    getReturnEligibleItems(search);
    setLoading(true);
  }, [search]);

  const changePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setState({ ...state, [anchor]: open, data: data });
    } else {
      setState({ ...state, [anchor]: open, data: {} });
    }
  };

  return (
    <DashboardLayout>
      <div>
        <h2 className="dashTitle">
          Returns
          <span>
            {returns.total > 0 ? <h6>{returns.total} return eligible product(s)</h6> : null}
          </span>
        </h2>
      </div>
      {loading ? (
        [...Array(10)].map((list, index) => {
          return <ListViewSkeleton key={index} />;
        })
      ) : returnItems.length ? (
        <>
          <Drawer
            className="rightDrawer"
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            <ProductView data={state.data} drawerHandler={toggleDrawer('right', false)} />
          </Drawer>
          <div className="d-flex justify-content-between">
            <div className="returnLt">
              {returnItems.map((item, index) => {
                return (
                  <ReturnItemList
                    key={index}
                    data={item}
                    drawerHandler={toggleDrawer('right', true, item)}
                  />
                );
              })}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
            <h6>
              Showing {returns.pageDesc} of {returns.total}
            </h6>
            <Pagination
              count={Math.ceil(returns.total / search.limit)}
              page={search.page}
              onChange={changePage}
              siblingCount={3}
              showFirstButton
              showLastButton
              boundaryCount={2}
            />
          </div>
        </>
      ) : (
        <div className="cart customContainer">
          <div className="d-flex justify-content-between">
            <div className="emptyCart">
              <object data="/assets/svg/emptyCart.svg" type="image/svg+xml" />
              <h4>You don't have any return eligible products</h4>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Return;
