import React, { useEffect, useState } from 'react';
import './App.css';
import Routes from './routes';
import { useHistory, useLocation } from 'react-router-dom';
import { setAuthToken, setIPAddress } from './product/common/api';
import { CookiesProvider } from 'react-cookie';
import CustomAuthState from './custom/context/auth/authState';
import ProductAuthState from './product/context/auth/authState';
import CustomCommonState from './custom/context/common/commonState';
import CustomProductState from './custom/context/product/productState';
import UserState from './custom/context/user/userState';
import AlertState from './custom/context/alert/alertState';
import ProductState from './custom/context/product/productState';
import InvoiceState from './custom/context/invoice/invoiceState';
import AuctionState from './custom/context/auction/auctionState';
import CartState from './custom/context/cart/cartState';
import ReturnState from './custom/context/return/returnState';
import BuyerState from './custom/context/buyer/buyerState';
import AutoPilotState from './custom/context/autopilot/autoPilotState';
import PayState from './custom/context/payment/payState';
import RefundState from './custom/context/refund/refundState';
import AppointmentState from './custom/context/appointment/appointmentState';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import Alerts from './custom/common/alert';
import publicIp from 'public-ip';
import { theme } from './utils/UI/theme';
import { MuiThemeProvider } from '@material-ui/core';
import { InstantSearch } from 'react-instantsearch-dom';
import { createURL, urlToSearchState, DEBOUNCE_TIME, searchStateToUrl } from './utils/Core';
import algoliasearch from './services/algolia';

global.site_url = process.env.REACT_APP_DOMAIN;
global.images_url = global.site_url + '/uploads/product/';

const searchClient = algoliasearch;

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        history.push(searchStateToUrl(history, updatedSearchState), updatedSearchState);
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [location]);

  useEffect(() => {
    publicIp.v4().then((address) => setIPAddress(address));
  }, []);

  return (
    <CookiesProvider>
      <InstantSearch
        indexName={process.env.REACT_APP_ALGOLIA_INDEX}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <CustomCommonState>
          <CustomAuthState>
            <ProductAuthState>
              <AlertState>
                <UserState>
                  <CustomProductState>
                    <ProductState>
                      <InvoiceState>
                        <AuctionState>
                          <CartState>
                            <ReturnState>
                              <PayState>
                                <RefundState>
                                  <AppointmentState>
                                    <BuyerState>
                                      <AutoPilotState>
                                        <MuiThemeProvider theme={theme}>
                                          <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <SnackbarProvider maxSnack={3}>
                                              <div className="App">
                                                <Alerts />
                                                <Routes />
                                              </div>
                                            </SnackbarProvider>
                                          </MuiPickersUtilsProvider>
                                        </MuiThemeProvider>
                                      </AutoPilotState>
                                    </BuyerState>
                                  </AppointmentState>
                                </RefundState>
                              </PayState>
                            </ReturnState>
                          </CartState>
                        </AuctionState>
                      </InvoiceState>
                    </ProductState>
                  </CustomProductState>
                </UserState>
              </AlertState>
            </ProductAuthState>
          </CustomAuthState>
        </CustomCommonState>
      </InstantSearch>
    </CookiesProvider>
  );
};

export default App;
