import React from 'react';
import { Pagination } from 'react-instantsearch-dom';
import { connectStateResults } from 'react-instantsearch-dom';
import './page-selector.css';

const PageSelector = ({ searchResults }) => {
  const nbHits = searchResults && searchResults.nbHits;
  const nbPages = searchResults && searchResults.nbPages;

  return (
    <>
      {nbHits > 0 && (
        <div className="pageSelector w-100">
          {/*TODO - change how the number of items is displayed*/}
          <Pagination
            showFirst
            showLast
            showPrevious
            showNext
            padding={4}
            translations={{
              previous: 'Back',
              next: 'Next',
              ariaPrevious: 'Previous Page',
              ariaNext: 'Next Page',
            }}
          />
          <p>{`${nbPages} ${nbPages === 1 ? 'Page' : 'Pages'} Total`}</p>
        </div>
      )}
    </>
  );
};

export default connectStateResults(PageSelector);
