import React, { useEffect, useRef, useState } from 'react';
import '../FilterPanel.css';
import { withStyles } from '@material-ui/core/styles';
import style from '../styles';
// import algoliasearch from 'algoliasearch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputBase,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleRedirectInternal } from '../../../../common/components';
import { useHistory } from 'react-router-dom';
import algoliasearch from '../../../../../services/algolia';

const client = algoliasearch;

const suggestedRetail = [
  { label: 'Any Price', value: '' },
  { label: 'Under $10', value: '0:10' },
  { label: '$10 - $100', value: '10:100' },
  { label: '$100 - $500', value: '100:500' },
  { label: 'Over $500', value: '500:' },
];

const HomeFilterPanel = ({ classes }) => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const [brandSearch, setBrandSearch] = useState('');
  const [brands, setBrands] = useState([]);
  const [openBrands, setOpenBrands] = useState(true);
  const [openRetail, setOpenRetail] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    // Returns the facet values from the Index based on the query brandSearch
    const fetchAlgoliaFacet = async (facet) => {
      const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);
      const { facetHits } = await index.searchForFacetValues(facet, brandSearch);
      return facetHits;
    };

    fetchAlgoliaFacet('Brand').then((result) => setBrands(result));

    return () => {
      mountedRef.current = false;
    };
  }, [brandSearch]);

  return (
    <div className="filterPanel">
      <div className="d-flex flex-column align-items-center filterTitleCnt">
        <h4 className="fpTitle">Filters</h4>
      </div>
      <div>
        <Accordion square expanded={openBrands}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setOpenBrands(!openBrands)}
            className={classes.accordionSummary}
          >
            <h6 className={'accTitle'}>{'Brands'}</h6>
          </AccordionSummary>
          <AccordionDetails className={'filterCheck d-flex align-items-center flex-wrap'}>
            <Paper component={'form'}>
              <InputBase
                placeholder={`Search Brands`}
                inputProps={{
                  'aria-label': `Search Brands`,
                }}
                value={brandSearch}
                onChange={(e) => {
                  setBrandSearch(e.target.value);
                }}
              />
              <IconButton
                type={'button'}
                aria-label={'clear search'}
                onClick={() => {
                  setBrandSearch('');
                }}
              >
                {brandSearch.length > 0 && <ClearIcon style={{ color: 'primary' }} />}
              </IconButton>
            </Paper>
            {brands.map((option, i) => (
              <FormGroup row key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={'primary'}
                      name={option.value}
                      value={option.value}
                      onChange={(event) => {
                        event.preventDefault();
                        handleRedirectInternal(
                          history,
                          `reactinstantsearch?refinementList[Brand][0]=${event.target.value}`
                        );
                      }}
                    />
                  }
                  label={option.value}
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={openRetail}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setOpenRetail(!openRetail)}
          >
            <h6 className={'accTitle'}>{'Suggested Retail'}</h6>
          </AccordionSummary>
          <AccordionDetails className={'filterCheck flex-column d-flex align-items-center'}>
            <RadioGroup
              aria-label={'Suggested Retail'}
              value={value}
              onChange={(event) => {
                event.preventDefault();
                setValue(event.target.value);
                handleRedirectInternal(
                  history,
                  `reactinstantsearch?multiRange[Suggested%20Retail]=${event.target.value}`
                );
              }}
            >
              {suggestedRetail.map((item) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    disabled={item.noRefinement}
                    label={item.label}
                    control={
                      <Radio
                        value={item.value}
                        name={'Suggested Retail'}
                        checked={item.isRefined}
                        color={'primary'}
                      />
                    }
                  />
                );
              })}
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
export default withStyles(style)(HomeFilterPanel);
