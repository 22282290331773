import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  primColor: 'rgb(174, 34, 7)',
  secColor: 'rgb(43, 43, 43)',
  accentColor: 'rgb(151, 151, 151)',
  backgroundColor: '#f5f5f5',
  starColor: '#fdcc0d',
};

export const theme = createMuiTheme({
  breakpoints: {
    keys: {
      0: 'MOBILE',
      1: 'SM',
      2: 'MD',
      3: 'LG',
      4: 'XL',
    },
    values: {
      MOBILE: 480,
      SM: 600,
      MD: 960,
      LG: 1280,
      XL: 1920,
    },
  },
  palette: {
    primary: {
      main: colors.primColor,
    },
    secondary: {
      main: colors.secColor,
    },
  },

  overrides: {
    MuiButton: {
      root: {},
      text: {},
      textPrimary: {},
    },

    MuiCheckbox: {
      root: {
        color: colors.primColor,
      },
    },

    MuiFormControlLabel: {
      root: {
        marginLeft: '0px',
        marginRight: '5px',
        marginBottom: '5px',
      },
    },

    MuiAccordion: {
      root: {
        boxShadow: 'none',
        margin: '5px 0px',
        '&:not(:last-child)': {
          // borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: '5px 0px',
        },
      },
      expanded: {},
    },

    MuiAccordionSummary: {
      root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
          minHeight: 50,
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
    },

    PrivateSwitchBase: {
      root: {
        padding: '5px 5px 5px 0px',
      },
    },

    MuiSkeleton: {
      root: {
        display: 'flex',
        backgroundColor: 'transparent',
      },
    },
  },
});
