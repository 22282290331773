import {
  GET_ALL_INVOICE,
  GET_ALL_SEARCH,
  GET_ALL_SIMILAR,
  GET_ALL_USER_INVOICES,
  GET_ALL_USER_RETURNS,
  GET_ALL_DASHBOARD,
  RESPONSE_STATUS,
  GET_ALL_BID_HISTORY,
  GET_ALL_ACTIVE_SORTS,
  GET_CART_ITEMS,
  GET_ALL_RETURN_INVOICE,
  CLEAR_RESPONSE,
  GET_SAVED_SEARCHES,
  ALGOLIA_PRODUCT_HITS,
} from './productTypes';

export default (state, action) => {
  switch (action.type) {
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case ALGOLIA_PRODUCT_HITS:
      return {
        ...state,
        algolia_producthits: action.payload,
      };
    case GET_ALL_SEARCH:
      return {
        ...state,
        search_allproducts: action.payload,
      };
    case GET_ALL_BID_HISTORY:
      return {
        ...state,
        search_allbidhistory: action.payload,
      };
    case GET_ALL_USER_INVOICES:
      return {
        ...state,
        search_alluserinvoices: action.payload,
      };
    case GET_ALL_USER_RETURNS:
      return {
        ...state,
        search_alluserreturns: action.payload,
      };

    case GET_ALL_SIMILAR:
      return {
        ...state,
        search_allsimilar: action.payload,
      };
    case GET_ALL_INVOICE:
      return {
        ...state,
        search_allinvoiceproducts: action.payload,
      };
    case GET_CART_ITEMS:
      return {
        ...state,
        cart_items: action.payload,
      };
    case GET_ALL_RETURN_INVOICE:
      return {
        ...state,
        search_allreturninvoiceproducts: action.payload,
      };

    case GET_ALL_ACTIVE_SORTS:
      return {
        ...state,
        active_sorts: action.payload,
      };
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        search_alldashboardproducts: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    case GET_SAVED_SEARCHES:
      return {
        ...state,
        saved_searches: action.payload,
      };
    default:
      return state;
  }
};
