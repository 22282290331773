import React, { useEffect, useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import { urlToSearchState } from '../../utils/Core';

const SearchInput = ({ currentRefinement, refine, iconColor, useStartAdornment }) => {
  const location = useLocation();
  const searchState = urlToSearchState(location);
  const [value, setValue] = useState(searchState.query || '');

  const handleChange = (event) => {
    refine(event.target.value);
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(searchState.query);
  }, [searchState.query]);

  return (
    <CustomInput
      inputStyle={{
        backgroundColor: 'white',
      }}
      name={'searchbar'}
      autoFocus
      className={'form-control'}
      placeholder={'Search'}
      aria-label={'Search'}
      aria-describedby={'searchIcon'}
      value={value || ''}
      onChange={handleChange}
      startAdornment={
        useStartAdornment && <SearchIcon style={{ marginRight: '3px' }} color={iconColor} />
      }
      endAdornment={
        (value || currentRefinement) && (
          <CloseIcon
            onClick={() => {
              setValue('');
              refine('');
            }}
            style={{ cursor: 'pointer' }}
          />
        )
      }
    />
  );
};

export default connectSearchBox(SearchInput);
