import React from 'react';
import Skeletons from '../../../Skeletons';
import '../ProductCard.css';

function SpecialEventSkeleton() {
  return (
    <div className="specialEventSkeleton">
      <Skeletons type="gridImage" />
      <div>
        <Skeletons type="title" />
        <Skeletons type="text" />
        <Skeletons type="text" />
      </div>
      <div>
        <Skeletons type="actionButton" />
        <Skeletons type="text" />
      </div>
    </div>
  );
}

export default SpecialEventSkeleton;
