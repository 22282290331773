import React, { useReducer } from 'react';
import CommonContext from './commonContext';
import CommonReducer from './commonReducer';
import { apiCall } from '../../../custom/common/api';
import { response } from '../../../custom/context/common';

import {
  COMMON_VALUES,
  BID_HISTORY_VALUE,
  US_STATE_VALUE,
  CLEAR_SEARCH,
  GET_STATIC_PAGE,
  CLEAR_BID_HISTORY,
  SEARCH_VALUE,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  CURRENT_LOCATION,
  GET_BANNERS,
} from './commonTypes';

const CommonState = (props) => {
  const initialState = {
    responseStatus: null,
    allCategory: [],
    allLocations: [],
    allCondition: [],
    allDamageTypes: [],
    allPackageTypes: [],
    allBidIncrements: [],
    allNotifications: [],
    searchValue: null,
    bidHistoryValue: 0,
    USStates: [],
    banners: [],
    static_page: {
      record: {},
    },
    currentLocation: {
      id: localStorage.getItem('currentLocationId') ? localStorage.getItem('currentLocationId') : 7,
      state: localStorage.getItem('currentState') ? localStorage.getItem('currentState') : 'NV',
      city: localStorage.getItem('currentCity') ? localStorage.getItem('currentCity') : 'Las Vegas',
    },
  };

  const [state, dispatch] = useReducer(CommonReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const getGlobalVariable = async (type) => {
    const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')]);

    if (res.data.status === 'success') {
      dispatch({
        type: COMMON_VALUES,
        payload: {
          allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
          allNotifications: res.data.data.responseData.tableValues.allall_notifications,
          allLocations: res.data.data.responseData.tableValues.alllocations,
          allCondition: res.data.data.responseData.tableValues.allconditionTypes,
          allDamageTypes: res.data.data.responseData.tableValues.alldamageTypes,
          allPackageTypes: res.data.data.responseData.tableValues.allpackageTypes,
          allBidIncrements: res.data.data.responseData.allBidIncrements,
          bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
        },
      });
    }
  };

  const getStaticPage = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'getStaticPage', formData, '', 'common')]);
      const from = 'StaticPage';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_STATIC_PAGE,
          payload: {
            record: res.data.data.responseData.record ? res.data.data.responseData.record : {},
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const setUSState = async (data) => {
    dispatch({
      type: US_STATE_VALUE,
      payload: data,
    });
  };

  const setSearchValue = async (data) => {
    dispatch({
      type: SEARCH_VALUE,
      payload: data,
    });
  };

  const setBidHistoryValue = async (data) => {
    dispatch({
      type: BID_HISTORY_VALUE,
      payload: data,
    });
  };

  const setCurrentLocation = async (data) => {
    dispatch({
      type: CURRENT_LOCATION,
      payload: data,
    });
  };

  const getBanners = async () => {
    const [res] = await Promise.all([apiCall('post', 'getBanners', {}, '', 'common')]);
    if (res.data.status == 'success') {
      dispatch({
        type: GET_BANNERS,
        payload: res.data.data.banners,
      });
    }
  };

  const clearBidHistoryValue = () =>
    dispatch({
      type: CLEAR_BID_HISTORY,
    });

  const clearSearchValue = () =>
    dispatch({
      type: CLEAR_SEARCH,
    });

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <CommonContext.Provider
      value={{
        allCategory: state.allCategory,
        allLocations: state.allLocations,
        allCondition: state.allCondition,
        allDamageTypes: state.allDamageTypes,
        allPackageTypes: state.allPackageTypes,
        allNotifications: state.allNotifications,
        allBidIncrements: state.allBidIncrements,
        banners: state.banners,
        allStarRating: [
          { id: 5 },
          { id: 4.5 },
          { id: 4 },
          { id: 3.5 },
          { id: 3 },
          { id: 2.5 },
          { id: 2 },
          { id: 1.5 },
          { id: 1 },
        ],
        searchValue: state.searchValue,
        static_page: state.static_page,
        bidHistoryValue: state.bidHistoryValue,
        responseStatus: state.responseStatus,
        USStates: state.USStates,
        currentLocation: state.currentLocation,
        getGlobalVariable,
        setSearchValue,
        clearSearchValue,
        setBidHistoryValue,
        clearBidHistoryValue,
        getStaticPage,
        setUSState,
        clearResponse,
        setCurrentLocation,
        getBanners,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};

export default CommonState;
