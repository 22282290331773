import React, { useState, useContext, useEffect, useRef } from 'react';
import AppointmentCard from '../../custom/components/molecules/AppointmentCard';
import DashboardLayout from './DashboardLayout';
import AppointmentContext from '../../custom/context/appointment/appointmentContext';
import AlertContext from '../../custom/context/alert/alertContext';
import { Pagination } from '@material-ui/lab';

function MyAppointments() {
  const { getMyAppointments, myAppointments, responseStatus, clearResponse } = useContext(
    AppointmentContext
  );
  const { setAlert } = useContext(AlertContext);

  let [viewProduct, setViewProduct] = useState([]);

  const [search, setSearch] = useState({
    limit: 12,
    page: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    getMyAppointments(search);
    setViewProduct([]);
    return () => {
      setViewProduct([]);
    };
  }, []);

  useEffect(() => {
    getMyAppointments(search);
  }, [search]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'cancelAppointment') {
        setAlert(responseStatus.message, responseStatus.status);
        clearResponse();
        getMyAppointments(search);
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    //console.log('my appointments ', myAppointments)
    setViewProduct(myAppointments.records.length ? myAppointments.records : []);
  }, [myAppointments]);

  return (
    <DashboardLayout>
      <div className="myInvoices">
        <div className="d-flex justify-content-between align-items-start w-100">
          <div>
            <h2 className="dashTitle">
              My Appointments
              <span>
                {viewProduct.length ? <h6>{viewProduct.length} Appointment(s)</h6> : null}
              </span>
            </h2>
          </div>
        </div>

        {viewProduct.length ? (
          <div className="searchResults d-flex justify-content-start flex-wrap">
            {viewProduct.map((data, index) => (
              <AppointmentCard key={index} data={data} type="invoice" />
            ))}
            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
              <h6>
                Showing {myAppointments.setDisp} of {myAppointments.totalRecords}
              </h6>
              <Pagination
                count={Math.ceil(myAppointments.totalRecords / search.limit)}
                page={search.page}
                onChange={onHandlePage}
                siblingCount={3}
                showFirstButton
                showLastButton
                boundaryCount={2}
              />
            </div>
          </div>
        ) : (
          <div className="np-pro-box"> No Records Found! </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default MyAppointments;
