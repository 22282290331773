import React from 'react';

const Shimmer = () => {
  return (
    <div className="shimmerWrapper">
      <div className="shimmer" />
    </div>
  );
};

export default Shimmer;
