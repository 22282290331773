import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../../custom/components/atoms/PrimaryButton';
import FavouriteCheckbox from '../../../../custom/components/atoms/FavoriteCheckbox';

import './ProductCard.css';
import Timer from '../../../../custom/common/timer';
import Bidding from '../../molecules/Bidding/BiddingItem';
import {
  dateFormatFront,
  handleRedirectInternal,
  currencyFormat,
} from '../../../common/components';
import CommonContext from '../../../context/common/commonContext';
import AuthContext from '../../../../custom/context/auth/authContext';
import { Highlight } from 'react-instantsearch-dom';
import { getStarRatingIcons } from '../../../../utils/UI/starRating';

const ListView = (props) => {
  const product = props.data;
  const commonContext = useContext(CommonContext);
  const authContext = useContext(AuthContext);

  const { isAuthenticated } = authContext;
  const { setBidHistoryValue } = commonContext;
  const history = useHistory();

  return (
    <>
      <div className="productCardList d-flex justify-content-between align-items-start">
        <div className="psListLt d-flex">
          <div className="pclImg">
            <img
              src={product.avatarorg}
              alt={product.leadDescription}
              className="curserPointer"
              onClick={props.drawerHandler}
            />
            {product.market_status === 'open' ? (
              <FavouriteCheckbox watchlisted={product.watchlistid} project_id={product.id} />
            ) : null}
            <div className="listBidStatus">
              {isAuthenticated && (
                <>
                  {product.bidtopstatus === 'outbid' && <h4 className="outBid">OUTBID</h4>}
                  {product.bidtopstatus === 'winner' && <h4 className="winning">WINNING</h4>}
                  {product.bidtopstatus === 'won' && <h4 className="winning">WON</h4>}
                  {product.bidtopstatus === 'lost' && <h4 className="outBid">LOST</h4>}
                </>
              )}
            </div>
          </div>

          <div className="listContent">
            <h2 onClick={props.drawerHandler} className="listProdTitle">
              <Highlight attribute={'Lead Description'} hit={product} tagName={'strong'} />
            </h2>
            <div className="listLotDetails">
              {product.starRating >= 0 ? (
                <p style={{ display: 'inline-block' }}>{getStarRatingIcons(product.starRating)}</p>
              ) : null}
            </div>

            <div className="listLotDetails">
              {product.nellisnotes ? (
                <p style={{ display: 'inline-block' }}>NOTES: {product.nellisnotes}</p>
              ) : (
                <p> </p>
              )}
            </div>
            {product.bidtopstatus === 'won' ? (
              <div className="listLotDetails statusMessage">
                {product.appointmentId === null ? (
                  product.buynow_status === 'canceled' ? (
                    <>
                      <span class="material-icons">cancel</span>
                      <h6> Returned / Canceled</h6>
                    </>
                  ) : product.market_status === 'relisted' ? (
                    <>
                      <span class="material-icons">event_busy</span>
                      <h6> Item Expired</h6>
                    </>
                  ) : (
                    <>
                      <span class="material-icons">announcement</span>
                      <h6> Needs Appointment</h6>
                    </>
                  )
                ) : product.appointmentStatusTypeId == '2' ||
                  product.appointmentStatusTypeId == '3' ? (
                  <>
                    <span class="material-icons">priority_high</span>
                    <h6> Ready for Pickup</h6>
                  </>
                ) : product.appointmentStatusTypeId == '4' ? (
                  <>
                    <span class="material-icons">check_circle</span>
                    <h6 style={{ display: 'inlineBlock' }}> Picked Up</h6>
                  </>
                ) : product.appointmentStatusTypeId == '5' ? (
                  <>
                    <span class="material-icons">event_busy</span>
                    <h6> Canceled</h6>
                  </>
                ) : (
                  <>
                    <span class="material-icons">schedule</span>
                    <h6> Appointment Scheduled</h6>
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className="listActBtn">
          {product.market_status === 'open' ? (
            <>
              <div className="listTimer d-flex justify-content-between align-items-center">
                <h6>Time Left:</h6>
                <p className="d-flex align-items-center">
                  <span className="material-icons">timer</span>
                  {product.date_closed ? (
                    <Timer
                      date_added={product.date_added}
                      date_closed={product.date_closed}
                      withText={0}
                    />
                  ) : null}
                </p>
              </div>
              <div className="listBidInfo d-flex justify-content-between align-items-center">
                <h6>{product.cbidtext}:</h6>
                <p className="d-flex justify-content-between align-items-center">
                  <span>{currencyFormat(product.wprice)}</span>
                  <span className="listDivider">|</span>
                  <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                    {product.bid_count} Bids
                  </span>
                </p>
              </div>
              <div className="listBidBox">
                <Bidding data={product} />
              </div>
            </>
          ) : product.market_status === 'closed' ? (
            <>
              <div className="listTimer d-flex justify-content-between align-items-center">
                <h6>Closed on:</h6>
                <p className="d-flex align-items-center">{dateFormatFront(product.date_closed)}</p>
              </div>
              <div className="listBidInfo d-flex justify-content-between align-items-center">
                <p className="d-flex justify-content-between align-items-center">
                  <span>Reserve Price Not Met</span>
                  <span className="listDivider">|</span>
                  <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                    {product.bid_count} Bids
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="listTimer d-flex justify-content-between align-items-center">
                <h6>Closed on:</h6>
                <p className="d-flex align-items-center">{dateFormatFront(product.date_closed)}</p>
              </div>
              <div className="listBidInfo d-flex justify-content-between align-items-center">
                <h6>{product.cbidtext}:</h6>
                <p className="d-flex justify-content-between align-items-center">
                  <span>{currencyFormat(product.buynowamount)}</span>
                  <span className="listDivider">|</span>
                  <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                    {product.bid_count} Bids
                  </span>
                </p>
              </div>
              {product.bidtopstatus === 'won' && (
                <div className="actionAfterWin">
                  {product.appointmentId === null &&
                  product.buynow_status === 'active' &&
                  product.market_status !== 'relisted' ? (
                    <>
                      <PrimaryButton
                        label="Make payment"
                        btnSize="small"
                        onClick={() => handleRedirectInternal(history, 'cart')}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </>
          )}
          <Button className="bidDetails" onClick={props.drawerHandler}>
            More Info
          </Button>
        </div>
      </div>
    </>
  );
};
export default ListView;
