import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './FilterPanel.css';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

const UnconnectedRefinement = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion square expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <h6 className={'accTitle'}>{title}</h6>
      </AccordionSummary>
      <AccordionDetails className={'filterCheck flex-column d-flex'}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default UnconnectedRefinement;
