import React, { useReducer } from 'react';
import ReturnContext from './returnContext';
import ReturnReducer from './returnReducer';
import { apiCall } from '../../common/api';
import { response } from '../common';

import {
  GET_ALL_SEARCH,
  CLEAR_RESPONSE,
  RESPONSE_STATUS,
  GET_SINGLE_CART,
  GET_RETURN_TYPES,
} from './returnTypes';

const ReturnState = (props) => {
  const initialState = {
    search_allproducts: {
      cartrecords: [],
      noncartrecords: [],
    },
    returns: {
      items: [],
      pageDesc: '',
      total: 0,
    },
    search_singlecart: {
      cartrecords: [],
      cartvalues: {},
      cartLocation: {},
    },
    responseStatus: null,
    returnTypes: {
      items: [],
    },
  };

  const [state, dispatch] = useReducer(ReturnReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const getSingleCartProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'checkout', formData, '', 'return')]);
      const from = 'cartsearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_SINGLE_CART,
          payload: {
            cartrecords: res.data.data.responseData.cartItems.length
              ? res.data.data.responseData.cartItems
              : [],
            cartvalues: res.data.data.responseData.cartValues,
            cartLocation: res.data.data.responseData.cartLocation,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const getReturnEligibleItems = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'return')]);
      const from = 'cartsearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_SEARCH,
          payload: {
            items: res.data.data.responseData.items.length ? res.data.data.responseData.items : [],
            pageDesc: res.data.data.responseData.pageDesc
              ? res.data.data.responseData.pageDesc
              : [],
            total: res.data.data.responseData.total ? res.data.data.responseData.total : 0,
          },
        });
      } else if (res.data.status === 'error') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: {
            status: res.data.status,
            message: res.data.data.message,
            type: res.data.data.responseType,
            from: from,
          },
        });
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  // Register User
  const changeOnCart = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'changeStatus', formData, '', 'return')]);
      resp.commonResponse(res.data, 'cartchange');
    } catch (err) {
      resp.commonErrorResponse('cartchange');
    }
  };

  const saveAddress = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'saveAddress', formData, '', 'return')]);
      resp.commonResponse(res.data, 'cartaddress');
    } catch (err) {
      resp.commonErrorResponse('cartaddress');
    }
  };

  const returnItemHandler = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'returnItem', formData, '', 'return')]);
      resp.commonResponse(res.data, 'returnItem');
    } catch (err) {
      resp.commonErrorResponse('returnItem');
    }
  };

  const getReturnTypes = async () => {
    try {
      const [res] = await Promise.all([apiCall('get', 'returnTypes', {}, '', 'return')]);
      console.log('get return types response::: ', res);
      dispatch({
        type: GET_RETURN_TYPES,
        payload: {
          items: res.data.data.returnTypes,
        },
      });
    } catch (err) {
      console.log('ERROR: ', err);
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <ReturnContext.Provider
      value={{
        returns: state.returns,
        search_singlecart: state.search_singlecart,
        responseStatus: state.responseStatus,
        returnTypes: state.returnTypes,
        getSingleCartProducts,
        clearResponse,
        saveAddress,
        getReturnEligibleItems,
        getReturnTypes,
        changeOnCart,
        returnItemHandler,
      }}
    >
      {props.children}
    </ReturnContext.Provider>
  );
};

export default ReturnState;
