import React, { useContext, useState, useEffect } from 'react';
import CustomInput from '../../product/components/atoms/Inputs/CustomInput';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import '../Login/Login.css';
import { mapData, handleRedirectInternal } from '../../product/common/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import AlertContext from '../../custom/context/alert/alertContext';
import UserContext from '../../custom/context/user/userContext';

const ForgotPassword = () => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);

  const { forgotPassword, responseStatus, clearResponse } = userContext;
  const { setAlert } = alertContext;

  const history = useHistory();
  const validationArray = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required!'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      forgotPassword(values);
    },
  });

  const forgotValues = [
    {
      label: 'Email address',
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email address',
      class: 'col-12',
      autoFocus: true,
      formik: formik,
    },
  ];

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'forgotPassword') {
        if (responseStatus.status === 'success') {
          handleRedirectInternal(history, '');
        }
      }
    }
  }, [responseStatus]);

  return (
    <div className="login forgotPassword">
      <div className="container">
        <div className="d-flex justify-content-center align-items-start">
          <div className="loginLt">
            <h1>Forgot Password ?</h1>
            <p>
              Please enter your registered email address and we will send you a password resent link
              shortly.
            </p>
            <form onSubmit={formik.handleSubmit} autocomplete="nofill">
              <div className="row">{Object.values(mapData(forgotValues))}</div>
              <PrimaryButton type="submit" label="SEND RESET PASSWORD LINK" btnSize="large" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
