import React, { useContext, useState, useEffect } from 'react';
import CustomInput from '../../product/components/atoms/Inputs/CustomInput';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import '../Login/Login.css';
import { mapData, handleRedirectInternal } from '../../product/common/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import AlertContext from '../../custom/context/alert/alertContext';
import UserContext from '../../custom/context/user/userContext';

const ForgotPassword = (props) => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);

  const { resetPassword, responseStatus, clearResponse } = userContext;

  const history = useHistory();
  const validationArray = Yup.object({
    user_token: Yup.string().required('Required!'),
    password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
    password_checker: Yup.number().moreThan(1, 'Password has to be Fair'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], "Password's not match")
      .required('Required!'),
  });

  const formik = useFormik({
    initialValues: {
      user_token: props.match.params.token,
      password: '',
      password_checker: 0,
      confirm_password: '',
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      resetPassword(values);
    },
  });

  const resetPasswordData = [
    {
      label: 'New Password',
      name: 'password',
      type: 'password',
      placeholder: 'Enter your new password',
      class: 'col-md-12',
      formik: formik,
    },
    {
      name: 'password_checker',
      type: 'password_checker',
      formik: formik,
      class: 'col-md-12',
      hideMessage: true,
    },
    {
      label: 'Confirm password',
      name: 'confirm_password',
      type: 'password',
      placeholder: 'Re-enter your password',
      class: 'col-md-12',
      formik: formik,
    },
  ];

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'resetPassword') {
        if (responseStatus.status === 'success') {
          handleRedirectInternal(history, '');
        }
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    if (formik.errors['password_checker']) {
      if (formik.values.password && formik.values.password.length < 8) {
        formik.setFieldError('password', formik.errors['password']);
      } else {
        formik.setFieldError('password', formik.errors['password_checker']);
      }
    }
  }, [formik.errors['password'], formik.errors['password_checker']]);

  return (
    <div className="login">
      <div className="container">
        <div className="d-flex justify-content-center align-items-start">
          <div className="loginLt">
            <h1>Reset Password</h1>
            <form onSubmit={formik.handleSubmit} autocomplete="nofill">
              <div className="row">{Object.values(mapData(resetPasswordData))}</div>
              <PrimaryButton type="submit" label="Change" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
