import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logo } from '../../../../utils/UI';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Divider,
  ListItem,
  Menu,
  MenuItem,
  SwipeableDrawer,
} from '@material-ui/core';
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton';
import AuthContext from '../../../../custom/context/auth/authContext';
import CommonContext from '../../../context/common/commonContext';
import AlertContext from '../../../../custom/context/alert/alertContext';
import { handleRedirectInternal } from '../../../common/components';
import FullScreenPopup from '../../organisms/FullScreenPopup';
import CustomDialog from '../../organisms/CustomDialog';
import ProductContext from '../../../../custom/context/product/productContext';
import { useFormik } from 'formik';
import openSocket from 'socket.io-client';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from '../../atoms/Inputs/CustomInput';

const MainHeader = () => {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { addSavedSearch, getCartItems, cart_items, responseStatus } = productContext;
  const {
    setSearchValue,
    allLocations,
    currentLocation,
    setCurrentLocation,
    searchValue,
  } = commonContext;
  const { allCategory } = commonContext;
  const [value, setValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [storeLocation, setStoreLocation] = useState(false);
  const locationArray = allLocations
    .filter((location) => {
      return location.active === 1;
    })
    .map((location) => {
      return {
        id: location.id,
        city: location.city,
        state: location.state,
      };
    });

  const toggleFullScreenPopup = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDirectFullScreenPopup = (value) => {
    setModalOpen(value);
  };

  const { user, isAuthenticated, isAdmin, logout } = authContext;
  const [showCategory, setShowCategory] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const { setAlert } = alertContext;
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (history, path) => {
    setAnchorEl(null);
    if (history && path && path !== 'backdropClick') {
      handleRedirectInternal(history, path);
    }
  };

  const onLogOut = () => {
    logout();
    setAlert('Logged out successfully', 'success');
    handleRedirectInternal(history, '');
  };

  const viewStoreLocation = () => {
    setStoreLocation(!storeLocation);
  };

  const formik = useFormik({
    initialValues: {
      searchbar: '',
      category: '',
    },
  });

  useEffect(() => {
    const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`);
    // update cart count when a buyer wins item
    socket.on('realclosedupdates', (data) => {
      if (data.usr != '' && user.id === parseInt(data.bpop_cbidder)) {
        getCartItems();
      }
    });
    return () => {
      socket.off('realclosedupdates', (data) => {
        if (data.usr != '' && user.id === parseInt(data.bpop_cbidder)) {
          getCartItems();
        }
      });
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getCartItems();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (Object.keys(currentLocation).length > 0) {
      localStorage.setItem('currentLocationId', currentLocation.id);
      localStorage.setItem('currentCity', currentLocation.city);
      localStorage.setItem('currentState', currentLocation.state);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'payment') {
        getCartItems();
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    if (cart_items.items) {
      setCartCount(cart_items.items.length);
    }
  }, [cart_items]);

  useEffect(() => {
    if (formik.values.category) {
      setSearchValue({
        filters: {
          category: {
            value: [formik.values.category],
          },
        },
      });
    }
  }, [formik.values]);

  const changeLocationHandler = (data) => {
    setCurrentLocation({
      id: data.id,
      city: data.city,
      state: data.state,
    });
  };

  const getOperationHours = (location) => {
    let date = new Date();
    const day = date.getDay();
    let i = allLocations.findIndex((loc) => {
      return loc.id == location.id;
    });
    if (i !== -1) {
      if (day == 0) {
        return allLocations[i].sundayHours;
      } else if (day == 6) {
        return allLocations[i].saturdayHours;
      } else {
        return allLocations[i].weeklyHours;
      }
    }
  };

  const addSavedSearchData = (search_text) => {
    addSavedSearch({
      search_text: search_text,
      city: currentLocation.city,
      state: currentLocation.state,
    });
  };

  useEffect(() => {
    if (
      searchValue &&
      searchValue.filters &&
      typeof searchValue.filters.searchbar !== 'undefined'
    ) {
      formik.setFieldValue('searchbar', searchValue.filters.searchbar.value);
    }
  }, [searchValue]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(`/reactinstantsearch?query=${value}`);
      toggleDirectFullScreenPopup(false);
    }
  };

  return (
    <div className="mainHeader customContainer d-flex justify-content-between align-items-center">
      <div className="headLt d-flex justify-content-start">
        <Link to="/">
          <img src={logo} alt="Nellis Auction Logo" />
        </Link>
        <div className="shopLocation">
          <div className="d-flex justify-content-start">
            <span className="material-icons">location_on</span>
            <div className="headLocation">
              <p>You’re Shopping</p>
              <Button onClick={() => setStoreLocation(true)}>
                {currentLocation.city}, {currentLocation.state}
                <span className="material-icons">expand_more</span>
              </Button>
              <p>OPEN {getOperationHours(currentLocation)}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* DESKTOP NAVIGATION */}
        <div className="headRt deskNav d-flex justify-content-start align-items-center">
          <ul className="d-flex justify-content-start align-items-center">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/search">Search</Link>
            </li>
            <li>
              <Link to="/reactinstantsearch">React Instant Search</Link>
            </li>

            {isAuthenticated ? (
              <>
                <li className="headCart">
                  <Link to="/cart">
                    {cartCount > 0 ? (
                      <>
                        <Badge badgeContent={cartCount} color="primary">
                          <span className="material-icons">shopping_cart</span>
                        </Badge>
                        {'    '}
                        My Cart
                      </>
                    ) : (
                      <>
                        <Badge color="primary">
                          <span className="material-icons">shopping_cart</span>
                        </Badge>
                        {'    '}
                        My Cart
                      </>
                    )}
                  </Link>
                </li>
                <li>
                  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <span className="material-icons" style={{ paddingRight: '10px' }}>
                      account_circle
                    </span>
                    Account
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleClose(history, 'dashboard/watchlist')}>
                      Watchlist
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(history, 'dashboard/auction')}>
                      My Auctions
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(history, 'myaccount')}>
                      My Account
                    </MenuItem>
                    <MenuItem onClick={() => onLogOut()}>Logout</MenuItem>
                  </Menu>
                </li>
              </>
            ) : (
              <li>
                <SecondaryButton
                  onClick={() => handleClose(history, 'login')}
                  btnSize="small"
                  label="Log In / Sign Up"
                />
              </li>
            )}
          </ul>
        </div>
        {/* MOBILE / TAB NAVIGATION */}
        <Button className="respNavBtn">
          <span className="material-icons" onClick={() => toggleFullScreenPopup()}>
            search
          </span>
        </Button>

        {isAuthenticated && (
          <Button className="respNavBtn" onClick={() => handleRedirectInternal(history, 'cart')}>
            <Badge badgeContent={cartCount} color="primary">
              <span className="material-icons">shopping_cart</span>
            </Badge>
          </Button>
        )}
        <Button className="respNavBtn" onClick={toggleDrawer('right', true)}>
          <span className="material-icons">menu</span>
        </Button>
        <React.Fragment>
          <SwipeableDrawer
            className="headerDrawer"
            anchor={'right'}
            open={state['right']}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            disableSwipeToOpen={false}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
          >
            <div className="headRt respNav d-flex justify-content-start align-items-center">
              <div className="naLogoHead d-flex justify-content-between align-items-center">
                <Link to="/">
                  <img src={logo} alt="Nellis Auction" height="60" />
                </Link>
                <Button className="headDrawerClose" onClick={toggleDrawer('right', false)}>
                  <span className="material-icons">clear</span>
                </Button>
              </div>
              <Divider />
              {showCategory ? (
                <>
                  <ul className="d-flex flex-wrap navCategories">
                    <Button className="navBack" onClick={() => setShowCategory(false)}>
                      <span className="material-icons" style={{ paddingRight: '10px' }}>
                        arrow_back
                      </span>
                      Back to main menu
                    </Button>
                    {allCategory.map((data, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => formik.setFieldValue('category', data.id)}
                      >
                        {data.description}
                      </ListItem>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="headerDrawerNavLinks">
                  <ul className="navRespLinks d-flex justify-content-start align-items-center">
                    <Link to="/" onClick={toggleDrawer('right', false)}>
                      <ListItem button>
                        <div>
                          <span className="material-icons">home</span>
                          Home
                        </div>
                      </ListItem>
                    </Link>
                    <ListItem button onClick={() => setShowCategory(true)}>
                      <div className="headCart justify-content-between w-100">
                        <span className="d-flex">
                          <span className="material-icons">category</span>
                          All Categories
                        </span>
                        <span style={{ paddingRight: '0' }} className="material-icons">
                          chevron_right
                        </span>
                      </div>
                    </ListItem>
                    <Link to="/search" onClick={toggleDrawer('right', false)}>
                      <ListItem button>
                        <div>
                          <span className="material-icons">search</span>
                          Search
                        </div>
                      </ListItem>
                    </Link>
                    <Link to="/reactinstantsearch" onClick={toggleDrawer('right', false)}>
                      <ListItem button>
                        <div>
                          <span className="material-icons">search</span>
                          React Instant Search
                        </div>
                      </ListItem>
                    </Link>

                    {isAuthenticated && (
                      <div className="w-100" onClick={toggleDrawer('right', false)}>
                        <ListItem button onClick={() => handleClose(history, 'dashboard/auction')}>
                          <div>
                            <span className="material-icons">gavel</span>
                            My Auctions
                          </div>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => handleClose(history, 'dashboard/watchlist')}
                        >
                          <div>
                            <span className="material-icons">favorite</span>
                            Watchlist
                          </div>
                        </ListItem>
                        <ListItem
                          button
                          className="headCart"
                          onClick={() => handleClose(history, 'cart')}
                        >
                          <div>
                            <span className="material-icons">shopping_cart</span>
                            Cart
                          </div>
                          <Badge badgeContent={cartCount} color="primary"></Badge>
                        </ListItem>
                        <ListItem button onClick={() => handleClose(history, 'appointments')}>
                          <div>
                            <span className="material-icons">book_online</span>
                            Appointments
                          </div>
                        </ListItem>
                        <ListItem button onClick={() => handleClose(history, 'receipts')}>
                          <div>
                            <span className="material-icons">receipt</span>
                            Receipts
                          </div>
                        </ListItem>
                        <ListItem
                          button
                          className="headCart"
                          onClick={() => handleClose(history, 'returns')}
                        >
                          <div>
                            <span className="material-icons">keyboard_return</span>
                            Returns
                          </div>
                          <Badge badgeContent={cartCount} color="primary"></Badge>
                        </ListItem>

                        {/* <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleClose(history, 'returninvoice')
                                                    }
                                                >
                                                    <div>
                                                        <span className="material-icons">
                                                            receipt
                                                        </span>
                                                        Return Invoices
                                                    </div>
                                                </ListItem> */}

                        <ListItem button onClick={() => handleClose(history, 'myaccount')}>
                          <div>
                            <span className="material-icons">account_circle</span>
                            My Account
                          </div>
                        </ListItem>
                        <ListItem button onClick={() => handleClose(history, 'saved_search')}>
                          <div>
                            <span className="material-icons">bookmark</span>
                            My Saved Searches
                          </div>
                        </ListItem>
                      </div>
                    )}
                    <ListItem button onClick={() => handleClose(history, 'help')}>
                      <div>
                        <span className="material-icons">help</span>
                        Help Desk
                      </div>
                    </ListItem>

                    <ListItem button onClick={() => handleClose(history, 'email')}>
                      <div>
                        <span className="material-icons">email</span>
                        Email
                      </div>
                    </ListItem>
                    <>
                      {isAuthenticated ? (
                        <ListItem button onClick={() => onLogOut()}>
                          <div>
                            <span className="material-icons">power_settings_new</span>
                            Logout
                          </div>
                        </ListItem>
                      ) : (
                        <li className="notLoggedLink" onClick={toggleDrawer('right', false)}>
                          <SecondaryButton
                            onClick={() => handleClose(history, 'login')}
                            btnSize="small"
                            label="Log In / Sign Up"
                          />
                        </li>
                      )}
                    </>
                  </ul>
                  <ul className="navRespLinks d-flex justify-content-start align-items-center">
                    <Divider />
                    <ListItem className="mobShopCurrLocation">
                      <div>
                        <span className="material-icons">location_on</span>
                        You’re Shopping in
                      </div>
                    </ListItem>
                    <ListItem className="mobShopLocation" onClick={toggleDrawer('right', false)}>
                      <Button onClick={() => setStoreLocation(true)}>
                        {currentLocation.city}, {currentLocation.state}
                        <span className="material-icons">expand_more</span>
                      </Button>

                      <p>OPEN {getOperationHours(currentLocation)}</p>
                    </ListItem>
                  </ul>
                </div>
              )}
            </div>
          </SwipeableDrawer>
        </React.Fragment>
        <FullScreenPopup
          modaltitle="Search"
          open={modalOpen}
          handleClose={() => toggleDirectFullScreenPopup(false)}
        >
          <div className="searchSlide">
            <h3>
              Search Products in<span>{currentLocation.city}</span>
            </h3>
            <div className="mb-3 searchHeaderInput">
              <CustomInput
                inputStyle={{
                  backgroundColor: 'white',
                }}
                name={'homeSearchbar'}
                autoFocus
                className={'form-control'}
                placeholder={'Search'}
                aria-label={'Search'}
                aria-describedby={'searchIcon'}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onKeyUp={(event) => handleKeyDown(event)}
                startAdornment={<SearchIcon style={{ marginRight: '3px' }} color={'primary'} />}
                endAdornment={
                  value && (
                    <CloseIcon
                      onClick={() => {
                        setValue('');
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  )
                }
              />
            </div>
            {formik.values.searchbar && (
              <Button className="mb-3" onClick={() => addSavedSearchData(formik.values.searchbar)}>
                <span className="material-icons">bookmark_add</span>
                Save this search
              </Button>
            )}
            <h4>Popular Categories</h4>
            <div className="popularCatg flex-wrap d-flex justify-content-start align-items-center">
              {allCategory.map((data, index) => (
                <div
                  className="catgType d-flex justify-content-center align-items-center"
                  key={index}
                >
                  <label
                    htmlFor={`category_${index}`}
                    onClick={() => toggleDirectFullScreenPopup(false)}
                  >
                    <ListItem
                      button
                      onClick={() => {
                        formik.setFieldValue('category', data.id);
                      }}
                    >
                      <h4>
                        {data.description} {data.id}
                      </h4>
                    </ListItem>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </FullScreenPopup>
        <CustomDialog
          className="changeLocation"
          title={'Shopping Location'}
          open={storeLocation}
          function={viewStoreLocation}
        >
          <h4>You are currently shopping in</h4>
          <p className="currLocation d-flex">
            {currentLocation.city}, {currentLocation.state}
          </p>
          <Divider />
          <div className="otherLocation">
            <h4>Other Locations</h4>
            {locationArray
              .filter((x) => x.id != currentLocation.id)
              .map((data, index) => (
                <ListItem key={index} button onClick={() => changeLocationHandler(data)}>
                  {data.city}, {data.state}
                </ListItem>
              ))}
          </div>
          <p></p>
        </CustomDialog>
      </div>
    </div>
  );
};
export default MainHeader;
