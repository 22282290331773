import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { socket } from '../../product/common/socket';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect';
import CustomMultiSelect from '../../product/components/atoms/Inputs/CustomMultiSelect';
import MenuItem from '@material-ui/core/MenuItem';
import CustomInput from '../../product/components/atoms/Inputs/CustomInput';
import CustomPhone from '../../product/components/atoms/Inputs/CustomPhone.js';
import PasswordStrength from '../../product/common/passwordStrength';

import CheckBox from '../../product/components/atoms/CheckBox';
import CustomTextArea from '../../product/components/atoms/Inputs/CustomTextArea';
import CKEditor from 'ckeditor4-react';

let serverTime = new Date();

const monthFormat = 'YYYY-MM';
const dateFormat = 'MM-DD-YYYY';
const dateTimeFormat = 'MM-DD-YYYY h:mm a';

if (socket) {
  socket.on('sliservertime', (data) => {
    if (moment(data.dTime).isValid()) {
      serverTime = new Date(data.dTime);
    }
  });
}
export const mapData = (page) => {
  let data = page.map((data, index) => (
    <div key={index} className={data.class}>
      {data.type === 'select' ? (
        <>
          <CustomSelect
            label={data.label}
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            placeholder={data.placeholder}
            onBlur={data.formik.handleBlur}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          >
            {data.options.map((opt, optindex) => (
              <option key={optindex} value={opt.value}>
                {opt.show}
              </option>
            ))}
          </CustomSelect>
        </>
      ) : data.type === 'phone' ? (
        <>
          <CustomPhone
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={(phone) => data.formik.setFieldValue(data.name, phone)}
            label={data.label}
            placeholder={data.placeholder}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'multiselect' ? (
        <>
          <CustomMultiSelect
            label={data.label}
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            placeholder={data.placeholder}
            onBlur={data.formik.handleBlur}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          >
            {data.options.map((opt, optindex) => (
              <MenuItem key={optindex} value={opt.value}>
                {opt.show}
              </MenuItem>
            ))}
          </CustomMultiSelect>
        </>
      ) : data.type === 'ckeditor' ? (
        <>
          {/* <CKEditor
                        editor={ClassicEditor}
                        data={data.formik.values[data.name] ? data.formik.values[data.name] : ''}
                        onChange={(event, editor) => {
                            data.formik.setFieldValue(data.name, editor.getData())
                        }}
                    /> */}
          <CKEditor
            data={data.formik.values[data.name]}
            onChange={(event, editor) => {
              data.formik.setFieldValue(data.name, event.editor.getData());
            }}
          />
        </>
      ) : data.type === 'password_checker' ? (
        <>
          <PasswordStrength data={data} />
        </>
      ) : data.type === 'date' ? (
        <>
          <KeyboardDatePicker
            margin="0"
            autoOk={true}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            format={dateFormat}
            disabled={data.disabled}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(dateFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      ) : data.type === 'month' ? (
        <>
          <KeyboardDatePicker
            margin="0"
            autoOk={true}
            openTo="year"
            views={['year', 'month']}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            format={monthFormat}
            disabled={data.disabled}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(monthFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      ) : data.type === 'datetime' ? (
        <>
          <KeyboardDateTimePicker
            margin="0"
            autoOk={true}
            showTodayButton={true}
            id={data.name}
            inputVariant="outlined"
            label={data.label}
            format={dateTimeFormat}
            disabled={data.disabled}
            className="customDatepicker"
            value={moment()}
            inputValue={data.formik.values[data.name]}
            onChange={(val) => {
              data.formik.setFieldValue(data.name, moment(val).format(dateTimeFormat));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      ) : data.type === 'textarea' ? (
        <>
          <CustomTextArea
            id={data.id}
            value={data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            label={data.label}
            placeholder={data.placeholder}
            type={data.type}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
          />
        </>
      ) : data.type === 'checkbox' ? (
        <>
          <CheckBox
            checked={data.formik.values[data.name]}
            label={data.label}
            value={true}
            onchange={() => {
              data.formik.setFieldValue(data.name, !data.formik.values[data.name]);
            }}
            name={data.name}
          />
        </>
      ) : data.type === 'checkboxarray' ? (
        data.item.map((d, i) => (
          <>
            <CheckBox
              name={data.name}
              label={d.description}
              checked={data.formik.values[data.name].indexOf(d.id.toString()) !== -1 ? true : false}
              value={d.id.toString()}
              onchange={data.formik.handleChange}
            />
          </>
        ))
      ) : (
        <>
          <CustomInput
            id={data.id}
            value={data.value ? data.value : data.formik.values[data.name]}
            autoFocus={data.autoFocus}
            name={data.name}
            disabled={data.disabled}
            shrink={data.formik.values[data.name] && true}
            onBlur={data.formik.handleBlur}
            onChange={data.onChange ? data.onChange : data.formik.handleChange}
            label={data.label}
            placeholder={data.placeholder}
            type={data.type}
            startAdornment={data.startAdornment}
            endAdornment={data.endAdornment}
            error={data.formik.touched[data.name] && data.formik.errors[data.name]}
            helperText={
              data.formik.errors[data.name] &&
              data.formik.touched[data.name] &&
              data.formik.errors[data.name]
            }
            autoComplete={data.autoComplete}
          />
        </>
      )}
    </div>
  ));
  return data;
};

export const handleRedirectInternal = (history, path) => {
  history.push(`/${path}`);
  window.scrollTo(0, 0);
};
export const dateFormatFront = (data) => {
  return moment(data).isValid()
    ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')} PT`
    : '-';
};

export const dateFormatFrontDay = (data) => {
  return `${moment(data).format('MMM Do YYYY')}`;
};

export const capitalize = (data) => {
  return data.charAt(0).toUpperCase() + data.slice(1);
};

export const currencyFormat = (data) => {
  return `$${parseFloat(data).toFixed('2').toLocaleString('en-US')}`;
};

export const dateFormatFunction = (data) => {
  return `${moment(data).format(dateFormat)}`;
};

export const dateTimeFormatFunction = (data) => {
  return `${moment(data).format(dateTimeFormat)}`;
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const checkProductOpen = (date_added, user_role) => {
  let startDate = new Date(serverTime);
  let addDate = new Date(date_added);
  let milliSeconds = 0;
  // if (parseInt(user_role) !== 3) {
  //   milliSeconds = 1 * 3600000;
  // } else {
  // }
  let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
  let newaddDate = new Date(incrementedTime);
  if (newaddDate > startDate) {
    return false;
    // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
    // let timerTime = incrementedTime - startDate.getTime();
    // if (timerTime <= 0) {
    //   return true;
    // } else {
    //   return false;
    // }
  } else {
    return true;
  }
};

export const maskEmailFront = (data) => {
  data = data.toString();
  var first4 = data.substring(0, 4);
  var last5 = data.substring(data.length - 5);
  var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*');
  return first4 + mask + last5;
};

export const useCustomMediaQuery = (queryString) => {
  const [isMatch, setIsMatch] = useState(false);
  const mqChange = (mq) => {
    setIsMatch(mq.matches);
  };

  useEffect(() => {
    const mq = window.matchMedia(queryString);
    mq.addListener(mqChange);
    mqChange(mq);

    return () => {
      mq.removeListener(mqChange);
    };
  }, [queryString]);

  return isMatch;
};
