import React, { useState, useContext, useEffect, useRef } from 'react';
import './FilterPanel.css';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBox from '../../../../product/components/atoms/CheckBox';
import RadioBox, { GreenRadio } from '../../../../product/components/atoms/RadioBox';
import CustomProductContext from '../../../context/product/productContext';
import CommonContext from '../../../context/common/commonContext';
import { useFormik } from 'formik';
import { Button, Slider } from '@material-ui/core';
import { getStarRatingIcons } from '../../../../utils/UI/starRating';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 50,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const FilterPanel = (props) => {
  const commonContext = useContext(CommonContext);
  const productContext = useContext(CustomProductContext);
  const { active_sorts, getAllActiveSorts } = productContext;
  const {
    allCategory,
    allCondition,
    allLocations,
    allStarRating,
    setSearchValue,
    currentLocation,
  } = commonContext;
  const [expanded, setExpanded] = React.useState('panel0');

  const sortValues = props.sorts;
  const formik = useFormik({
    initialValues: {
      filters: {
        category: {
          value: [],
          type: 'array',
          field: 'it.categoryTypeId',
        },
        condition: {
          value: [],
          type: 'array',
          field: 'it.conditionTypeId',
        },
        location: {
          value: [],
          type: 'array',
          field: 'p.location_id',
        },
        price: {
          value: '',
          type: 'range',
          field: 'p.wprice',
        },
        searchbar: {
          value: '',
          type: 'like',
          field: 'it.leadDescription',
        },
        auctionlotId: {
          value: [],
          type: 'array',
          field: 'p.auctionlotId',
        },
        starRating: {
          value: [],
          type: 'array',
          field: 'it.starRating',
        },
        currentLocation: {
          state: currentLocation.state,
          city: currentLocation.city,
        },
      },
    },
  });
  const [priceRange, setPriceRange] = useState(
    formik.values.filters.price.value !== '' ? formik.values.filters.price.value : []
  );

  useEffect(() => {
    getAllActiveSorts({
      filters: {
        currentLocation: {
          state: currentLocation.state,
          city: currentLocation.city,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (props.search) {
      let searchValue = props.search.filters;
      if (searchValue.category.value.length > 0) {
        formik.values.filters.category.value = searchValue.category.value.map((cat) => {
          return cat.toString();
        });
      } else {
        formik.values.filters.category.value = [];
      }

      if (searchValue.auctionlotId.value.length > 0) {
        formik.values.filters.auctionlotId.value = searchValue.auctionlotId.value;
      }

      if (searchValue.condition.value.length > 0) {
        formik.values.filters.condition.value = searchValue.condition.value.map((cat) => {
          return cat.toString();
        });
      } else {
        formik.values.filters.condition.value = [];
      }
      if (searchValue.location.value.length > 0) {
        formik.values.filters.location.value = searchValue.location.value.map((cat) => {
          return cat.toString();
        });
      } else {
        formik.values.filters.location.value = [];
      }
      if (searchValue.price.value) {
        formik.values.filters.price.value = searchValue.price.value;
        setPriceRange(searchValue.price.value);
      } else {
        formik.values.filters.price.value = '';
      }
      if (searchValue.starRating.value.length > 0) {
        formik.values.filters.starRating.value = searchValue.starRating.value.map((star) => {
          return star;
        });
      }
      if (searchValue.searchbar.value !== '') {
        formik.values.filters.searchbar.value = searchValue.searchbar.value;
      }
    }
  }, [props.search]);

  useEffect(() => {
    // on search page
    if (props.from === 1) {
      const searchConst = props.search;
      const formikValues = formik.values;
      props.setSearch({ ...searchConst, ...formikValues });
    }
    // on home page
    else {
      if (
        formik.values.filters.category.value.length > 0 ||
        formik.values.filters.condition.value.length > 0 ||
        formik.values.filters.location.value.length > 0 ||
        formik.values.filters.price.value !== '' ||
        formik.values.filters.starRating.value.length > 0
      ) {
        setSearchValue(formik.values);
      }
    }
  }, [formik.values]);

  const locationInitialLoad = useRef(true);
  useEffect(() => {
    if (locationInitialLoad.current == true) {
      locationInitialLoad.current = false;
    } else {
      getAllActiveSorts({
        filters: {
          currentLocation: {
            state: currentLocation.state,
            city: currentLocation.city,
          },
        },
      });
    }
  }, [currentLocation]);

  useEffect(() => {
    if (Object.keys(active_sorts).length) {
      if (active_sorts.maxPrice && priceRange.length === 0) {
        setPriceRange([active_sorts.minPrice, active_sorts.maxPrice]);
      }
    }
  }, [active_sorts]);

  let sortedCategories = [];
  let sortedConditions = [];
  let sortedCountries = [];
  let starRatingFilter = [];
  if (sortValues) {
    if (sortValues.categorysort) {
      sortedCategories = allCategory.filter((inner) =>
        sortValues.categorysort.includes(inner.id.toString())
      );
    } else {
      sortedCategories = allCategory;
    }
    if (sortValues.conditionsort) {
      sortedConditions = allCondition.filter((inner) => {
        if (sortValues.conditionsort.includes(inner.id.toString()) && inner.active === 1) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      sortedConditions = allCondition;
    }

    if (sortValues.locationsort) {
      sortedCountries = allLocations.filter((inner) =>
        sortValues.locationsort.includes(inner.id.toString())
      );
    } else {
      sortedCountries = allLocations;
    }

    if (sortValues.starRatingFilter) {
      starRatingFilter = sortValues.starRatingFilter.map((star) => {
        return { id: star };
      });
    } else {
      starRatingFilter = allStarRating;
    }
  } else if (active_sorts) {
    const sortValues = active_sorts.sorts;
    if (sortValues.categorysort) {
      sortedCategories = allCategory.filter((inner) =>
        sortValues.categorysort.includes(inner.id.toString())
      );
    } else {
      sortedCategories = allCategory;
    }
    if (sortValues.conditionsort) {
      sortedConditions = allCondition.filter((inner) => {
        if (sortValues.conditionsort.includes(inner.id.toString()) && inner.active === 1) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      sortedConditions = allCondition;
    }

    if (sortValues.locationsort) {
      sortedCountries = allLocations.filter((inner) => {
        return sortValues.locationsort.includes(inner.id.toString());
      });
    } else {
      sortedCountries = allLocations;
    }

    if (sortValues.starRatingFilter) {
      starRatingFilter = sortValues.starRatingFilter.map((star) => {
        return { id: star };
      });
    } else {
      starRatingFilter = allStarRating;
    }
  } else {
    sortedCategories = allCategory;
    sortedConditions = allCondition;
    sortedCountries = allLocations;
    starRatingFilter = allStarRating;
  }

  const sliderHandleChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const priceRangeHandler = (event, newValue) => {
    formik.setFieldValue('filters.price', {
      value: newValue,
      type: 'range',
      field: 'p.wprice',
    });
  };

  const filterValues = [
    {
      title: 'Category',
      type: 'check',
      name: 'category',
      item: sortedCategories,
    },
    {
      title: 'Condition',
      type: 'check',
      name: 'condition',
      item: sortedConditions,
    },
    {
      title: 'Location',
      type: 'check',
      name: 'location',
      item: sortedCountries,
    },
    {
      title: 'Price',
      type: 'slider',
      name: 'price',
      item: priceRange,
    },
    {
      title: 'Star Rating',
      type: 'check',
      name: 'starRating',
      item: starRatingFilter,
    },
  ];

  function sliderText(value) {
    return `$${value}`;
  }

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === expanded && !newExpanded) {
      setExpanded(newExpanded ? panel : false);
    } else {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [window.innerWidth, windowWidth]);

  return (
    <div className="filterPanel">
      <div className="d-flex justify-content-between align-items-center filterTitleCnt">
        <h4 className="fpTitle">Filters</h4>
        {props.clearSearch && (
          <Button onClick={() => props.clearSearch()} className="filterClear">
            Clear
          </Button>
        )}
      </div>
      <div className="filterAcc">
        {filterValues.map((data, index) => (
          <Accordion
            key={index}
            square
            expanded={
              expanded === `panel${index}` ||
              (windowWidth > 1024 ? formik.values.filters[data.name].value.length > 0 : false)
            }
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id={`panel${index}d-header`}
            >
              <h6 className="accTitle">{data.title}</h6>
            </AccordionSummary>
            <AccordionDetails>
              {data.item.length > 0 ? (
                <div className="filterCheck d-flex align-items-center flex-wrap">
                  {data.type === 'check' ? (
                    data.item.map((d, i) => (
                      <React.Fragment key={i}>
                        <CheckBox
                          name={`filters.${data.name}.value`}
                          label={
                            data.name === 'location'
                              ? `${d.name}`
                              : data.name === 'starRating'
                              ? getStarRatingIcons(d.id)
                              : d.description
                          }
                          checked={
                            formik.values.filters[data.name].value.indexOf(d.id.toString()) !== -1
                          }
                          value={d.id.toString()}
                          onChange={formik.handleChange}
                        />
                      </React.Fragment>
                    ))
                  ) : data.type === 'radio' ? (
                    <RadioBox
                      items={data.item}
                      value={formik.values.filters[data.name].value}
                      onchange={formik.handleChange}
                      name={`filters.${data.name}.value`}
                      int={1}
                    />
                  ) : (
                    <div className="priceSlider">
                      <h5>
                        {sliderText(data.item[0])}
                        <span> to </span>
                        {sliderText(data.item[1])}
                      </h5>
                      <Slider
                        value={data.item}
                        onChange={sliderHandleChange}
                        onChangeCommitted={priceRangeHandler}
                        valueLabelDisplay="off"
                        aria-labelledby="range-slider"
                        getAriaValueText={sliderText}
                        min={active_sorts.minPrice}
                        max={active_sorts.maxPrice}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <small>{`No ${data.title} found`}</small>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
export default FilterPanel;
