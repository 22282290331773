import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const TopHeader = () => {
  return (
    <div className="topHeader d-flex justify-content-end align-items-center">
      <ul className="customContainer d-flex justify-content-end align-items-center">
        <li>
          <Link to="#">
            <span className="material-icons">help</span>Help Desk
          </Link>
        </li>
        <li>
          <Link to="#">
            <span className="material-icons">email</span>Email
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default TopHeader;
