import React, { useState, useContext, useEffect, useRef } from 'react';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import CustomInput from '../../product/components/atoms/Inputs/CustomInput';
import CartItem from '../../custom/components/molecules/ProductCard/CartItem';
import './Checkout.css';
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect';
import { Button, InputAdornment, ListItem } from '@material-ui/core';
import RadioBox from '../../custom/components/atoms/RadioBox';
import CheckBox from '../../custom/components/atoms/CheckBox';
import Popup from '../../custom/components/organisms/Popup';
import ProductView from '../../custom/components/organisms/ProductView';
import { useHistory } from 'react-router-dom';
import AppointmentContext from '../../custom/context/appointment/appointmentContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import ProductContext from '../../custom/context/product/productContext';
import CommonContext from '../../custom/context/common/commonContext';
import {
  mapData,
  handleRedirectInternal,
  currencyFormat,
  dateFormatFrontDay,
  dateTimeFormatFunction,
} from '../../custom/common/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import momentTz from 'moment-timezone';
import CryptoJS from 'crypto-js';
import aes from 'crypto-js/aes';
import { FormatLineSpacing } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';

const today = new Date();
let appointmentSchedule = [
  { date: dateFormatFrontDay(today), label: 'Today' },
  {
    date: dateFormatFrontDay(new Date(today.getTime() + 24 * 3600000)),
    label: 'Tomorrow',
  },
  {
    date: dateFormatFrontDay(new Date(today.getTime() + 48 * 3600000)),
    label: 'In 2 days',
  },
  {
    date: dateFormatFrontDay(new Date(today.getTime() + 62 * 3600000)),
    label: 'In 3 days',
  },
];

const allPayments = [
  { value: 'card', show: 'Credit/Debit Card' },
  { value: 'curbside', show: 'Curbside' },
];

const allCards = [
  { id: 'single', description: 'Single Card' },
  { id: 'multiple', description: 'Multiple Card' },
];

const Checkout = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { USStates, allLocations } = commonContext;
  const {
    available_appointments,
    getAvailbleAppointments,
    responseStatus,
    clearResponse,
  } = useContext(AppointmentContext);

  const { loadUser, user } = authContext;
  const {
    checkoutCart,
    responseStatus: responseStatusPayment,
    clearResponse: clearResponsePayment,
  } = useContext(ProductContext);
  const { setAlert } = alertContext;
  const history = useHistory();
  let [cartProducts, setCartProducts] = useState([]);
  let [availAppoints, setAvailAppoints] = useState([]);
  let [cartValues, setCartValues] = useState({});
  const [checkoutData, setCheckoutData] = useState({});
  let [cartLocation, setCartLocation] = useState({});
  let [allAvailableTime, setAllAvailableTime] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [taxExemptBtn, setTaxExemptBtn] = useState(false);
  const [storeCredit, setStoreCredit] = useState({
    amount: 0,
    applied: false,
    appliedAmt: 0,
  });
  const [drawer, setDrawer] = useState({
    right: false,
    bottom: false,
    data: {},
  });

  useEffect(() => {
    if (cartLocation) {
      const weeklyHours =
        cartLocation.weeklyHours && cartLocation.weeklyHours !== 'Closed'
          ? cartLocation.weeklyHours.split('-')
          : null;

      const saturdayHours =
        cartLocation.saturdayHours && cartLocation.saturdayHours !== 'Closed'
          ? cartLocation.saturdayHours.split('-')
          : null;

      const sundayHours =
        cartLocation.sundayHours && cartLocation.sundayHours !== 'Closed'
          ? cartLocation.sundayHours.split('-')
          : null;

      console.log('weeklyHours', weeklyHours);

      let weeklyHoursStart = weeklyHours ? moment(weeklyHours[0], ['h A']).format('HH') : 0;
      let weeklyHoursEnd = weeklyHours ? moment(weeklyHours[1], ['h A']).format('HH') : 0;

      let saturdayHoursStart = saturdayHours ? moment(saturdayHours[0], ['h A']).format('HH') : 0;
      let saturdayHoursEnd = saturdayHours ? moment(saturdayHours[1], ['h A']).format('HH') : 0;

      let sundayHoursStart = sundayHours ? moment(sundayHours[0], ['h A']).format('HH') : 0;
      let sundayHoursEnd = sundayHours ? moment(sundayHours[1], ['h A']).format('HH') : 0;

      if (cartLocation.timezone) {
        const dayOfWeek = new Date().getDay();
        let hoursEnd;
        // Sunday
        if (dayOfWeek === 0) {
          hoursEnd = sundayHoursEnd;
        }
        // Saturday
        else if (dayOfWeek === 6) {
          hoursEnd = saturdayHoursEnd;
        }
        // Weekdays
        else {
          hoursEnd = weeklyHoursEnd;
        }
        // current date in cart location
        let currentDatetime = moment().tz(cartLocation.timezone).format();
        // end hour in cart location
        let endTime = moment()
          .tz(cartLocation.timezone)
          .set('hour', parseInt(hoursEnd) - 1)
          .set('minute', 45)
          .set('second', 0)
          .format();
        // no more available slot for today
        if (endTime < currentDatetime) {
          appointmentSchedule.splice(0, 1);
        }
      }

      // 1 - Monday 2 - Tuesday
      setDataArray([
        {
          date: 0,
          minTime: sundayHoursStart,
          maxTime: sundayHoursEnd,
        },
        {
          date: 1,
          minTime: weeklyHoursStart,
          maxTime: weeklyHoursEnd,
        },
        {
          date: 2,
          minTime: weeklyHoursStart,
          maxTime: weeklyHoursEnd,
        },
        {
          date: 3,
          minTime: weeklyHoursStart,
          maxTime: weeklyHoursEnd,
        },
        {
          date: 4,
          minTime: weeklyHoursStart,
          maxTime: weeklyHoursEnd,
        },
        {
          date: 5,
          minTime: weeklyHoursStart,
          maxTime: weeklyHoursEnd,
        },
        {
          date: 6,
          minTime: saturdayHoursStart,
          maxTime: saturdayHoursEnd,
        },
      ]);
    }
  }, [cartLocation]);

  useEffect(() => {
    getAvailbleAppointments();
    // checkout content
    if (sessionStorage.getItem('checkout')) {
      loadUser();
      var bytes = aes.decrypt(
        sessionStorage.getItem('checkout'),
        process.env.REACT_APP_AES_ENCRYPTION_SECRET
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setCheckoutData(decryptedData);
    } else {
      handleRedirectInternal(history, 'cart');
    }
  }, []);

  useEffect(() => {
    if (allLocations.length > 0 && Object.keys(checkoutData).length > 0) {
      let currentLocation = allLocations.filter((location) => {
        return location.id == checkoutData.locationId;
      });
      setCartLocation(currentLocation[0]);
    }
  }, [checkoutData, allLocations]);

  useEffect(() => {
    if (available_appointments) {
      setAvailAppoints(available_appointments.records.length ? available_appointments.records : []);
    }
  }, [available_appointments]);

  useEffect(() => {
    if (responseStatusPayment) {
      if (responseStatusPayment.from === 'payment') {
        if (responseStatusPayment.status === 'success') {
          sessionStorage.removeItem('checkout');
          handleRedirectInternal(history, 'appointments');
        } else {
          setAlert(responseStatusPayment.message, responseStatusPayment.status);
          clearResponsePayment();
        }
      }
    }
  }, [responseStatusPayment]);

  const [addAddress, setAddAddress] = useState(false);

  const toggleModal = () => {
    setAddAddress(!addAddress);
  };

  const validation = Yup.object({
    bill_first_name: Yup.string()
      .min(2, 'Mininum 2 characters')
      .max(15, 'Maximum 15 characters')
      .required('Required!'),
    bill_last_name: Yup.string()
      .min(2, 'Mininum 2 characters')
      .max(15, 'Maximum 15 characters')
      .required('Required!'),
    bill_address1: Yup.string().required('Required!'),
    bill_city: Yup.string().required('Required!'),
    bill_state: Yup.string().required('Required!'),
    bill_zipcode: Yup.string()
      .min(4, 'Invalid Zip Code')
      .max(8, 'Invalid Zip Code')
      .required('Required!'),
  });

  const formik = useFormik({
    initialValues: {
      bill_first_name: user.first_name,
      bill_last_name: user.last_name,
      bill_address1: user.address1,
      bill_city: user.city,
      bill_state: user.state,
      bill_zipcode: user.zip,
    },
    validateOnBlur: false,
    validationSchema: validation,
    onSubmit: (values) => {
      //saveAddress(values)
      toggleModal();
      setBillingAddress({
        bill_first_name: values.bill_first_name,
        bill_last_name: values.bill_last_name,
        bill_address1: values.bill_address1,
        bill_city: values.bill_city,
        bill_state: values.bill_state,
        bill_zipcode: values.bill_zip,
      });
    },
  });
  // initialize billing address
  useEffect(() => {
    if (user) {
      formik.setFieldValue('bill_first_name', user.first_name);
      formik.setFieldValue('bill_last_name', user.last_name);
      formik.setFieldValue('bill_address1', user.address1);
      formik.setFieldValue('bill_city', user.city);
      formik.setFieldValue('bill_state', user.state);
      formik.setFieldValue('bill_zipcode', user.zip);
      setBillingAddress({
        bill_first_name: user.first_name,
        bill_last_name: user.last_name,
        bill_address1: user.address1,
        bill_city: user.city,
        bill_state: user.state,
        bill_zipcode: user.zip,
      });
      if (user.isTaxExempt) {
        setTaxExemptBtn(true);
      }
      if (user.storeCreditBalance > 0) {
        setStoreCredit({ ...storeCredit, amount: user.storeCreditBalance });
      }
    }
  }, [user]);
  const billingAddressInfo = [
    {
      label: 'First name',
      type: 'text',
      placeholder: 'Enter your first name',
      class: 'col-12',
      name: 'bill_first_name',
      formik: formik,
    },
    {
      label: 'Last name',
      placeholder: 'Enter your last name',
      class: 'col-12',
      type: 'text',
      name: 'bill_last_name',
      formik: formik,
    },
    {
      label: 'Address',
      placeholder: 'Enter you address',
      class: 'col-12',
      type: 'text',
      name: 'bill_address1',
      formik: formik,
    },
    {
      label: 'City',
      placeholder: 'Enter your city',
      class: 'col-12',
      type: 'text',
      name: 'bill_city',
      formik: formik,
    },
    {
      label: 'State',
      placeholder: 'Enter your state',
      class: 'col-12',
      type: 'select',
      options: USStates,
      name: 'bill_state',
      formik: formik,
    },
    {
      label: 'ZIP',
      placeholder: 'Enter your ZIP code',
      class: 'col-6',
      type: 'number',
      name: 'bill_zipcode',
      formik: formik,
    },
  ];

  const validationPayment = Yup.object({
    appointmentID: Yup.string(),
    pickupDay: Yup.string().when(['appointmentID'], {
      is: (a) => !a,
      then: Yup.string().required('Required!'),
    }),
    pickupTime: Yup.string().when(['appointmentID'], {
      is: (a) => !a,
      then: Yup.string().required('Required!'),
    }),
    payment_method: Yup.string().required('Required!'),
    no_of_cards: Yup.string().when(['payment_method'], {
      is: (a) => a === 'card',
      then: Yup.string().required('Required!'),
    }),
    card_number: Yup.string()
      .matches(
        /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
        'Invalid credit card number!'
      )
      .when(['payment_method'], {
        is: (a) => a === 'card',
        then: Yup.string().required('Required!'),
      }),
    card_expire_month: Yup.string().when(['payment_method'], {
      is: (a) => a === 'card',
      then: Yup.string().required('Required!'),
    }),
    card_expire_year: Yup.string().when(['payment_method'], {
      is: (a) => a === 'card',
      then: Yup.string().required('Required!'),
    }),
    card_cvv: Yup.string()
      .min(3, 'Invalid CVV')
      .max(4, 'Invalid CVV')
      .when(['payment_method'], {
        is: (a) => a === 'card',
        then: Yup.string().required('Required!'),
      }),
    card_amount: Yup.string().required('Required!'),
    second_card_number: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string()
        .matches(
          /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
          'Invalid credit card number!'
        )
        .required('Required!'),
    }),
    second_card_expire_month: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_card_expire_year: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_card_cvv: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().min(3, 'Invalid CVV').max(4, 'Invalid CVV').required('Required!'),
    }),
    second_card_amount: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_first_name: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_last_name: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_address1: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_city: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_state: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string().required('Required!'),
    }),
    second_bill_zipcode: Yup.string().when('no_of_cards', {
      is: (NoCard) => NoCard !== 'single',
      then: Yup.string()
        .min(4, 'Invalid Zip Code')
        .max(8, 'Invalid Zip Code')
        .required('Required!'),
    }),
  });

  const formikPayment = useFormik({
    initialValues: {
      appointmentID: '',
      appointmentTime: '',
      pickupDay: '',
      pickupTime: '',
      pickup: '',
      pickupSomeoneElse: false,
      authorizedPickup: '',
      payment_method: 'card',
      no_of_cards: 'single',
      card_number: '',
      card_expiry: '',
      card_expire_month: '',
      card_expire_year: '',
      card_cvv: '',
      card_amount: cartValues.total_amount,
      second_card_number: '',
      second_card_expiry: '',
      second_card_expire_month: '',
      second_card_expire_year: '',
      second_card_cvv: '',
      second_card_amount: '',
      second_bill_first_name: '',
      second_bill_last_name: '',
      second_bill_address1: '',
      second_bill_city: '',
      second_bill_state: '',
      second_bill_zipcode: '',
      kount_session_id: window.kount_session_id.MercSessId,
    },
    validateOnBlur: false,
    validationSchema: validationPayment,
    onSubmit: (values) => {
      formikPayment.setSubmitting(true);
      if (values.pickupDay && values.pickupTime) {
        let pickupChanged = values.pickupDay + ' ' + values.pickupTime;
        values.pickup = moment(pickupChanged, 'MMM Do YYYY HH:mm').format();
      }

      if (values.payment_method == 'card') {
        values.card_expiry = values.card_expire_month + '-' + values.card_expire_year;
        if (values.no_of_cards != 'single') {
          values.second_card_expiry =
            values.second_card_expire_month + '-' + values.second_card_expire_year;
        }
      }

      values.items = checkoutData.items;

      // attach buynow id
      values.buynowId = checkoutData.items.map((item) => {
        return item.buynowId;
      });

      // attach billing address
      values.bill_first_name = formik.values.bill_first_name;
      values.bill_last_name = formik.values.bill_last_name;
      values.bill_address1 = formik.values.bill_address1;
      values.bill_city = formik.values.bill_city;
      values.bill_state = formik.values.bill_state;
      values.bill_zipcode = formik.values.bill_zipcode;

      checkoutData.total = parseFloat(checkoutData.total).toFixed(2);
      checkoutData.totalBeforeTax = parseFloat(checkoutData.totalBeforeTax).toFixed(2);
      checkoutData.totalTax = parseFloat(checkoutData.totalTax).toFixed(2);
      values.checkoutData = checkoutData;

      values.storeCreditAmt = null;
      if (storeCredit.applied) {
        values.storeCreditAmt = storeCredit.appliedAmt;
      }

      checkoutCart(values);
    },
  });

  const firstCard = () => {
    if (formikPayment.values.no_of_cards === 'single') {
      let firstAmount = checkoutData.total - formikPayment.values.second_card_amount;
      formikPayment.values.card_amount = checkoutData.total;
      formikPayment.values.second_card_amount = '';
      return parseFloat(Number(firstAmount).toFixed(2));
    } else if (formikPayment.values.second_card_amount > 0) {
      let firstAmount = checkoutData.total - formikPayment.values.second_card_amount;
      if (firstAmount > 0) {
        firstAmount = formikPayment.values.second_card_amount
          ? checkoutData.total - formikPayment.values.second_card_amount
          : checkoutData.total;
        formikPayment.values.card_amount = parseFloat(Number(firstAmount).toFixed(2));
      } else {
        formikPayment.values.card_amount = checkoutData.total;
        formikPayment.values.second_card_amount = '';
      }
      return parseFloat(Number(firstAmount).toFixed(2));
    }
  };

  const creditCardMonthOptions = [
    {
      value: '',
      show: 'MM',
    },
    {
      value: '01',
      show: '01',
    },
    {
      value: '02',
      show: '02',
    },
    {
      value: '03',
      show: '03',
    },
    {
      value: '04',
      show: '04',
    },
    {
      value: '05',
      show: '05',
    },
    {
      value: '06',
      show: '06',
    },
    {
      value: '07',
      show: '07',
    },
    {
      value: '08',
      show: '08',
    },
    {
      value: '09',
      show: '09',
    },
    {
      value: '10',
      show: '10',
    },
    {
      value: '11',
      show: '11',
    },
    {
      value: '12',
      show: '12',
    },
  ];

  const creditCardYearOptions = [
    {
      value: '',
      show: 'YYYY ',
    },
  ];
  let date = new Date();
  let currentYear = date.getFullYear();
  let upToYear = parseInt(currentYear) + 25;
  for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
    creditCardYearOptions.push({
      value: year,
      show: year,
    });
  }

  const firstCardFirstPart = [
    {
      label: 'Card number',
      type: 'number',
      placeholder: 'Enter your card number',
      class: 'col-md-8 col-sm-12',
      name: 'card_number',
      formik: formikPayment,
    },
    {
      label: 'Month',
      placeholder: 'MM',
      class: 'col-md-2 col-sm-6',
      type: 'select',
      name: 'card_expire_month',
      options: creditCardMonthOptions,
      formik: formikPayment,
    },
    {
      label: 'Year',
      placeholder: 'YYYY',
      class: 'col-md-2 col-sm-6',
      type: 'select',
      name: 'card_expire_year',
      options: creditCardYearOptions,
      formik: formikPayment,
    },
  ];

  const firstCardSecondPart = [
    {
      label: 'CVV',
      type: 'number',
      placeholder: 'Enter CVV',
      class: 'col-md-3 col-sm-6',
      name: 'card_cvv',
      formik: formikPayment,
    },
    {
      label: 'Amount',
      placeholder: 'Enter Amount',
      value: firstCard(),
      disabled: true,
      class: 'col-md-3 col-sm-6',
      type: 'number',
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
      name: 'card_amount',
      formik: formikPayment,
    },
  ];

  const secondCardFirstPart = [
    {
      label: 'Card number',
      type: 'number',
      placeholder: 'Enter your card number',
      class: 'col-md-8 col-sm-12',
      name: 'second_card_number',
      formik: formikPayment,
    },
    {
      label: 'Month',
      placeholder: 'MM',
      class: 'col-md-2 col-sm-6',
      type: 'select',
      name: 'second_card_expire_month',
      options: creditCardMonthOptions,
      formik: formikPayment,
    },
    {
      label: 'Year',
      placeholder: 'YYYY',
      class: 'col-md-2 col-sm-6',
      type: 'select',
      name: 'second_card_expire_year',
      options: creditCardYearOptions,
      formik: formikPayment,
    },
  ];

  const secondCardSecondPart = [
    {
      label: 'CVV',
      type: 'number',
      placeholder: 'Enter CVV',
      class: 'col-md-3 col-sm-6',
      name: 'second_card_cvv',
      formik: formikPayment,
    },
    {
      label: 'Amount',
      placeholder: 'Enter Amount',
      class: 'col-md-3 col-sm-6',
      type: 'number',
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
      name: 'second_card_amount',
      formik: formikPayment,
    },
  ];

  const secondBillingAddress = [
    {
      label: 'First name',
      type: 'text',
      placeholder: 'Enter your first name',
      class: 'col-6',
      name: 'second_bill_first_name',
      formik: formikPayment,
    },
    {
      label: 'Last name',
      placeholder: 'Enter your last name',
      class: 'col-6',
      type: 'text',
      name: 'second_bill_last_name',
      formik: formikPayment,
    },
    {
      label: 'Address',
      placeholder: 'Enter you address',
      class: 'col-8',
      type: 'text',
      name: 'second_bill_address1',
      formik: formikPayment,
    },
    {
      label: 'City',
      placeholder: 'Enter your city',
      class: 'col-4',
      type: 'text',
      name: 'second_bill_city',
      formik: formikPayment,
    },
    {
      label: 'State',
      placeholder: 'Enter your state',
      class: 'col-6',
      type: 'select',
      options: USStates,
      name: 'second_bill_state',
      formik: formikPayment,
    },
    {
      label: 'ZIP',
      placeholder: 'Enter your ZIP code',
      class: 'col-6',
      type: 'number',
      name: 'second_bill_zipcode',
      formik: formikPayment,
    },
  ];

  function intervals(startString, endString) {
    var start = moment(startString, 'YYYY-MM-DD HH:mm');
    var end = moment(endString, 'YYYY-MM-DD HH:mm');
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    var result = [];
    var current = moment(start);
    var timezoneAbbr = moment.tz(cartLocation.timezone).format('z');
    // initialize pickup time
    formikPayment.values.pickupTime = moment(current).format('HH:mm');
    while (current < end) {
      result.push({
        value: moment(current).format('HH:mm'),
        show: `${current.format('h:mm a')} ${timezoneAbbr}`,
      });
      current.add(15, 'minutes');
    }
    return result;
  }

  useEffect(() => {
    const selectedDate = moment(formikPayment.values.pickupDay, 'MMM Do YYYY').format();
    let weekDate = parseInt(moment(selectedDate).format('d'));
    let todayWeekDate = parseInt(moment().format('d'));
    let selectedWeekDay = dataArray.filter((x) => x.date === weekDate);
    console.log('selected weekday: ', selectedWeekDay);
    if (weekDate) {
      if (selectedWeekDay.length > 0) {
        if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
          let dateSelected = moment(formikPayment.values.pickupDay, 'MMM Do YYYY').format(
            'YYYY-MM-DD'
          );
          let timeMinimum = moment(selectedWeekDay[0].minTime, 'HH').format('HH:mm');
          if (todayWeekDate === weekDate) {
            timeMinimum = moment().tz(cartLocation.timezone).format('HH:mm');
          }
          let timeMaximum = moment(selectedWeekDay[0].maxTime, 'HH').format('HH:mm');
          timeMinimum = moment(dateSelected + ' ' + timeMinimum).format();
          timeMaximum = moment(dateSelected + ' ' + timeMaximum).format();
          const changeData = intervals(timeMinimum, timeMaximum);
          setAllAvailableTime(changeData);
        } else {
          setAllAvailableTime([{ value: '', show: `No Timeslots Available` }]);
        }
      } else {
        setAllAvailableTime([{ value: '', show: `No Timeslots Available` }]);
      }
    } else {
      setAllAvailableTime([{ value: '', show: `Select Date` }]);
    }
  }, [formikPayment.values.pickupDay]);

  const confirmAppointment = (data) => {
    formikPayment.setFieldValue('appointmentID', data.id);
    formikPayment.setFieldValue('appointmentTime', data.appointmentTime);
  };
  const removeAppointment = () => {
    formikPayment.setFieldValue('appointmentID', '');
    formikPayment.setFieldValue('appointmentTime', '');
  };

  const applyTaxExemption = () => {
    let checkoutDataCopy = { ...checkoutData };
    checkoutDataCopy.total -= checkoutDataCopy.totalTax;
    if (checkoutDataCopy.total < 0) {
      checkoutDataCopy.total = 0;
    }
    checkoutDataCopy.taxPercent = 0;
    checkoutDataCopy.totalTax = 0;
    setCheckoutData(checkoutDataCopy);
    setTaxExemptBtn(false);
  };

  const applyStoreCredit = (e) => {
    e.preventDefault();
    let checkoutDataCopy = { ...checkoutData };
    if (checkoutDataCopy.total > storeCredit.amount) {
      checkoutDataCopy.total -= storeCredit.amount;
      setCheckoutData(checkoutDataCopy);
      setStoreCredit({ ...storeCredit, applied: true, appliedAmt: storeCredit.amount });
    } else {
      setStoreCredit({
        ...storeCredit,
        applied: true,
        appliedAmt: checkoutDataCopy.total,
      });
      checkoutDataCopy.total = 0;
      setCheckoutData(checkoutDataCopy);
      // if store credit covers the entire total, change payment method type to storeCredit
      formikPayment.setFieldValue('payment_method', 'storeCredit');
    }
  };

  const cancelStoreCredit = (e) => {
    e.preventDefault();
    let checkoutDataCopy = { ...checkoutData };
    if (checkoutDataCopy.total == 0) {
      formikPayment.values.payment_method = 'card';
    }
    checkoutDataCopy.total += parseInt(storeCredit.appliedAmt);
    setCheckoutData(checkoutDataCopy);
    setStoreCredit({ ...storeCredit, applied: false });
  };

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setDrawer({ ...drawer, [anchor]: open, data: data });
    } else {
      setDrawer({ ...drawer, [anchor]: open, data: {} });
    }
  };

  return (
    <div className="checkout customContainer">
      {availAppoints.length > 0 && !formikPayment.values.appointmentID && (
        <>
          {availAppoints.map((data, index) => (
            <>
              <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                <span className="material-icons">restore</span>
                <div>
                  <h2>
                    You have an existing appointment at{' '}
                    {dateTimeFormatFunction(data.appointmentTime)}.
                  </h2>
                  <h4>You can add items to the same appointment</h4>
                </div>
                <PrimaryButton
                  label="Continue"
                  onClick={() => confirmAppointment(data)}
                  btnSize="small"
                />
              </div>
            </>
          ))}
        </>
      )}

      <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
        <Link to="/cart">Cart</Link>
        <Typography color="textPrimary">Checkout</Typography>
      </Breadcrumbs>
      <form onSubmit={formikPayment.handleSubmit} autocomplete="nofill">
        <div className="checkoutCnt d-flex justify-content-between">
          <div className="checkoutLt">
            {formikPayment.values.appointmentID ? (
              <>
                <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                  <span className="material-icons">restore</span>
                  <div>
                    <h2>
                      You have an selected your previous appointment scheduled at{' '}
                      {dateTimeFormatFunction(formikPayment.values.appointmentTime)}.
                    </h2>
                  </div>
                  <PrimaryButton
                    label="Remove"
                    onClick={() => removeAppointment()}
                    btnSize="small"
                  />
                </div>
              </>
            ) : (
              <div className="schedule">
                <h1 className="checkoutHead">
                  Schedule Appointment
                  {'  '}
                  {Object.keys(checkoutData).length > 0
                    ? '(' + checkoutData.locationName + ')'
                    : ''}
                </h1>

                <div className="chCnt d-flex justify-content-start align-items-center">
                  {appointmentSchedule.map((data, index) => (
                    <div className="selectDate d-flex justify-content-center align-items-center">
                      <input
                        name="pickupDay"
                        value={data.date}
                        onChange={formikPayment.handleChange}
                        type="radio"
                        id={`schedule_${index}`}
                        hidden
                      ></input>
                      <label for={`schedule_${index}`}>
                        <ListItem button>
                          <p>{data.date}</p>
                          <span>({data.label})</span>
                        </ListItem>
                      </label>
                    </div>
                  ))}
                </div>
                <p className="scheduleError">
                  {formikPayment.errors.pickupDay &&
                    formikPayment.touched.pickupDay &&
                    formikPayment.errors.pickupDay}
                </p>
                <div className="selectTime d-flex justify-content-start align-items-center">
                  <h1 className="checkoutHead subHead">Select a time</h1>
                  <CustomSelect
                    label="Select a time"
                    value={formikPayment.values.pickupTime}
                    size="small"
                    name="pickupTime"
                    shrink={allAvailableTime.length && true}
                    onChange={formikPayment.handleChange}
                    error={formikPayment.touched.pickupTime && formikPayment.errors.pickupTime}
                    helperText={
                      formikPayment.errors.pickupTime &&
                      formikPayment.touched.pickupTime &&
                      formikPayment.errors.pickupTime
                    }
                  >
                    {allAvailableTime.map((opt, optindex) => (
                      <option value={opt.value}>{opt.show}</option>
                    ))}
                  </CustomSelect>
                </div>
                <div className="selectTime d-flex justify-content-start align-items-center">
                  <div>
                    <h1 className="checkoutHead subHead authorizedPickupLabel">
                      Authorize someone else to pick up?
                    </h1>
                  </div>
                  <div>
                    <CheckBox
                      name="pickupSomeoneElse"
                      value={formikPayment.values.pickupSomeoneElse}
                      checked={formikPayment.values.pickupSomeoneElse}
                      onChange={formikPayment.handleChange}
                    />
                  </div>
                  {formikPayment.values.pickupSomeoneElse ? (
                    <div>
                      <CustomInput
                        name="authorizedPickup"
                        inputStyle={{ marginBottom: '0px' }}
                        value={formikPayment.authorizedPickup}
                        onChange={formikPayment.handleChange}
                        error={
                          formikPayment.touched.authorizedPickup &&
                          formikPayment.errors.authorizedPickup
                        }
                        helperText={
                          formikPayment.errors.authorizedPickup &&
                          formikPayment.touched.authorizedPickup &&
                          formikPayment.errors.authorizedPickup
                        }
                        placeholder="Enter full name"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            <div className="billingInfo">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h1 className="checkoutHead m-0">Billing Information</h1>
                <Button variant="outlined" onClick={setAddAddress}>
                  Change
                </Button>
              </div>
              <div className="billingAddress">
                <h4>
                  {billingAddress.bill_first_name && billingAddress.bill_first_name}{' '}
                  {billingAddress.bill_last_name && billingAddress.bill_last_name}
                </h4>
                <p>
                  {billingAddress.bill_address1 && billingAddress.bill_address1}{' '}
                  {billingAddress.bill_address && billingAddress.bill_address}
                </p>
                <p>
                  {billingAddress.bill_city && billingAddress.bill_city}{' '}
                  {billingAddress.bill_state && billingAddress.bill_state}
                </p>
                <p> {billingAddress.bill_zipcode && billingAddress.bill_zipcode}</p>
              </div>
            </div>
            {formikPayment.values.payment_method === 'storeCredit' ? null : (
              <div className="payment">
                <h1 className="checkoutHead">Payment Method</h1>

                <div className="paymentMethod d-flex justify-content-start align-items-center">
                  <h1 className="checkoutHead subHead">Select Payment method</h1>
                  <CustomSelect
                    label="Select Payment method"
                    value={formikPayment.values.payment_method}
                    size="small"
                    name="payment_method"
                    value={formikPayment.values.payment_method}
                    onChange={formikPayment.handleChange}
                    error={
                      formikPayment.touched.payment_method && formikPayment.errors.payment_method
                    }
                    helperText={
                      formikPayment.errors.payment_method &&
                      formikPayment.touched.payment_method &&
                      formikPayment.errors.payment_method
                    }
                  >
                    {allPayments.map((opt, optindex) => (
                      <option value={opt.value}>{opt.show}</option>
                    ))}
                  </CustomSelect>
                </div>
                {formikPayment.values.payment_method === 'card' ? (
                  <>
                    <div className="isMultiCardSelection">
                      <h1 className="checkoutHead subHead">Choose</h1>
                      <RadioBox
                        items={allCards}
                        value={formikPayment.values.no_of_cards}
                        onChange={formikPayment.handleChange}
                        name="no_of_cards"
                      />
                    </div>

                    <div className="cardDetails">
                      {formikPayment.values.no_of_cards !== 'single' && (
                        <h4>Enter details of your first card</h4>
                      )}
                      <div className="row">{Object.values(mapData(firstCardFirstPart))}</div>

                      <div className="row noMarginBottom">
                        {Object.values(mapData(firstCardSecondPart))}
                      </div>
                    </div>
                    {formikPayment.values.no_of_cards != 'single' ? (
                      <>
                        <div className="cardDetails">
                          <h4>Enter details of your second card</h4>
                          <div className="row">{Object.values(mapData(secondCardFirstPart))}</div>

                          <div className="row noMarginBottom">
                            {Object.values(mapData(secondCardSecondPart))}
                          </div>
                          <h4>Second Billing Address</h4>
                          <div className="row">{Object.values(mapData(secondBillingAddress))}</div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            )}

            <div className="review">
              {Object.keys(checkoutData).length > 0 ? (
                <>
                  <div className="d-flex align-items-center mb-3">
                    <h1 className="checkoutHead m-0">Review Your Order</h1>
                    <span>{checkoutData.items.length} Item(s)</span>
                  </div>

                  <div className="cartProducts">
                    <div>
                      {checkoutData.items.map((data, index) => (
                        <React.Fragment key={index}>
                          {/* Product View Drawer */}
                          <Drawer
                            className="rightDrawer"
                            anchor={'right'}
                            open={drawer['right']}
                            onClose={toggleDrawer('right', false)}
                          >
                            <ProductView
                              data={drawer.data}
                              drawerHandler={toggleDrawer('right', false)}
                            />
                          </Drawer>
                          <CartItem
                            data={data}
                            from="checkout"
                            // changeOnCart={changeOnCart}
                            cancelItem={() => {}}
                            toggleDrawer={toggleDrawer('right', true, data)}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {/* This CTA will be hidden after 1024px */}
                  <div className="checkoutSecAction d-flex justify-content-between align-items-center">
                    <PrimaryButton type="submit" label={'Complete Checkout'} />
                    <div>
                      <label>Order Total:</label>
                      <h6>{currencyFormat(checkoutData.total)}</h6>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {Object.keys(checkoutData).length > 0 ? (
            <div className="checkoutRt">
              <div className="orderSummary">
                <PrimaryButton type="submit" btnSize="small" label={'Complete Checkout'} />
                <div>
                  <h3>Order Summary</h3>
                  <p>
                    <label>Items:</label>
                    {checkoutData.items.length}
                  </p>
                  <p>
                    <label>Total before taxes:</label>
                    {currencyFormat(checkoutData.totalBeforeTax)}
                  </p>
                  <p>
                    <label>Buyer Premium: </label>
                    {currencyFormat(checkoutData.totalBuyerPremium)} ({checkoutData.premiumPercent}{' '}
                    %)
                  </p>
                  <p>
                    <label>Estimated taxes: </label>
                    <div>
                      {taxExemptBtn ? (
                        <button className="taxExemptBtn" onClick={applyTaxExemption}>
                          Use Tax Exemption
                        </button>
                      ) : null}
                      {currencyFormat(checkoutData.totalTax)} ({checkoutData.taxPercent} %)
                    </div>
                  </p>
                  {storeCredit.amount > 0 ? (
                    <p>
                      {storeCredit.applied ? (
                        <>
                          <label> Applied Store Credit: </label>
                          <div>
                            <button className="taxExemptBtn" onClick={cancelStoreCredit}>
                              Cancel
                            </button>
                            -{currencyFormat(storeCredit.appliedAmt)}
                          </div>
                        </>
                      ) : (
                        <>
                          <label>My Store Credit Balance: </label>
                          <div>
                            <button className="taxExemptBtn" onClick={applyStoreCredit}>
                              Apply
                            </button>
                            {currencyFormat(storeCredit.amount)}
                          </div>
                        </>
                      )}
                    </p>
                  ) : null}

                  <p>
                    <label>Total:</label>
                    {currencyFormat(checkoutData.total)}
                  </p>
                  <hr />
                  <h4>
                    <label>Order Total:</label>
                    {currencyFormat(checkoutData.total)}
                  </h4>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </form>
      <div className="PaymentModal">
        <Popup
          open={addAddress}
          size="md"
          handleClose={() => toggleModal()}
          modaltitle="Change billing address"
        >
          <form onSubmit={formik.handleSubmit} autocomplete="nofill">
            {Object.values(mapData(billingAddressInfo))}
            <PrimaryButton btnSize="large" type="submit" label={'Save'} />
          </form>
        </Popup>
      </div>
    </div>
  );
};

export default Checkout;
