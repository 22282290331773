import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './ProductCard.css';
import FavouriteCheckbox from '../../../../custom/components/atoms/FavoriteCheckbox';
import PrimaryButton from '../../../../custom/components/atoms/PrimaryButton';
import Timer from '../../../../custom/common/timer';
import Bidding from '../../molecules/Bidding/BiddingItem';
import AuthContext from '../../../../custom/context/auth/authContext';
import {
  dateFormatFront,
  handleRedirectInternal,
  currencyFormat,
} from '../../../common/components';
import CommonContext from '../../../context/common/commonContext';
import { Highlight } from 'react-instantsearch-dom';
import { getStarRatingIcons } from '../../../../utils/UI/starRating';

const Grid = (props) => {
  const product = props.data;
  const renderHighLight = props.renderHighlight;
  const commonContext = useContext(CommonContext);
  const authContext = useContext(AuthContext);

  const { setBidHistoryValue } = commonContext;
  const { isAuthenticated } = authContext;

  const history = useHistory();

  return (
    <div className="productCardGrid">
      {product ? (
        <>
          <div className="pcgImg">
            {product.market_status === 'open' ? (
              <FavouriteCheckbox watchlisted={product.watchlistid} project_id={product.id} />
            ) : null}

            <img
              className="curserPointer"
              src={product.avatarorg}
              onClick={props.drawerHandler}
              alt={product.leadDescription}
            />
            {isAuthenticated && (
              <>
                {product.bidtopstatus === 'outbid' && <h4 className="outBid">OUTBID</h4>}
                {product.bidtopstatus === 'winner' && <h4 className="winning">WINNING</h4>}
                {product.bidtopstatus === 'won' && <h4 className="winning">WON</h4>}
                {product.bidtopstatus === 'lost' && <h4 className="outBid">LOST</h4>}
              </>
            )}
          </div>
          <h2 className="gridProdTitle" onClick={props.drawerHandler}>
            {renderHighLight ? (
              <Highlight attribute={'Lead Description'} hit={product} tagName={'strong'} />
            ) : (
              product.leadDescription
            )}
          </h2>
          {/* <div className="gridLotInfo d-flex justify-content-start align-items-center">
                        <h5>Lot # {product.item_id}</h5> <span className="gridDivider">|</span>{' '}
                        <h5>{product.allattachmentlist.length} Photos</h5>
                    </div> */}
          {!props.returnItems && (
            <div className="d-flex justify-content-start align-items-center">
              {product.starRating >= 0 ? <p>{getStarRatingIcons(product.starRating)}</p> : <p> </p>}
            </div>
          )}
          <div className="gridLotDetails d-flex justify-content-start align-items-center">
            {product.nellisnotes ? <p>NOTES: {product.nellisnotes}</p> : <p> </p>}
          </div>

          {props.returnItems ? (
            <div className="returnItemAct">
              <div className="gridBidInfo d-flex justify-content-between align-items-center">
                <h6>Amount: </h6>
                <p>
                  <span>{currencyFormat(product.wprice)}</span>
                </p>
              </div>
              {props.returnItems}
            </div>
          ) : product.market_status === 'open' ? (
            <>
              {
                <>
                  <div className="gridTimer d-flex justify-content-between align-items-center">
                    <h6>Time Left:</h6>
                    <p className="d-flex align-items-center">
                      <span className="material-icons">timer</span>
                      {product.date_closed ? (
                        <Timer
                          date_added={product.date_added}
                          date_closed={product.date_closed}
                          withText={0}
                        />
                      ) : null}
                    </p>
                  </div>
                  <div className="gridBidInfo d-flex justify-content-between align-items-center">
                    <h6>{product.cbidtext}: </h6>
                    <p className="d-flex justify-content-between align-items-center">
                      <span>{currencyFormat(product.wprice)}</span>
                      <span className="gridDivider">|</span>
                      <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                        {product.bid_count} Bids
                      </span>
                    </p>
                  </div>
                  <div className="gridBidBox">
                    <Bidding data={product} />
                  </div>
                </>
              }
            </>
          ) : product.market_status === 'closed' ? (
            <>
              <div className="gridTimer d-flex justify-content-between align-items-center">
                <h6>Closed on:</h6>
                <p className="d-flex align-items-center">{dateFormatFront(product.date_closed)}</p>
              </div>
              <div className="gridBidInfo d-flex justify-content-between align-items-center">
                <p className="d-flex justify-content-between align-items-center">
                  <span>Reserve Price Not Met</span>
                  <span className="gridDivider">|</span>
                  <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                    {product.bid_count} Bids
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="gridTimer d-flex justify-content-between align-items-center">
                <h6>Closed on:</h6>
                <p className="d-flex align-items-center">{dateFormatFront(product.date_closed)}</p>
              </div>
              <div className="gridBidInfo d-flex justify-content-between align-items-center">
                <h6>{product.cbidtext}: </h6>
                <p className="d-flex justify-content-between align-items-center">
                  <span>{currencyFormat(product.buynowamount)}</span>
                  <span className="gridDivider">|</span>
                  <span className="cursorDecoy" onClick={() => setBidHistoryValue(product.id)}>
                    {product.bid_count} Bids
                  </span>
                </p>
              </div>
              {product.bidtopstatus === 'won' && (
                <div className="actionAfterWin">
                  {product.appointmentId === null &&
                  product.buynow_status === 'active' &&
                  product.market_status !== 'relisted' ? (
                    <>
                      <PrimaryButton
                        label="Make payment"
                        btnSize="small"
                        onClick={() => handleRedirectInternal(history, 'cart')}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </>
          )}
          {product.bidtopstatus === 'won' ? (
            <div className="listLotDetails statusMessage">
              {product.appointmentId === null ? (
                product.buynow_status === 'canceled' ? (
                  <>
                    <span class="material-icons">cancel</span>
                    <h6> Returned / Canceled</h6>
                  </>
                ) : product.market_status === 'relisted' ? (
                  <>
                    <span class="material-icons">event_busy</span>
                    <h6> Item Expired</h6>
                  </>
                ) : (
                  <>
                    <span class="material-icons">announcement</span>
                    <h6> Needs Appointment</h6>
                  </>
                )
              ) : product.appointmentStatusTypeId == '2' ||
                product.appointmentStatusTypeId == '3' ? (
                <>
                  <span class="material-icons">priority_high</span>
                  <h6> Ready for Pickup</h6>
                </>
              ) : product.appointmentStatusTypeId == '4' ? (
                <>
                  <span class="material-icons">check_circle</span>
                  <h6 style={{ display: 'inlineBlock' }}> Picked Up</h6>
                </>
              ) : product.appointmentStatusTypeId == '5' ? (
                <>
                  <span class="material-icons">event_busy</span>
                  <h6> Canceled</h6>
                </>
              ) : (
                <>
                  <span class="material-icons">schedule</span>
                  <h6> Appointment Scheduled</h6>
                </>
              )}
            </div>
          ) : null}
          {props.returnItems ? null : (
            <div className="moreInfo text-center mt-2">
              <Button onClick={props.drawerHandler}>More Info</Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Grid;
