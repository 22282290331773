import { useContext } from 'react';
import AlertContext from './../context/alert/alertContext';
import { useSnackbar } from 'notistack';

const handleClick = (enqueueSnackbar, clearAlert, alertContent) => {
  enqueueSnackbar(alertContent.msg, {
    variant: alertContent.type,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  });
  clearAlert(alertContent.id);
};

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  const { alerts, clearAlert } = alertContext;
  const { enqueueSnackbar } = useSnackbar();

  return (
    alerts.length > 0 &&
    alerts
      .filter(({ msg }) => !!msg)
      .map((alert) => handleClick(enqueueSnackbar, clearAlert, alert))
  );
};

export default Alerts;
