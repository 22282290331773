import React, { useReducer } from 'react';
import RefundContext from './refundContext';
import RefundReducer from './refundReducer';
import { apiCall } from '../../common/api';
import { response } from '../common';
import { RESPONSE_STATUS, CLEAR_RESPONSE } from './refundTypes';

const RefundState = (props) => {
  const initialState = {
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(RefundReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const payCart = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'authorize', formData, '', 'refund')]);
      resp.commonResponse(res.data, 'payment');
    } catch (err) {
      resp.commonErrorResponse('payment');
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <RefundContext.Provider
      value={{
        responseStatus: state.responseStatus,
        payCart,
        clearResponse,
      }}
    >
      {props.children}
    </RefundContext.Provider>
  );
};

export default RefundState;
