import React, { useContext, useState, useEffect } from 'react';
import CustomInput from '../../product/components/atoms/Inputs/CustomInput';
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import './Dashboard.css';
import DashboardLayout from './DashboardLayout';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { mapData } from '../../product/common/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../custom/context/auth/authContext';
import UserContext from '../../custom/context/user/userContext';
import CommonContext from '../../custom/context/common/commonContext';
import PhoneValidation from '../Registration/phoneValidation';

const MyAccount = () => {
  const authContext = useContext(AuthContext);

  const userContext = useContext(UserContext);
  const commonContext = useContext(CommonContext);
  const { USStates, allNotifications } = commonContext;

  const { user, loadUser } = authContext;

  const { updateProfile, updatePreference, responseStatus: responseStatusUser } = userContext;

  const [phoneVerify, setPhoneVerify] = React.useState(false);

  const changePhoneVerify = () => {
    setPhoneVerify(!phoneVerify);
  };

  const validationArray = Yup.object({
    first_name: Yup.string()
      .min(2, 'Mininum 2 characters')
      .max(15, 'Maximum 15 characters')
      .required('Required!'),
    last_name: Yup.string()
      .min(2, 'Mininum 2 characters')
      .max(15, 'Maximum 15 characters')
      .required('Required!'),
    address1: Yup.string().required('Required!'),
    phone: Yup.string()
      .test('isValid', 'Invalid Phone Number', (value) => {
        return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
      })
      .required('Required!'),
    city: Yup.string().required('Required!'),
    state: Yup.string().required('Required!'),
    zip: Yup.string().min(4, 'Invalid Zip Code').max(8, 'Invalid Zip Code').required('Required!'),
  });

  const validationPassword = Yup.object({
    change_password: Yup.boolean(),
    current_password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
    new_password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], "Password's not match")
      .required('Required!'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      phone: '',
      email: '',
      city: '',
      state: '',
      zip: '',
      phone_verified: 0,
      verified_phonenum: '',
      phone_verifysent: 0,
      verify_code: '',
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      if (
        user.phone === formik.values.phone ||
        (values.phone_verified === 1 && formik.values.verified_phonenum === formik.values.phone)
      ) {
        delete values.email;
        values.phone = values.phone.replace(/[^+0-9]/g, '');
        updateProfile(values);
      } else {
        setPhoneVerify(true);
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue('first_name', user.first_name);
      formik.setFieldValue('last_name', user.last_name);
      formik.setFieldValue('address1', user.address1);
      formik.setFieldValue('address2', user.address2);
      formik.setFieldValue('phone', user.phone);
      formik.setFieldValue('email', user.email);
      formik.setFieldValue('city', user.city);
      formik.setFieldValue('state', user.state);
      formik.setFieldValue('zip', user.zip);

      formikPreference.values.email_prefer =
        user.email_notification && user.email_notification.split(',').length
          ? user.email_notification.split(',')
          : [];
      formikPreference.values.sms_prefer =
        user.sms_notification && user.sms_notification.split(',').length
          ? user.sms_notification.split(',')
          : [];
    }
  }, [user]);

  const formikChange = useFormik({
    initialValues: {
      change_password: 1,
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: validationPassword,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });

  const formikPreference = useFormik({
    initialValues: {
      email_prefer: [],
      sms_prefer: [],
    },
    onSubmit: (values) => {
      updatePreference(values);
    },
  });
  const emailPreference = [
    {
      name: 'email_prefer',
      type: 'checkboxarray',
      class: 'col-12',
      item: allNotifications.filter((inner) => inner.email === 1),
      formik: formikPreference,
    },
  ];

  const smsPreference = [
    {
      name: 'sms_prefer',
      type: 'checkboxarray',
      class: 'col-12',
      item: allNotifications.filter((inner) => inner.sms === 1),
      formik: formikPreference,
    },
  ];

  const changePassword = [
    {
      label: 'Current Password',
      name: 'current_password',
      type: 'password',
      placeholder: 'Enter your current password',
      class: 'col-md-6 col-sm-12',
      formik: formikChange,
    },
    {
      label: 'Password',
      name: 'new_password',
      type: 'password',
      placeholder: 'Enter your password',
      class: 'col-md-6 col-sm-12',
      formik: formikChange,
    },
    {
      label: 'Confirm password',
      name: 'confirm_password',
      type: 'password',
      placeholder: 'Re-enter your password',
      class: 'col-md-6 col-sm-12',
      formik: formikChange,
    },
  ];

  const profile = [
    {
      label: 'First name',
      type: 'text',
      placeholder: 'Enter your first name',
      class: 'col-md-6 col-sm-12',
      name: 'first_name',
      formik: formik,
    },
    {
      label: 'Last name',
      placeholder: 'Enter your last name',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      name: 'last_name',
      formik: formik,
    },
    {
      label: 'Email',
      placeholder: 'Enter your email',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      disabled: true,
      name: 'email',
      formik: formik,
    },
    {
      label: 'Phone number',
      placeholder: 'Enter your phone number',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      name: 'phone',
      formik: formik,
    },
    {
      label: 'Address 1',
      placeholder: 'Enter you address',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      name: 'address1',
      formik: formik,
    },
    {
      label: 'Address 2',
      placeholder: 'Apt #, Unit# et',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      name: 'address2',
      formik: formik,
    },
    {
      label: 'City',
      placeholder: 'Enter your city',
      class: 'col-md-6 col-sm-12',
      type: 'text',
      name: 'city',
      formik: formik,
    },
    {
      label: 'State',
      placeholder: 'Enter your state',
      class: 'col-md-6 col-sm-12',
      type: 'select',
      options: USStates,
      name: 'state',
      formik: formik,
    },
    {
      label: 'ZIP',
      placeholder: 'Enter your ZIP code',
      class: 'col-md-3 col-sm-12',
      type: 'number',
      name: 'zip',
      formik: formik,
    },
  ];

  useEffect(() => {
    if (responseStatusUser) {
      if (
        responseStatusUser.from === 'updateProfile' ||
        responseStatusUser.from === 'updatePreference'
      ) {
        if (responseStatusUser.status === 'success') {
          loadUser();
        }
      }
    }
  }, [responseStatusUser]);

  return (
    <DashboardLayout>
      <div className="myAccount">
        <div className="accountCnt">
          <form onSubmit={formik.handleSubmit} autocomplete="nofill">
            <h2 className="dashTitle">My Account</h2>
            <div className="accChild">
              <h4>Profile Details</h4>
              <div className="row">{Object.values(mapData(profile))}</div>
            </div>
            <PrimaryButton type="submit" label="Save" />
          </form>
          <form onSubmit={formikChange.handleSubmit} autocomplete="nofill">
            <div className="accChild">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Change Password</h4>
              </div>
              <div className="row">{Object.values(mapData(changePassword))}</div>
            </div>
            <PrimaryButton type="submit" label="Change" />
          </form>

          <form onSubmit={formikPreference.handleSubmit} autocomplete="nofill">
            <div className="accChild">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Notification Preference</h4>
              </div>
              <div className="row">
                <div className="col-6 text-left">
                  Email
                  <div className="row">{Object.values(mapData(emailPreference))}</div>
                </div>
                <div className="col-6 text-left">
                  SMS
                  <div className="row">{Object.values(mapData(smsPreference))}</div>
                </div>
              </div>
            </div>
            <PrimaryButton type="submit" label="SAVE" />
          </form>
        </div>
        <PhoneValidation
          phoneVerify={phoneVerify}
          formik={formik}
          setPhoneVerify={setPhoneVerify}
          changePhoneVerify={changePhoneVerify}
        />
      </div>
    </DashboardLayout>
  );
};

export default MyAccount;
