import { ListItem } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Dashboard.css';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SideNav = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="sideNav">
      <ul>
        <ListItem button onClick={handleClick}>
          <div
            className={`d-flex justify-content-between align-items-center w-100 ${
              open && 'activeDropdown'
            }`}
          >
            <span className="d-flex">
              <span className="material-icons">gavel</span>Current Auctions
            </span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <NavLink activeClassName="active" to="/dashboard/auction">
                All
              </NavLink>
            </ListItem>

            <ListItem button>
              <NavLink activeClassName="active" to="/dashboard/winning">
                Winning
              </NavLink>
            </ListItem>

            <ListItem button>
              <NavLink activeClassName="active" to="/dashboard/outbid">
                Currently Outbid
              </NavLink>
            </ListItem>
            <ListItem button>
              <NavLink activeClassName="active" to="/dashboard/won">
                {/* <span className="material-icons">emoji_events</span> */}
                Won
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button>
          <NavLink activeClassName="active" to="/dashboard/watchlist">
            <span className="material-icons">favorite</span>Watchlist
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink activeClassName="active" to="/cart">
            <span className="material-icons">shopping_cart</span>Cart
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink activeClassName="active" to="/appointments">
            <span className="material-icons">book_online</span>Appointments
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink activeClassName="active" to="/receipts">
            <span className="material-icons">receipt</span>Receipts
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink activeClassName="active" to="/returns">
            <span className="material-icons">keyboard_return</span>Return
          </NavLink>
        </ListItem>
        {/* <ListItem button>
                    <NavLink activeClassName="active" to="/savedCard">
                        <span className="material-icons">payments</span>My Saved Cards
                    </NavLink>
                </ListItem> */}
        <ListItem button>
          <NavLink activeClassName="active" to="/myaccount">
            <span class="material-icons">account_circle</span>My Account
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink activeClassName="active" to="/saved_search">
            <span className="material-icons">bookmark</span>My Saved Searches
          </NavLink>
        </ListItem>
      </ul>
    </div>
  );
};

export default SideNav;
