import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Drawer, ListItem } from '@material-ui/core';
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton';
import './ProductCard.css';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CommonContext from '../../../context/common/commonContext';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { dateTimeFormatFunction, useCustomMediaQuery } from '../../../common/components';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
const FileSaver = require('file-saver');

const useStyles = makeStyles({
  fullList: {
    width: 'auto',
  },
});

const shareActions = [
  {
    icon: <object type="image/svg+xml" data="/assets/svg/fb.svg" />,
    name: 'Facebook',
  },
  {
    icon: <object type="image/svg+xml" data="/assets/svg/tw.svg" />,
    name: 'Twitter',
  },
  {
    icon: <object type="image/svg+xml" data="/assets/svg/pin.svg" />,
    name: 'Pinterest',
  },
  {
    icon: <object type="image/svg+xml" data="/assets/svg/mail.svg" />,
    name: 'Email',
  },
  {
    icon: <object type="image/svg+xml" data="/assets/svg/gplus.svg" />,
    name: 'Google',
  },
];

const SpecialEvent = (props) => {
  const commonContext = useContext(CommonContext);
  const { setSearchValue, allLocations } = commonContext;

  const history = useHistory();
  const [shareOpen, setShareOpen] = React.useState(false);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const classes = useStyles();

  const [state, setState] = useState({
    bottom: false,
  });

  const formik = useFormik({
    initialValues: {
      auctionlotId: '',
    },
  });

  const auction = props.data;

  const auctionRedirect = (auctID, auctTitle) => {
    setSearchValue({
      filters: {
        auctionlotId: {
          value: [auctID],
          title: auctTitle,
        },
      },
    });
  };

  const handleRedirectInternal = (path) => {
    history.push(`/${path}`);
    window.scrollTo(0, 0);
  };

  const handleClose = (state) => {
    state(false);
  };

  const handleOpen = (state) => {
    state(true);
  };

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setState({ ...state, [anchor]: open, data: data });
    } else {
      setState({ ...state, [anchor]: open, data: {} });
    }
  };

  const calendarRedirect = (type) => {
    const locationDetails = allLocations
      .filter((inner) => inner.id === 1)
      .map(
        (filtered) => `${filtered.address}, ${filtered.city} ${filtered.state}  ${filtered.zipCode}`
      );
    const event = {
      title: auction.title,
      description: auction.description,
      start: auction.date_added,
      end: auction.date_closed,
      location: locationDetails[0],
    };
    if (type === 'ical') {
      FileSaver.saveAs(ics(event), 'hello-world.ics');
    } else if (type === 'yahoo') {
      const RedirectURL = new URL(yahoo(event));
      window.open(RedirectURL, '_blank');
    } else if (type === 'outlook') {
      const RedirectURL = new URL(outlook(event));
      window.open(RedirectURL, '_blank');
    } else {
      const RedirectURL = new URL(google(event));
      window.open(RedirectURL, '_blank');
    }
  };

  const calendarActions = [
    {
      icon: <img src="./assets/svg/googleCal.svg" alt="Google" />,
      name: 'Google Calendar',
      type: 'google',
    },
    {
      icon: <img src="./assets/svg/yahooCal.svg" alt="Yahoo" />,
      name: 'Yahoo Calendar',
      type: 'yahoo',
    },
    {
      icon: <img src="./assets/svg/outlookCal.svg" alt="Yahoo" />,
      name: 'Outlook Calendar',
      type: 'outlook',
    },
    {
      icon: <img src="./assets/svg/outlookCal.svg" alt="iCal" />,
      name: 'iCal',
      type: 'ical',
    },
  ];

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (new Date() < new Date(auction.date_added)) {
      setIsVisible(false);
    }
    var checkAuctionStarted = setInterval(() => {
      if (new Date() >= new Date(auction.date_added)) {
        setIsVisible(true);
      }
    }, 1000);
    return () => {
      clearInterval(checkAuctionStarted);
    };
  }, []);

  return (
    <div className="specialEvent d-flex justify-content-between align-items-start">
      <div className="specialEventLt d-flex">
        <div className="seImg">
          <img src={`${global.site_url}/uploads/auction/${auction.avatar}`} alt="Special Event" />
        </div>
        <div className="seContent">
          <h2>{auction.title}</h2>
          <p>Local Pickup Only | Las Vegas, NV</p>
          <p className="seAddress">{auction.description}</p>
          <p>{dateTimeFormatFunction(auction.date_added)}</p>
        </div>
      </div>
      <div className="seActBtn mt-3">
        {isVisible ? (
          <PrimaryButton
            onClick={() => auctionRedirect(auction.id, auction.title)}
            label="Explore Auction"
            btnSize="small"
          />
        ) : null}

        <div className="seLinks">
          {/* <SpeedDial
                        button
                        ariaLabel="share example"
                        icon={
                            <>
                                <i className="fas fa-share"></i>Share
                            </>
                        }
                        onClose={() => handleClose(setShareOpen)}
                        onClick={() => handleOpen(setShareOpen)}
                        open={shareOpen}
                        direction="up"
                    >
                        {shareActions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={() => handleClose(setShareOpen)}
                            />
                        ))}
                    </SpeedDial> */}
          {useCustomMediaQuery('(min-width: 600px)') ? (
            <SpeedDial
              button
              ariaLabel="calendar example"
              icon={
                <>
                  <i className="fas fa-calendar-plus"></i>
                  Add to Calendar
                </>
              }
              onClose={() => handleClose(setCalendarOpen)}
              onClick={() => handleOpen(setCalendarOpen)}
              open={calendarOpen}
              direction="up"
            >
              {calendarActions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={(e) => {
                    calendarRedirect(action.type);
                  }}
                />
              ))}
            </SpeedDial>
          ) : (
            <>
              <Button className="atcBtn" onClick={toggleDrawer('bottom', true)}>
                <i className="fas fa-calendar-plus"></i>
                Add to Calendar
              </Button>
              <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="specialEventDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
              >
                <div
                  className={clsx(classes.list, 'bottom')}
                  role="presentation"
                  onKeyDown={toggleDrawer('bottom', false)}
                >
                  <div className="calItems">
                    <h3>Add to calendar</h3>
                    {calendarActions.map((action) => (
                      <ListItem
                        button
                        onClick={(e) => {
                          calendarRedirect(action.type);
                        }}
                      >
                        <span className="ciIcon">{action.icon}</span>
                        <span>{action.name}</span>
                      </ListItem>
                    ))}
                  </div>
                </div>
              </Drawer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SpecialEvent;
