import React, { useState, useContext, useEffect } from 'react';
import './Header.css';
import { Button, ListItem } from '@material-ui/core';
import CommonContext from '../../../context/common/commonContext';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';

const BottomHeader = () => {
  const [itemsToBeDisplayed, setItemsToBeDisplayed] = useState(6);
  const commonContext = useContext(CommonContext);
  const { allCategory, setSearchValue, searchValue } = commonContext;

  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      category: '',
    },
  });

  useEffect(() => {
    if (formik.values.category) {
      setSearchValue({
        filters: {
          category: {
            value: [formik.values.category],
          },
        },
      });
    }
  }, [formik.values]);

  useEffect(() => {
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth <= 1180) {
      setItemsToBeDisplayed(4);
    }
  }, [itemsToBeDisplayed]);

  return (
    <div className="bottomHeader">
      <div className="d-flex justify-content-between align-items-center customContainer">
        {/* <Button>All Categories</Button> */}
        {allCategory.slice(0, itemsToBeDisplayed).map((data, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => {
                formik.setFieldValue('category', data.id);
              }}
              selected={
                formik.values.category == data.id && location.pathname !== '/' ? true : false
              }
            >
              {data.description}
            </ListItem>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default BottomHeader;
