import React, { useState, useContext, useEffect, useRef } from 'react';
import openSocket from 'socket.io-client';
import InstantSearchFilterPanel from '../../custom/components/organisms/FilterPanel/instantSearch-filter-panel';
import '../Search/Search.css';
import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ProductContext from '../../custom/context/product/productContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import CommonContext from '../../custom/context/common/commonContext';
import { messageHandler } from '../Common/socketHandler';
import SearchSkeleton from '../Search/SearchSkeleton';
import { Stats, Configure } from 'react-instantsearch-dom';
import PageSelector from './page-selector';
import SearchInput from './search-input';
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect';
import SearchResults from './SearchResults';
import { connectStateResults } from 'react-instantsearch-dom';
import Typography from '@material-ui/core/Typography';
import SortBy from './sort-by';
import ExtraFilters from '../../custom/components/organisms/FilterPanel/extra-filters';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const pageOptions = [
  {
    value: 10,
    show: '10 Results per page',
  },
  {
    value: 20,
    show: '20 Results per page',
  },
  {
    value: 30,
    show: '30 Results per page',
  },
];

const sortOptions = [
  {
    value: process.env.REACT_APP_ALGOLIA_INDEX,
    show: 'Featured',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_current_price_asc`,
    show: 'Current Price (Low - High)',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_current_price_desc`,
    show: 'Current Price (High - Low)',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_time_remaining_asc`,
    show: 'Show Auctions Ending Earliest',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_time_remaining_desc`,
    show: 'Show Auctions Ending Latest',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_retail_price_asc`,
    show: 'Suggested Retail (Low - High)',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_retail_price_desc`,
    show: 'Suggested Retail (High - Low)',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_bids_asc`,
    show: 'Bid Count (Low - High)',
  },
  {
    value: `${process.env.REACT_APP_ALGOLIA_INDEX}_bids_desc`,
    show: 'Bid Count (High - Low)',
  },
];

const ReactInstantSearch = ({ searchResults }) => {
  const productContext = useContext(ProductContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { currentLocation } = commonContext;
  const hitsPerPage = searchResults && searchResults.hitsPerPage;
  const currentQuery = searchResults && searchResults.query;
  const { algolia_producthits, refreshAlgoliaProductDetails, responseStatus } = productContext;
  const { user } = authContext;
  const { setAlert } = alertContext;
  const [auctionView, setAuctionView] = useState('Grid');
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    bottom: false,
  });
  let [viewProduct, setViewProduct] = useState([]);
  const [resultLimit, setResultLimit] = useState(pageOptions[0]);
  const theme = useTheme();
  const largerThanMobile = useMediaQuery(theme.breakpoints.up('SM'));
  const largerThanTablet = useMediaQuery(theme.breakpoints.up('MD'));

  useEffect(() => {
    setLoading(true);
  }, []);

  const initialLoad = useRef(true);

  useEffect(() => {
    if (!initialLoad.current) {
      setLoading(false);
      setViewProduct(algolia_producthits?.records ?? []);
    } else {
      initialLoad.current = false;
    }
  }, [algolia_producthits]);

  useEffect(() => {
    setViewProduct([]);
    return () => {
      setViewProduct([]);
    };
  }, []);

  const viewProductRef = useRef(viewProduct);
  const userRef = useRef(user);
  useEffect(() => {
    viewProductRef.current = viewProduct;
    userRef.current = user;
  });

  const handler = (message, type) => {
    if (type === 'productPosted' || type === 'productClosed') {
      setLoading(true);
      refreshAlgoliaProductDetails();
    } else {
      messageHandler(
        message,
        viewProductRef.current,
        userRef.current,
        setAlert,
        setViewProduct,
        type
      );
      refreshAlgoliaProductDetails();
    }
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'watchlist') {
        handler(
          { id: responseStatus.data.project_id, status: responseStatus.data.status },
          'watchlistAdded'
        );
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`);
    socket.on('realclosedupdates', (data) => {
      handler(data, 'realclosedupdates');
    });
    socket.on('bidAddtime', (data) => {
      handler(data, 'bidAddtime');
    });
    socket.on('productPosted', (data) => {
      handler(data, 'productPosted');
    });
    socket.on('productClosed', (data) => {
      handler(data, 'productClosed');
    });

    return () => {
      socket.off('realclosedupdates', (data) => {
        handler(data, 'realclosedupdates');
      });
      socket.off('bidAddtime', (data) => {
        handler(data, 'bidAddtime');
      });
      socket.off('productPosted', (data) => {
        handler(data, 'productPosted');
      });
      socket.off('productClosed', (data) => {
        handler(data, 'productClosed');
      });
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (state.right) {
      const index = viewProduct.findIndex((s) => s.id === parseInt(state.data.id, 10));
      if (index !== -1) {
        setState({ ...state, data: viewProduct[index] });
      }
    }
  }, [viewProduct]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const changePageLimit = (limit) => {
    const selection = pageOptions.find(({ value }) => value === Number(limit));
    setResultLimit(selection);
  };

  return (
    <div className="search customContainer">
      {/* This Configure sets Algolia's number of results per page in hitsPerPage and the location from currentLocation in commonContext via filters.
          Display is hidden */}
      <Configure
        hitsPerPage={resultLimit.value}
        filters={`Location:"${currentLocation.city}, ${currentLocation.state}"`}
      />
      <div className="d-flex justify-content-start align-items-start">
        <div className="searchLt">
          <SearchInput iconColor={'primary'} useStartAdornment />
          <CustomSelect
            label={'Results per page'}
            value={resultLimit.value}
            size={'small'}
            name={'limit'}
            shrink={resultLimit.value !== ''}
            onChange={(event) => changePageLimit(event.target.value)}
          >
            {pageOptions.map(({ value, show }, index) => (
              <option key={value + index} value={value}>
                {show}
              </option>
            ))}
          </CustomSelect>
          <div className="mt-2 mb-3 gridListToggle">
            <Button
              className={auctionView === 'Grid' ? 'active' : ''}
              onClick={() => setAuctionView('Grid')}
            >
              <i className="fas fa-th-large" /> Grid
            </Button>
            <Button
              className={auctionView === 'List' ? 'active' : ''}
              onClick={() => setAuctionView('List')}
            >
              <i className="fas fa-th-list" />
              List
            </Button>
          </div>
          <SortBy items={sortOptions} defaultRefinement={sortOptions[0].value} className={'mb-4'} />
          {largerThanTablet ? (
            <div className="deskFilter">
              <InstantSearchFilterPanel>
                <ExtraFilters />
              </InstantSearchFilterPanel>
            </div>
          ) : (
            <Drawer
              anchor="bottom"
              open={state['bottom']}
              className="responsiveFilterDrawer"
              onClose={toggleDrawer('bottom', false)}
              onOpen={toggleDrawer('bottom', true)}
            >
              <div
                role="presentation"
                // onClick={toggleDrawer('bottom', false)}
                onKeyDown={toggleDrawer('bottom', false)}
              >
                <div>
                  <h4 className="sbTitle">Sort By</h4>
                  <div className="searchAuctions">
                    <SortBy
                      items={sortOptions}
                      defaultRefinement={sortOptions[0].value}
                      className={'d-flex justify-content-start align-items-center flex-wrap'}
                      button
                    />
                  </div>
                  <h4 className="sbTitle">Results Per Page</h4>
                  <div className="searchAuctions">
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                      {pageOptions.map((opt, index) => (
                        <Button
                          key={index}
                          className={hitsPerPage === opt.value ? 'active' : null}
                          onClick={() => changePageLimit(opt.value)}
                        >
                          {opt.show}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
                <InstantSearchFilterPanel>
                  <ExtraFilters />
                </InstantSearchFilterPanel>
              </div>
            </Drawer>
          )}
        </div>

        <div className="searchRt">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sectionTitle w-100">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <h2 className="mb-3">{currentLocation.city + ', ' + currentLocation.state}</h2>
                <Button
                  className="toggleRespDrawer"
                  variant="outlined"
                  onClick={toggleDrawer('bottom', true)}
                >
                  <span className="material-icons">tune</span>Filters
                </Button>
              </div>
              <div className="d-flex">
                <Stats
                  className={'mr-1'}
                  translations={{
                    stats(_, processingTimeMS) {
                      const count = algolia_producthits?.totalRecords ?? 0;
                      return `${count} ${
                        count === 1 ? 'item' : 'items'
                      } found in ${processingTimeMS} ms`;
                    },
                  }}
                />
                {currentQuery && currentQuery !== '' && (
                  <Typography variant={'body1'} color={'primary'}>
                    when searching for "{currentQuery}"
                  </Typography>
                )}
              </div>
            </div>
          </div>
          {loading ? (
            <SearchSkeleton />
          ) : (
            <>
              {viewProduct.length ? (
                <div className="searchResults d-flex justify-content-start flex-wrap">
                  <SearchResults
                    auctionView={auctionView}
                    direction={largerThanMobile ? 'right' : 'bottom'}
                  />
                  <PageSelector />
                </div>
              ) : (
                <div className="np-pro-box">
                  <img src="./assets/svg/noResults.svg" alt="No results image" />
                  <h5>No Records Found!</h5>
                  <h6>
                    We couldn't find what you are looking for.
                    <br /> You can try changing the filters or come back later.
                  </h6>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default connectStateResults(ReactInstantSearch);
