import {
  GET_ALL_INVOICE,
  GET_ALL_USER_INVOICES,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
} from './invoiceTypes';

export default (state, action) => {
  switch (action.type) {
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case GET_ALL_USER_INVOICES:
      return {
        ...state,
        search_alluserinvoices: action.payload,
      };
    case GET_ALL_INVOICE:
      return {
        ...state,
        receipts: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    default:
      return state;
  }
};
