import React, { useReducer } from 'react';
import InvoiceContext from './invoiceContext';
import InvoiceReducer from './invoiceReducer';
import { apiCall } from '../../common/api';
import { response } from '../common';

import {
  GET_ALL_INVOICE,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_ALL_USER_INVOICES,
} from './invoiceTypes';

const InvoiceState = (props) => {
  const initialState = {
    receipts: {
      invoicerecords: [],
      transactionrecords: [],
      appointmentrecord: {},
      locationrecord: {},
      cartvalues: {},
    },
    receipts: {
      records: [],
      totalRecords: 0,
      setDisp: '',
    },
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(InvoiceReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const getAllReceipts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'getAllReceipts', formData, '', 'invoice')]);
      const from = 'invoicesearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_INVOICE,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllUserInvoices = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'allinvoices', formData, '', 'invoice')]);
      const from = 'allinvoices';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_USER_INVOICES,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <InvoiceContext.Provider
      value={{
        receipts: state.receipts,
        search_alluserinvoices: state.search_alluserinvoices,
        responseStatus: state.responseStatus,
        clearResponse,
        getAllReceipts,
        getAllUserInvoices,
      }}
    >
      {props.children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceState;
