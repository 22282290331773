import React, { useEffect, useContext } from 'react';
import Login from '../views/Login';
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import PrivateRoute from './privateRoute';
import Header from '../custom/components/molecules/Header';
import Footer from '../custom/components/molecules/Footer';
import Registration from '../views/Registration';
import RegistrationSuccess from '../views/Registration/success';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import VerifyEmail from '../views/VerifyEmail';
import Home from '../views/Home';
import Search from '../views/Search';
import ReactInstantSearch from '../views/ReactInstantSearch';
import Cart from '../views/Cart';
import Checkout from '../views/Checkout';
import ReturnCheckout from '../views/ReturnCheckout';
import MyAuctions from '../views/Dashboard/MyAuctions';
import MyAccount from '../views/Dashboard/MyAccount';
import MyAppointments from '../views/Dashboard/MyAppointments';
import AuthContext from '../custom/context/auth/authContext';
import CommonContext from '../custom/context/common/commonContext';
import Invoice from '../views/Invoice';
import Appointment from '../views/Appointment';
import ReturnInvoice from '../views/ReturnInvoice';
import Return from '../views/Return';
import CheckoutSkeleton from '../views/Checkout/CheckoutSkeleton';
import csc from 'country-state-city';
import MyInvoices from '../views/Dashboard/MyInvoices';
import MyReturns from '../views/Dashboard/MyReturns';
import StaticPage from '../views/Static';
import Error404 from '../views/ErrorPages/Error404';
import SavedSearch from '../views/Dashboard/SavedSearch';
import UserMigration from '../views/UserMigration';

const Routes = (props) => {
  const authContext = useContext(AuthContext);
  const { loadUser } = authContext;
  const commonContext = useContext(CommonContext);
  const { getGlobalVariable, setUSState } = commonContext;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.token) {
      loadUser();
    }

    getGlobalVariable();
  }, []);

  useEffect(() => {
    const USStates = [];
    csc.getStatesOfCountry('231').map((lot) => {
      USStates.push({ value: lot.name, show: lot.name });
    });
    setUSState(USStates);
  }, []);

  if (props.location.pathname !== '/returncheckout') {
    sessionStorage.removeItem('returnItem');
  }
  if (props.location.pathname !== '/checkout') {
    sessionStorage.removeItem('checkout');
  }
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/registration/success" component={RegistrationSuccess} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset_password/:token" component={ResetPassword} />
        <Route exact path="/user_transfer/:token" component={UserMigration} />
        <Redirect from="/verify_email/:token" to="/activation/success/:token" />
        <Route exact path="/activation/success/:token" component={VerifyEmail} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/reactinstantsearch" component={ReactInstantSearch} />
        <PrivateRoute exact path="/cart" component={Cart} />
        <PrivateRoute exact path="/checkout" component={Checkout} />
        <PrivateRoute exact path="/returncheckout" component={ReturnCheckout} />
        <PrivateRoute exact path="/dashboard/:type" component={MyAuctions} />
        <PrivateRoute exact path="/appointments" component={MyAppointments} />
        <PrivateRoute exact path="/appointments/:id" component={Appointment} />
        <PrivateRoute exact path="/receipts" component={MyInvoices} />
        <PrivateRoute exact path="/returninvoice" component={MyReturns} />
        <PrivateRoute exact path="/myaccount" component={MyAccount} />
        <Route exact path="/saved_search" component={SavedSearch} />
        <PrivateRoute exact path="/invoice/:id" component={Invoice} />
        <PrivateRoute exact path="/returninvoice/:id" component={ReturnInvoice} />
        <PrivateRoute exact path="/returns" component={Return} />
        <Route exact path="/skeleton" component={CheckoutSkeleton} />
        <Route
          exact
          path={[
            '/estate-sales',
            '/business-bankruptcy',
            '/about-us',
            '/careers',
            '/location-hours',
            '/contact',
            '/terms',
            '/privacy',
          ]}
          component={StaticPage}
        />
        <Route exact path="*" component={Error404} />
      </Switch>
      <Footer />
    </>
  );
};
export default withRouter(Routes);
