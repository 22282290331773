import React, { useState, useRef, useEffect, useContext } from 'react';
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton';
import { useHistory } from 'react-router-dom';
import {
  handleRedirectInternal,
  dateTimeFormatFunction,
  currencyFormat,
} from '../../../common/components';
import './InvoiceCard.css';

const InvoiceCard = (props) => {
  const [product, setProduct] = useState();
  const history = useHistory();
  const type = props.type;
  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);
  return (
    <>
      <div className="invoiceCard">
        {product ? (
          <>
            <h3 className="custName">
              Invoice #{type === 'invoice' ? product.common_invoice : product.return_invoice}
            </h3>
            <h5>{dateTimeFormatFunction(product.cart_paiddate)}</h5>
            {type === 'invoice' ? (
              <>
                {product.cart_paid ? (
                  <div className="icPaid d-flex justify-content-between align-items-center">
                    <span>Paid</span>
                  </div>
                ) : (
                  <div className="icPaid d-flex justify-content-between align-items-center">
                    <span>Unpaid</span>
                  </div>
                )}
              </>
            ) : (
              <>
                {product.paid ? (
                  <div className="icPaid d-flex justify-content-between align-items-center">
                    <span>Refunded</span>
                  </div>
                ) : (
                  <div className="icPaid d-flex justify-content-between align-items-center">
                    <span>Pending</span>
                  </div>
                )}
              </>
            )}

            <div className="icItems d-flex justify-content-between align-items-center">
              <span>Total Items:</span> <span>{product.total_items}</span>
            </div>
            <div className="icPaid d-flex justify-content-between align-items-center">
              <span>Amount:</span> <span>{currencyFormat(product.total_amount)}</span>
            </div>
            {type === 'invoice' ? (
              <PrimaryButton
                label="View Invoice"
                onClick={() => handleRedirectInternal(history, `invoice/${product.common_invoice}`)}
                btnSize="small"
              />
            ) : (
              <PrimaryButton
                label="View Invoice"
                onClick={() =>
                  handleRedirectInternal(history, `returninvoice/${product.return_invoice}`)
                }
                btnSize="small"
              />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default InvoiceCard;
