import React, { useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputBase,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import './FilterPanel.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';
import { handleRedirectInternal } from '../../../common/components';
import { useHistory, useLocation } from 'react-router-dom';

const ConnectedRefinementCheckbox = (props) => {
  const {
    searchForItems,
    items,
    refine,
    limit,
    showMore,
    title,
    inputPlaceholder,
    isSearchable,
    isExpanded,
    classes,
  } = props;
  const [extended, setExtended] = useState(false);
  const [expanded, setExpanded] = useState(isExpanded);
  const [value, setValue] = useState('');
  const history = useHistory();
  const location = useLocation();
  const refinedCount = items.reduce((a, b) => (b.isRefined ? a + 1 : a), 0);

  return (
    <Accordion square expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setExpanded(!expanded)}
        className={classes.accordionSummary}
      >
        <h6 className={'accTitle'}>{title}</h6>
        {refinedCount !== 0 && (
          <Skeleton
            variant={'circle'}
            width={25}
            height={25}
            animation={false}
            className={classes.skeletonCircle}
          >
            {refinedCount}
          </Skeleton>
        )}
      </AccordionSummary>
      <AccordionDetails className={'filterCheck d-flex align-items-center flex-wrap'}>
        {isSearchable && (
          <Paper component={'form'}>
            <InputBase
              placeholder={inputPlaceholder}
              inputProps={{
                'aria-label': `${inputPlaceholder}`,
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                searchForItems(e.currentTarget.value);
              }}
            />
            <IconButton
              type={'button'}
              aria-label={'clear search'}
              onClick={() => {
                setValue('');
                searchForItems('');
              }}
            >
              {value.length > 0 && <ClearIcon style={{ color: 'primary' }} />}
            </IconButton>
          </Paper>
        )}
        {items.map(
          (option, i) =>
            (i < limit || extended) && (
              <FormGroup row key={option.label + i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={'primary'}
                      name={option.label}
                      checked={option.isRefined}
                      value={option.value}
                      onChange={(event) => {
                        event.preventDefault();
                        refine(option.value);
                        //  TODO - Pass this path change down in props from  home page => filter panel => here
                        if (location.pathname !== `/reactinstantsearch`) {
                          handleRedirectInternal(history, `reactinstantsearch`);
                        }
                      }}
                    />
                  }
                  label={option.label}
                />
              </FormGroup>
            )
        )}
      </AccordionDetails>
      {showMore && items.length > 4 && (
        <Button onClick={() => setExtended(!extended)}>{extended ? 'See Less' : 'See All'}</Button>
      )}
    </Accordion>
  );
};

export default withStyles(style)(connectRefinementList(ConnectedRefinementCheckbox));
