import React, { useContext, useState } from 'react';
import ProductContext from '../../../custom/context/product/productContext';
import GridView from '../../../custom/components/molecules/ProductCard/GridView';
import ListView from '../../../custom/components/molecules/ProductCard/ListView';
import Drawer from '@material-ui/core/Drawer';
import ProductView from '../../../custom/components/organisms/ProductView';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';

const SearchResults = ({ classes, auctionView, direction }) => {
  const productContext = useContext(ProductContext);
  const { algolia_producthits } = productContext;
  const [state, setState] = useState({
    right: false,
    bottom: false,
    data: {},
  });

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setState({ ...state, [anchor]: open, data: data });
    } else {
      setState({ ...state, [anchor]: open, data: {} });
    }
  };

  return (
    <>
      <Drawer
        className={classes.productDrawer}
        anchor={direction}
        open={state[direction]}
        onClose={toggleDrawer(direction, false)}
        elevation={8}
      >
        <ProductView data={state.data} drawerHandler={toggleDrawer(direction, false)} />
      </Drawer>
      {algolia_producthits.records.map((product, index) => {
        if (auctionView === 'List') {
          return (
            <ListView
              data={product}
              favId={`searchProd_${index}`}
              key={index}
              drawerHandler={toggleDrawer(direction, true, product)}
            />
          );
        }
        return (
          <GridView
            key={index}
            favId={`searchProd_${index}`}
            data={product}
            drawerHandler={toggleDrawer(direction, true, product)}
            renderHighlight
          />
        );
      })}
    </>
  );
};

export default withStyles(style)(SearchResults);
