import React, { useContext, useEffect } from 'react';
import TopHeader from './TopHeader';
import MainHeader from './MainHeader';
import BottomHeader from './BottomHeader';
import { useHistory } from 'react-router-dom';

import { handleRedirectInternal } from '../../../common/components';
import ProductContext from '../../../../custom/context/product/productContext';
import AlertContext from '../../../../custom/context/alert/alertContext';
import CommonContext from '../../../context/common/commonContext';
import UserContext from '../../../../custom/context/user/userContext';
import AutoPilotContext from '../../../context/autopilot/AutoPilotContext';
import BidHistory from '../../organisms/BidHistory';
import { Button } from '@material-ui/core';

const Header = () => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const userContext = useContext(UserContext);
  const autoPilotContext = useContext(AutoPilotContext);

  const history = useHistory();

  const { setAlert } = alertContext;

  const {
    responseStatus: responseStatusProduct,
    clearResponse: clearResponseProduct,
  } = productContext;
  const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext;
  const {
    responseStatus: responseStatusAutoPilot,
    clearResponse: clearResponseAutoPilot,
  } = autoPilotContext;

  const { searchValue } = commonContext;

  useEffect(() => {
    if (searchValue) {
      handleRedirectInternal(history, `search`);
    }
  }, [searchValue]);

  useEffect(() => {
    if (responseStatusUser) {
      setAlert(responseStatusUser.message, responseStatusUser.status);
      clearResponseUser();
    }
    if (responseStatusProduct) {
      setAlert(responseStatusProduct.message, responseStatusProduct.status);
      clearResponseProduct();
    }
    if (responseStatusAutoPilot) {
      setAlert(responseStatusAutoPilot.message, responseStatusAutoPilot.status);
      clearResponseAutoPilot();
    }
  }, [responseStatusUser, responseStatusProduct, responseStatusAutoPilot]);

  return (
    <>
      <header>
        <TopHeader />
        <MainHeader />
        <BottomHeader />
        <Button
          className="scrollTop"
          onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        >
          <span className="material-icons">keyboard_arrow_up</span>
        </Button>
      </header>
      <BidHistory />
    </>
  );
};

export default Header;
