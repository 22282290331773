import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import openSocket from 'socket.io-client';
import HomeBanner from '../../custom/components/molecules/HomeBanner';
import GridView from '../../custom/components/molecules/ProductCard/GridView';
import ProductContext from '../../custom/context/product/productContext';
import AuctionContext from '../../custom/context/auction/auctionContext';
import { Pagination } from '@material-ui/lab';
import SpecialEvent from '../../custom/components/molecules/ProductCard/SpecialEvent';
import CommonContext from '../../custom/context/common/commonContext';
import ProductView from '../../custom/components/organisms/ProductView';
import './Home.css';
import clsx from 'clsx';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import AlertContext from '../../custom/context/alert/alertContext';
import AuthContext from '../../custom/context/auth/authContext';
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import { messageHandler } from '../Common/socketHandler';
import { useCustomMediaQuery } from '../../product/common/components';
import CustomDialog from '../../custom/components/organisms/CustomDialog';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import SpecialEventSkeleton from '../../custom/components/molecules/ProductCard/ProductCardSkeletons/SpecialEventSkeleton';
import HomeSkeleton from './HomeSkeleton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput';
import HomeFilterPanel from '../../custom/components/organisms/FilterPanel/homeFilterPanel';

const useStyles = makeStyles({
  fullList: {
    width: 'auto',
  },
});

const Home = () => {
  const productContext = useContext(ProductContext);
  const auctionContext = useContext(AuctionContext);
  const authContext = useContext(AuthContext);
  const commonContext = useContext(CommonContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const { user } = authContext;
  const { search_allauctions, getAllAuctionProducts } = auctionContext;
  const { search_allproducts, getAllSearchProducts, responseStatus } = productContext;
  const { currentLocation } = commonContext;
  const history = useHistory();
  const [value, setValue] = useState('');

  const [toggleDialog, setToggleDialog] = useState(false);

  const changeDialogStatus = () => {
    setToggleDialog(!toggleDialog);
  };

  useEffect(() => {
    !window.kount_session_id && setToggleDialog(true);
  }, []);

  const [previousSlideIndex, setPreviousSlideIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [nextSlideIndex, setNextSlideIndex] = useState(0);
  let [viewProduct, setViewProduct] = useState([]);
  let [auctions, setAuctions] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingAuction, setLoadingAuction] = useState(false);

  const classes = useStyles();

  const [state, setState] = useState({
    right: false,
    bottom: false,
    data: {},
  });

  const [search, setSearch] = useState({
    limit: 10,
    page: 1,
    orderby: 'null',
    filters: {
      currentLocation: {
        state: currentLocation.state,
        city: currentLocation.city,
      },
    },
  });

  const [searchAuction, setSearchAuction] = useState({
    limit: 10,
    page: 1,
    orderby: 1,
    filters: {
      currentLocation: {
        state: currentLocation.state,
        city: currentLocation.city,
      },
    },
  });

  useEffect(() => {
    setLoadingProduct(true);
    setLoadingAuction(true);
    getAllSearchProducts(search);
    getAllAuctionProducts(searchAuction);
  }, []);

  const locationInitialLoad = useRef(true);
  useEffect(() => {
    if (locationInitialLoad.current == true) {
      locationInitialLoad.current = false;
    } else {
      let searchAuctionFilter = { ...searchAuction };
      searchAuctionFilter.filters.currentLocation = {
        state: currentLocation.state,
        city: currentLocation.city,
      };
      setSearchAuction(searchAuctionFilter);
      getAllAuctionProducts(searchAuctionFilter);
    }
  }, [currentLocation]);

  const onHandlePageAuction = (event, value) => {
    setSearchAuction({ ...searchAuction, page: value });
  };

  const productInitialLoad = useRef(true);
  const auctionInitialLoad = useRef(true);
  useEffect(() => {
    //console.log('search_allproducts', search_allproducts)
    if (productInitialLoad.current) {
      productInitialLoad.current = false;
    } else {
      setLoadingProduct(false);
      setViewProduct(search_allproducts.records.length ? search_allproducts.records : []);
    }
  }, [search_allproducts]);

  useEffect(() => {
    //console.log('search_allauctions', search_allauctions)
    if (auctionInitialLoad.current) {
      auctionInitialLoad.current = false;
    } else {
      setLoadingAuction(false);
      setAuctions(search_allauctions.records.length ? search_allauctions.records : []);
    }
  }, [search_allauctions]);

  useEffect(() => {
    setViewProduct([]);
    return () => {
      setViewProduct([]);
    };
  }, []);

  const viewProductRef = useRef(viewProduct);
  const userRef = useRef(user);
  useEffect(() => {
    viewProductRef.current = viewProduct;
    userRef.current = user;
  });

  const handler = (message, type) => {
    messageHandler(
      message,
      viewProductRef.current,
      userRef.current,
      setAlert,
      setViewProduct,
      type
    );
    getAllSearchProducts(search);
  };

  useEffect(() => {
    const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`);
    socket.on('realclosedupdates', (data) => {
      handler(data, 'realclosedupdates');
    });
    socket.on('bidAddtime', (data) => {
      handler(data, 'bidAddtime');
    });
    return () => {
      socket.off('realclosedupdates', (data) => {
        handler(data, 'realclosedupdates');
      });
      socket.off('bidAddtime', (data) => {
        handler(data, 'bidAddtime');
      });
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (state.right) {
      const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10));
      if (index !== -1) {
        setState({ ...state, data: viewProduct[index] });
      }
    }
  }, [viewProduct]);

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setState({ ...state, [anchor]: open, data: data });
    } else {
      setState({ ...state, [anchor]: open, data: {} });
    }
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => {
      setPreviousSlideIndex(current);
      setCurrentSlideIndex(next);
    },
    afterChange: (current) => setNextSlideIndex(current),
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(`/reactinstantsearch?query=${value}`);
    }
  };

  return (
    <div className="home customContainer">
      <HomeBanner />
      <div className="homeCnt d-flex justify-content-start align-items-start">
        <div className="homeLt">
          {useCustomMediaQuery('(min-width: 1024px)') ? (
            <div className="deskFilter">
              <div className=" mb-3 searchInput">
                <CustomInput
                  inputStyle={{
                    backgroundColor: 'white',
                  }}
                  name={'homeSearchbar'}
                  autoFocus
                  className={'form-control'}
                  placeholder={'Search'}
                  aria-label={'Search'}
                  aria-describedby={'searchIcon'}
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                  onKeyUp={(event) => handleKeyDown(event)}
                  startAdornment={<SearchIcon style={{ marginRight: '3px' }} color={'primary'} />}
                  endAdornment={
                    value && (
                      <CloseIcon
                        onClick={() => {
                          setValue('');
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    )
                  }
                />
              </div>
              <HomeFilterPanel />
            </div>
          ) : (
            <Drawer
              anchor="bottom"
              open={state['bottom']}
              className="responsiveFilterDrawer"
              onClose={toggleDrawer('bottom', false)}
              onOpen={toggleDrawer('bottom', true)}
            >
              <div
                className={clsx(classes.list, 'bottom')}
                role="presentation"
                // onClick={toggleDrawer('bottom', false)}
                onKeyDown={toggleDrawer('bottom', false)}
              >
                <HomeFilterPanel />
              </div>
            </Drawer>
          )}
        </div>

        <div className="homeRt">
          {loadingProduct ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div className="sectionTitle d-flex justify-content-start align-items-center">
                  <object data="/assets/svg/flame.svg" type="image/svg+xml" />
                  <h2>Trending Now</h2>
                  <Link to="/reactinstantsearch">VIEW ALL</Link>
                </div>
                <div className="productNav"></div>
              </div>
              <HomeSkeleton />
            </>
          ) : viewProduct.length ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div className="sectionTitle d-flex justify-content-start align-items-center">
                  <object data="/assets/svg/flame.svg" type="image/svg+xml" />
                  <h2>Trending Now</h2>
                  <Link to="/reactinstantsearch">VIEW ALL</Link>
                </div>
                <div className="productNav">
                  {currentSlideIndex + 1} of {viewProduct.length}
                </div>
              </div>
              <div className="trendingItems">
                <Drawer
                  className="rightDrawer"
                  anchor={'right'}
                  open={state['right']}
                  onClose={toggleDrawer('right', false)}
                >
                  <ProductView data={state.data} drawerHandler={toggleDrawer('right', false)} />
                </Drawer>
                <Slider {...settings}>
                  {search_allproducts.records.map((product, index) => (
                    <GridView
                      key={index}
                      data={product}
                      favId={`searchProd_${index}`}
                      drawerHandler={toggleDrawer('right', true, product)}
                    />
                  ))}
                </Slider>
              </div>
            </>
          ) : (
            <div className="np-pro-box"> No Records Found! </div>
          )}
          <div className="sectionTitle d-flex justify-content-between align-items-center text-left mt-4">
            <h2>Special Events</h2>
            <Button
              className="toggleRespDrawer"
              variant="outlined"
              onClick={toggleDrawer('bottom', true)}
            >
              <span className="material-icons">tune</span>Filters
            </Button>
          </div>
          {loadingAuction ? (
            <SpecialEventSkeleton />
          ) : auctions.length ? (
            <div className="homeSpecialItems">
              {auctions.map((data, index) => (
                <SpecialEvent data={data} />
              ))}

              <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                <h6>
                  Showing {search_allauctions.setDisp} of {search_allauctions.totalRecords}
                </h6>
                <Pagination
                  count={Math.ceil(search_allauctions.totalRecords / searchAuction.limit)}
                  page={searchAuction.page}
                  onChange={onHandlePageAuction}
                  siblingCount={3}
                  showFirstButton
                  showLastButton
                  boundaryCount={2}
                />
              </div>
            </div>
          ) : (
            <div className="np-pro-box"> No Records Found! </div>
          )}
        </div>
      </div>

      <CustomDialog title={'Ad-Blocker Detected'} open={toggleDialog} function={changeDialogStatus}>
        <h5>Please disable your ad-blocker to continue</h5>
        <div className="actionWrapper">
          <PrimaryButton
            onClick={() => setToggleDialog(false)}
            type="submit"
            label="Disable Ad-Blocker"
          />
        </div>
      </CustomDialog>
    </div>
  );
};
export default Home;
