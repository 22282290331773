import React, { useContext, useEffect } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import AutoPilotContext from '../../../context/autopilot/AutoPilotContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { mapData } from '../../../common/components';
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton';

const Footer = () => {
  const autoPilotContext = useContext(AutoPilotContext);

  const { addCustomer, responseStatus: responseStatusAutoPilot } = autoPilotContext;

  const validationArray = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required!'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      addCustomer({ Email: values.email });
    },
  });

  const subscribe = [
    {
      label: 'Email',
      placeholder: 'Enter your email',
      class: 'subsInp',
      type: 'text',
      name: 'email',
      formik: formik,
    },
  ];

  useEffect(() => {
    if (responseStatusAutoPilot) {
      if (responseStatusAutoPilot.from === 'addCustomer') {
        if (responseStatusAutoPilot.status === 'success') {
          formik.values.email = '';
        }
      }
    }
  }, [responseStatusAutoPilot]);

  return (
    <footer>
      <div className="footerCnt customContainer d-flex justify-content-between">
        <ul className="footLinks">
          <li className="flHead">Services</li>
          <li>
            <Link to="/estate-sales">
              <ListItem button>Estate Sales</ListItem>
            </Link>
          </li>
          <li>
            <Link to="/business-bankruptcy">
              <ListItem button>Business Bankruptcy</ListItem>
            </Link>
          </li>
        </ul>

        <ul className="footLinks">
          <li className="flHead">Company</li>
          <li>
            <Link to="/about-us">
              <ListItem button>About Us</ListItem>
            </Link>
          </li>
          <li>
            <Link to="/careers">
              <ListItem button>Careers</ListItem>
            </Link>
          </li>
          <li>
            <Link to="/location-hours">
              <ListItem button>Location & Hours </ListItem>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <ListItem button>Contact</ListItem>
            </Link>
          </li>
        </ul>

        <ul className="footLinks">
          <li className="flHead">Connect</li>
          <li>
            <a href="https://www.facebook.com/NellisAuction/" target="_blank" rel="noreferrer">
              <ListItem button>Facebook</ListItem>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/nellisauction/" target="_blank" rel="noreferrer">
              <ListItem button>Instagram</ListItem>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/nellis-auction/"
              target="_blank"
              rel="noreferrer"
            >
              <ListItem button>Linkedin</ListItem>
            </a>
          </li>
        </ul>

        <ul className="footLinks">
          <li className="flHead">Contact</li>
          <li>
            <a href="mailto:info@nellisauction.com">
              <ListItem button>
                info@nellisauction
                <wbr />
                .com
              </ListItem>
            </a>
          </li>
          <li>
            <a href="tel:702-531-1300">
              <ListItem button>(702) 531-1300</ListItem>
            </a>
          </li>
        </ul>
        <ul className="footLinks">
          <li className="flHead">Newsletter</li>
          <li className="noLink">Join our weekly mailing list</li>
          <li>
            <div className="footSubscribe">
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="input-group mb-3 flex-nowrap">
                  {Object.values(mapData(subscribe))}
                  <PrimaryButton label="SUBSCRIBE" type="submit" id="subsButton" />
                </div>
              </form>
            </div>
          </li>
        </ul>
      </div>
      <div className="footBottom customContainer d-flex justify-content-between align-items-center">
        <ul>
          <li>
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
        <ul>
          <li>
            <p>© Copyright {new Date().getFullYear()} - Nellis Auction | All rights reserved</p>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
