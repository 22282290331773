import { Button } from '@material-ui/core';
import React, { useState, useRef, useEffect, useContext } from 'react';
import './ProductCard.css';
import { useHistory } from 'react-router-dom';
import { currencyFormat, handleRedirectInternal } from '../../../common/components';
import AppointmentContext from '../../../context/appointment/appointmentContext';
import Popup from '../../../components/organisms/Popup';
import PrimaryButton from '../../../components/atoms/PrimaryButton';
import SecondaryButton from '../../../components/atoms/SecondaryButton';

const AppointmentItem = (props) => {
  const [product, setProduct] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const { cancelAppointmentItem } = useContext(AppointmentContext);
  const history = useHistory();
  const cancelBuynowId = useRef(0);
  const cancelAppointmentId = useRef(0);
  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);

  const toggleCancelItemModal = (buynowId, appointmentId) => {
    if (buynowId && appointmentId) {
      cancelBuynowId.current = buynowId;
      cancelAppointmentId.current = appointmentId;
      setModalOpen(true);
    }
  };
  const cancelItemHandler = () => {
    cancelAppointmentItem({
      buynowId: cancelBuynowId.current,
      appointmentId: cancelAppointmentId.current,
    });
    cancelBuynowId.current = 0;
    cancelAppointmentId.current = 0;
    setModalOpen(false);
    handleRedirectInternal(history, 'appointments');
  };
  return (
    <>
      {product ? (
        <>
          <div className="cartItem d-flex justify-content-between align-items-start">
            <div className="itemInfo d-flex justify-content-start">
              <div className="pclImg">
                <img
                  className="thumbnail"
                  onClick={props.toggleDrawer}
                  src={product.avatarorg}
                  alt={product.leadDescription}
                />
              </div>
              <div className="listContent">
                <h2 className="listProdTitle" onClick={props.toggleDrawer}>
                  {product.title}
                </h2>
              </div>
            </div>

            <div className="listActBtn">
              <div className="listBidInfo d-flex justify-content-between align-items-center">
                <p className="d-flex justify-content-center align-items-center">
                  {product.isReturn ? (
                    <span> Return </span>
                  ) : product.paid == 1 ? (
                    <>
                      <span>Paid </span>
                    </>
                  ) : (
                    <>
                      <span>{currencyFormat(product.amount)}</span>
                      <span className="listDivider">|</span>
                      <span>Unpaid </span>
                    </>
                  )}
                </p>
              </div>
              {product.cancelEligible === 1 ? (
                <div className="listBidInfo d-flex justify-content-between align-items-center mt-4">
                  <SecondaryButton
                    onClick={() => toggleCancelItemModal(product.buynowId, product.appointmentId)}
                    label="Cancel"
                    btnSize="small"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <Popup
            modaltitle="Cancel Confirmation"
            open={modalOpen}
            handleClose={() => setModalOpen(!modalOpen)}
          >
            <div>
              <div className="mb-3">
                <h4>
                  <b>Cancel this item?</b>
                </h4>
                <p>
                  The amount for this item will not be charged at the time of appointment if you
                  have completed the payment.
                </p>
              </div>
              <div className="w-25 m-auto">
                <PrimaryButton
                  className="input-group-prepend"
                  label="Yes, cancel"
                  onClick={() => cancelItemHandler(product)}
                />
              </div>
            </div>
          </Popup>
        </>
      ) : null}
    </>
  );
};
export default AppointmentItem;
