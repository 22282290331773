import {
  COMMON_VALUES,
  BID_HISTORY_VALUE,
  CLEAR_BID_HISTORY,
  US_STATE_VALUE,
  CLEAR_SEARCH,
  SEARCH_VALUE,
  GET_STATIC_PAGE,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  CURRENT_LOCATION,
  GET_BANNERS,
} from './commonTypes';

export default (state, action) => {
  switch (action.type) {
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case COMMON_VALUES:
      return {
        ...state,
        allCategory: action.payload.allCategory,
        allNotifications: action.payload.allNotifications,
        allLocations: action.payload.allLocations,
        allCondition: action.payload.allCondition,
        allDamageTypes: action.payload.allDamageTypes,
        allPackageTypes: action.payload.allPackageTypes,
        allBidIncrements: action.payload.allBidIncrements,
        bidIncrementDefault: action.payload.bidIncrementDefault,
      };
    case SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case BID_HISTORY_VALUE:
      return {
        ...state,
        bidHistoryValue: action.payload,
      };
    case CLEAR_BID_HISTORY:
      return {
        ...state,
        bidHistoryValue: 0,
      };
    case GET_STATIC_PAGE:
      return {
        ...state,
        static_page: action.payload,
      };

    case US_STATE_VALUE:
      return {
        ...state,
        USStates: action.payload,
      };
    case GET_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        searchValue: null,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    case CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    default:
      return state;
  }
};
