import React, { useState, useContext, useEffect, useRef } from 'react';
import './Appointment.css';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AppointmentItem from '../../custom/components/molecules/ProductCard/AppointmentItem';
import ProductView from '../../custom/components/organisms/ProductView';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AppointmentContext from '../../custom/context/appointment/appointmentContext';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import ProductContext from '../../custom/context/product/productContext';
import DashboardLayout from '../Dashboard/DashboardLayout';
import ReactToPdf from 'react-to-pdf';

import {
  dateFormatFront,
  dateTimeFormatFunction,
  currencyFormat,
  handleRedirectInternal,
  capitalize,
} from '../../custom/common/components';
import Drawer from '@material-ui/core/Drawer';
import { logo } from '../../utils/UI';

const Appointment = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const history = useHistory();

  const { getAppointmentDetails, appointmentDetails, responseStatus, clearResponse } = useContext(
    AppointmentContext
  );
  const { isAuthenticated } = authContext;
  const { setAlert } = alertContext;

  const [print, setPrint] = useState(false);
  const [search, setSearch] = useState({
    invoice_id: props.match.params.id,
  });
  const [drawer, setDrawer] = useState({
    right: false,
    bottom: false,
    data: {},
  });

  useEffect(() => {
    getAppointmentDetails({ id: props.match.params.id });
  }, []);

  const [balance, setBalance] = useState({
    total: 0,
    totalTax: 0,
    totalPremium: 0,
    grandTotal: 0,
  });
  useEffect(() => {
    if (appointmentDetails.records.length > 0) {
      let total = 0;
      let totalTax = 0;
      let totalPremium = 0;
      let grandTotal = 0;
      appointmentDetails.records.map((item) => {
        if (item.paid == 0) {
          total += item.amount;
          let taxAmt = item.amount * (item.tax_percent / 100);
          let premiumAmt = item.amount * (item.buyer_premium_percent / 100);
          totalTax += taxAmt;
          totalPremium += premiumAmt;
          grandTotal += item.amount + taxAmt + premiumAmt;
        }
      });

      setBalance({
        total,
        totalTax,
        totalPremium,
        grandTotal,
      });
    }
  }, [appointmentDetails]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'appointmentDetails' && responseStatus.status === 'error') {
        handleRedirectInternal(history, 'appointments');
        clearResponse();
      }
    }
  }, [responseStatus]);

  const ref = React.createRef();

  const toggleDrawer = (anchor, open, data) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      setDrawer({ ...drawer, [anchor]: open, data: data });
    } else {
      setDrawer({ ...drawer, [anchor]: open, data: {} });
    }
  };

  return (
    <DashboardLayout>
      <div className="container-lg nellisInvoice">
        <h3 className="invoiceTitle">Appointment Details</h3>
        <div
          className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
          ref={ref}
          style={{ height: '100%', width: '100%' }}
        >
          <div className="card-header d-flex justify-content-between align-items-center p-4">
            <a className="pt-2 d-inline-block" data-abc="true">
              <img src={logo} className="invLogo" alt="Nellis Logo" style={{ width: '140px' }} />
            </a>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-6 invAddr">
                <h5 className="mb-3">Appointment Location:</h5>
                <h3 className="text-dark mb-1">Nellis Auction</h3>
                <div>{appointmentDetails.generalInfo.address}</div>
                <div>
                  {appointmentDetails.generalInfo.city}, {appointmentDetails.generalInfo.state}{' '}
                  {appointmentDetails.generalInfo.zipCode}, United States
                </div>
                <div>Phone: +1 702-531-1300</div>
              </div>
            </div>

            <h4 className="invoiceSubTitle">
              Appointment scheduled at:{' '}
              {dateTimeFormatFunction(appointmentDetails.generalInfo.appointmentTime)}
            </h4>

            <h4 className="invoiceSubTitle mt-5">Product Details</h4>

            <div className="table-responsive">
              {appointmentDetails.records.length ? (
                <div className="cartProducts">
                  <div>
                    {appointmentDetails.records.map((data, index) => (
                      <React.Fragment key={index}>
                        {/* Product View Drawer */}
                        <Drawer
                          className="rightDrawer"
                          anchor={'right'}
                          open={drawer['right']}
                          onClose={toggleDrawer('right', false)}
                        >
                          <ProductView
                            data={drawer.data}
                            drawerHandler={toggleDrawer('right', false)}
                          />
                        </Drawer>
                        <AppointmentItem
                          data={data}
                          appointmentInfo={appointmentDetails.generalInfo}
                          from="invoice"
                          toggleDrawer={toggleDrawer('right', true, data)}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-clear nellisInvTot">
                    <tbody>
                      <tr>
                        <td className="left" align="right">
                          <span className="text-dark">Total</span>
                        </td>
                        <td className="right">
                          <span className="text-dark">{currencyFormat(balance.total)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="left" align="right">
                          <span className="text-dark">Tax</span>
                        </td>
                        <td className="right">
                          <span className="text-dark">{currencyFormat(balance.totalTax)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="left" align="right">
                          <span className="text-dark">Buyer Premium</span>
                        </td>
                        <td className="right">
                          <span className="text-dark">{currencyFormat(balance.totalPremium)}</span>
                        </td>
                      </tr>
                      <tr style={{ background: '#f3f3f3' }}>
                        <td className="left" align="right">
                          <strong className="text-dark">Grand Total Appointment Balance</strong>
                        </td>
                        <td className="right">
                          <strong className="text-dark">
                            {currencyFormat(balance.grandTotal)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="thankText">Thank you for shopping with us !</h4>
      </div>
    </DashboardLayout>
  );
};

export default Appointment;
