import React from 'react';

const getStarRatingIcons = (star) => {
  const score = Math.round(parseFloat(star) * 10);
  const starIcons = [];

  starIcons.push(
    <span key="star-00" style={{ marginRight: '5px', width: '25px' }}>
      {star}
    </span>
  );

  for (let i = 0; i < 50; i += 10) {
    const diff = score - i;

    // >= .9 gives a whole star, e.g 3.9 = 4star, 5 = 5star
    if (diff >= 9) {
      starIcons.push(
        <span key={`star-${i}`} className="material-icons starRating">
          star_rate
        </span>
      );
      // >= .4 gives a half star, e.g. 2.4 = 2.5star, 3.5 = 3.5star
    } else if (diff >= 4) {
      starIcons.push(
        <span key={`star-${i}`} className="material-icons starRating">
          star_half
        </span>
      );
      // < .4 is no star, e.g. 2.3 = 2star
    } else {
      starIcons.push(
        <span key={`star-${i}`} className="material-icons-outlined starRating">
          star_rate
        </span>
      );
    }
  }

  return starIcons;
};

export { getStarRatingIcons };
