import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect';
import { connectSortBy } from 'react-instantsearch-dom';
import { Button } from '@material-ui/core';
import { urlToSearchState } from '../../utils/Core';

const SortBy = ({ items, refine, className, button, defaultRefinement }) => {
  const location = useLocation();
  const searchState = urlToSearchState(location);
  const sortByState = searchState.sortBy;

  if (button) {
    return (
      <div className={className}>
        {items.map((opt, index) => (
          <Button
            key={index}
            className={opt.isRefined ? 'active' : null}
            onClick={(event) => {
              refine(opt.value);
            }}
          >
            {opt.show}
          </Button>
        ))}
      </div>
    );
  }
  return (
    <div className={className}>
      <CustomSelect
        label={'Sort By'}
        size={'small'}
        name={'sortBy'}
        shrink={items.length > 0}
        value={sortByState || defaultRefinement}
        onChange={(event) => {
          refine(event.target.value);
        }}
      >
        {items.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.show}
          </option>
        ))}
      </CustomSelect>
    </div>
  );
};

export default connectSortBy(SortBy);
