import React, { useState, useContext, useEffect } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import SecondaryButton from '../../product/components/atoms/SecondaryButton';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { mapData, handleRedirectInternal } from '../../product/common/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

const Login = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  const { login, responseStatus, clearResponse, isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      handleRedirectInternal(history, '');
    }
  }, [isAuthenticated]);

  let [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const validationArray = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required!'),
    facebook_id: Yup.string(),
    google_id: Yup.string(),
    password: Yup.string().when(['facebook_id', 'google_id'], {
      is: (facebookID, googleID) => !facebookID && !googleID,
      then: Yup.string().required('Required!'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      email: localStorage.email ? localStorage.email : '',
      password: localStorage.password ? localStorage.password : '',
      google_id: '',
      facebook_id: '',
      remember_me: localStorage.remember_me ? localStorage.remember_me : false,
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      if (values.remember_me) {
        localStorage.email = values.email;
        localStorage.password = values.password;
        localStorage.remember_me = values.remember_me;
      } else {
        delete localStorage.email;
        delete localStorage.password;
        delete localStorage.remember_me;
      }
      login(values);
    },
  });

  const rememberMe = [
    {
      label: 'Remember me',
      name: 'remember_me',
      type: 'checkbox',
      placeholder: 'Remember me',
      class: 'col-7',
      formik: formik,
    },
  ];

  const loginInfo = [
    {
      label: 'Email address',
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email address',
      class: 'col-12',
      autoFocus: true,
      formik: formik,
    },
    {
      label: 'Password',
      name: 'password',
      type: passwordShown ? 'text' : 'password',
      placeholder: 'Enter your password',
      class: 'col-12',
      formik: formik,
      endAdornment: passwordShown ? (
        <span class="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
          visibility_off
        </span>
      ) : (
        <span class="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
          visibility
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'login') {
        setAlert(responseStatus.message, responseStatus.status);
        clearResponse();
        if (responseStatus.status === 'success') {
          handleRedirectInternal(history, 'search');
        }
      }
    }
  }, [responseStatus]);

  const responseFacebook = (response) => {
    formik.values.email = response.email;
    formik.values.facebook_id = response.id;
    login(formik.values);
  };

  const responseGoogle = (response) => {
    formik.values.email = response.profileObj.email;
    formik.values.google_id = response.profileObj.googleId;
    login(formik.values);
  };

  return (
    <div className="login">
      <div className="container loginCnt">
        <div className="loginBox d-flex justify-content-center align-items-start">
          <div className="loginLt">
            <h1>LOGIN</h1>
            <div className="socialButtons d-flex justify-content-between align-items-center">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => (
                  <Button
                    variant="outlined"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    color="default"
                  >
                    <object
                      aria-label="Google Login"
                      data="/assets/svg/google.svg"
                      type="image/svg+xml"
                    ></object>
                    Login with Google
                  </Button>
                )}
              />
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button onClick={renderProps.onClick} variant="outlined" color="default">
                    <object
                      aria-label="Facebook Login"
                      data="/assets/svg/facebook.svg"
                      type="image/svg+xml"
                    ></object>
                    Login with Facebook
                  </Button>
                )}
              />
            </div>
            <p className="loginDivider">OR</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">{Object.values(mapData(loginInfo))}</div>
              <div className="row">
                {Object.values(mapData(rememberMe))}
                <div className="col-5">
                  <Link className="fpLink" to="/forgot_password">
                    Forgot Password ?
                  </Link>
                </div>
              </div>

              <PrimaryButton label="LOGIN" type="submit" btnSize="large" />
            </form>
          </div>
          <div className="loginRt">
            <div>
              <h1>
                New to <br /> Nellis Auction?
              </h1>
              <p>Sign up for a free account in no time</p>
            </div>
            <div className="regIcon">
              <object data="/assets/svg/regIcon.svg" type="image/svg+xml" />
            </div>
            <SecondaryButton
              onClick={() => handleRedirectInternal(history, 'registration')}
              label="Create an Account"
              btnSize="large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
