import axios from 'axios';

const apiCall = async (method, url, data, headertype, baseurl) => {
  let site_url = `${global.site_url}/api/custom/user/`;
  if (baseurl) {
    site_url = `${global.site_url}/api/custom/${baseurl}/`;
  }
  return new Promise((resolve, reject) => {
    let type = '';
    if (headertype && headertype === 'formdata') {
      type = 'multipart/form-data';
    } else {
      type = 'application/json';
    }
    const config = {
      headers: {
        'content-type': type,
      },
    };
    switch (method) {
      case 'post':
        axios
          .post(`${site_url}${url}`, data || {}, config)
          .then((res) => {
            if (res.data.data.sessionTimeOut) {
              localStorage.setItem('token', '');
              window.location.reload();
            }
            resolve(res);
          })
          .catch((error) => reject(error));
        break;
      case 'get':
        axios
          .get(`${site_url}${url}`, config)
          .then((res) => {
            if (res.data.data.sessionTimeOut) {
              localStorage.setItem('token', '');
              window.location.reload();
            }
            resolve(res);
          })
          .catch((error) => reject(error));
        break;
      default:
        return null;
    }
  });
};

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['authorization'];
  }
};

const setIPAddress = (ipaddress) => {
  if (ipaddress) {
    axios.defaults.headers.common['ipaddress'] = ipaddress;
  } else {
    delete axios.defaults.headers.common['ipaddress'];
  }
};

/**
 * Headers disallowed by CORS policy
 * @type {string[]}
 */
const temporaryHeaders = ['authorization', 'ipaddress'];

export { apiCall, setAuthToken, setIPAddress, temporaryHeaders };
