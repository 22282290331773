import { Button } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput';
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton';
import '../../molecules/ProductCard/ProductCard.css';
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton';
import { handleRedirectInternal, currencyFormat } from '../../../common/components';
import { useHistory } from 'react-router-dom';
import Timer from '../../../../product/common/timer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import BuyerContext from '../../../../custom/context/buyer/buyerContext';
import AuthContext from '../../../../custom/context/auth/authContext';
import CustomDialog from '../../organisms/CustomDialog';

const Bidding = (props) => {
  const buyerContext = useContext(BuyerContext);
  const authContext = useContext(AuthContext);
  let { bidConfirm } = buyerContext;
  const { isAuthenticated, user } = authContext;
  const [product, setProduct] = React.useState();
  const history = useHistory();

  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);

  const validationArray = Yup.object({
    wsprice: Yup.number()
      .min(product ? product.next_bid : 0, `Min. Bid $${product ? product.next_bid : 0}`)
      .required('Enter Bid Amount'),
  });
  const [toggleDialog, setToggleDialog] = useState(false);
  const [verifyEmailDialog, setVerifyEmailDialog] = useState(false);
  const [bidDisabledMsg, setBidDisabledMsg] = useState('');

  const formik = useFormik({
    initialValues: {
      wsprice: '',
      id: 0,
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      values.id = product.id || 0;
      if (user.status === 'not_bid') {
        setBidDisabledMsg(
          'Bidding from your account is currently disabled.  Please contact customer service at (702) 531-1300.'
        );
        setToggleDialog(false);
        setVerifyEmailDialog(true);
        return;
      }
      if (user.mail_verified == 0) {
        setBidDisabledMsg('Please verify your email.');
        setToggleDialog(false);
        setVerifyEmailDialog(true);
        return;
      }
      if (values.wsprice / product.next_bid > 10 && values.wsprice > product.retailPrice) {
        if (toggleDialog) {
          setToggleDialog(false);
          bidConfirm(values);
          values.wsprice = '';
        } else {
          setToggleDialog(true);
        }
      } else {
        bidConfirm(values);
        values.wsprice = '';
      }
    },
  });

  const changeDialogStatus = () => {
    setToggleDialog(!toggleDialog);
  };

  return (
    <>
      {product ? (
        <>
          {product.market_status === 'open' ? (
            <>
              <form onSubmit={formik.handleSubmit}>
                <div className="biddingCnt d-flex justify-content-between align-items-start">
                  <CustomInput
                    size="small"
                    label="Enter bid"
                    value={formik.values.wsprice}
                    name="wsprice"
                    inputProps={{
                      min: product.next_bid,
                    }}
                    onChange={formik.handleChange}
                    placeholder={`Min. Bid ` + currencyFormat(product.next_bid)}
                    min="0"
                    type="number"
                    error={formik.errors.wsprice}
                    helperText={
                      formik.errors.wsprice && formik.touched.wsprice && formik.errors.wsprice
                    }
                  />
                  {isAuthenticated ? (
                    product.bidtopstatus === 'winner' ? (
                      <SecondaryButton type="submit" label="Increase Bid" btnSize="small" />
                    ) : (
                      <PrimaryButton type="submit" label="Place Bid" btnSize="small" />
                    )
                  ) : (
                    <PrimaryButton
                      label="Login to Bid"
                      btnSize="small"
                      onClick={() => handleRedirectInternal(history, 'login')}
                    />
                  )}
                </div>

                <CustomDialog
                  title={'Bid Confirmation'}
                  open={toggleDialog}
                  function={changeDialogStatus}
                >
                  <h5>Are you sure you want to bid {currencyFormat(formik.values.wsprice)} ?</h5>
                  <div className="actionWrapper">
                    <PrimaryButton onClick={formik.handleSubmit} type="submit" label="Place Bid" />
                  </div>
                </CustomDialog>
                <CustomDialog
                  title={'Message'}
                  open={verifyEmailDialog}
                  function={() => setVerifyEmailDialog(false)}
                >
                  <h5>{bidDisabledMsg}</h5>
                  <div className="actionWrapper">
                    <PrimaryButton
                      onClick={() => setVerifyEmailDialog(false)}
                      type="submit"
                      label="Ok"
                    />
                  </div>
                </CustomDialog>
              </form>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Bidding;
