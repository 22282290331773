import React, { useState, useContext, useEffect, useRef } from 'react';
import ReceiptCard from '../../custom/components/molecules/ReceiptCard';
import DashboardLayout from './DashboardLayout';
import InvoiceContext from '../../custom/context/invoice/invoiceContext';
import { Pagination } from '@material-ui/lab';

function MyReceipts() {
  const { getAllReceipts, receipts } = useContext(InvoiceContext);
  const [search, setSearch] = useState({
    limit: 10,
    page: 1,
    orderby: 1,
    checkout: 1,
  });
  const initialLoad = useRef(true);
  const [view, setView] = useState(false);

  useEffect(() => {
    getAllReceipts(search);
  }, [search]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setView(true);
    }
  }, [receipts]);

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  return (
    <DashboardLayout>
      <div className="myInvoices">
        <div className="d-flex justify-content-between align-items-start w-100">
          <div>
            <h2 className="dashTitle">
              My Receipts
              <span>
                {receipts.totalRecords ? <h6>{receipts.totalRecords} Receipt(s)</h6> : null}
              </span>
            </h2>
          </div>
        </div>
        {view ? (
          receipts.records.length ? (
            <div className="searchResults d-flex justify-content-start flex-wrap">
              {receipts.records.map((data, index) => (
                <ReceiptCard key={index} data={data} type="invoice" />
              ))}
              <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                <h6>
                  Showing {receipts.setDisp} of {receipts.totalRecords}
                </h6>
                <Pagination
                  count={Math.ceil(receipts.totalRecords / search.limit)}
                  page={search.page}
                  onChange={onHandlePage}
                  siblingCount={3}
                  showFirstButton
                  showLastButton
                  boundaryCount={2}
                />
              </div>
            </div>
          ) : (
            <div className="np-pro-box">
              <img src="/assets/svg/noResults.svg" alt="No results image" />
              <h5>No Receipts Found!</h5>
            </div>
          )
        ) : null}
      </div>
    </DashboardLayout>
  );
}

export default MyReceipts;
