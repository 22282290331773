import {
  GET_STATIC_PAGE,
  CLEAR_RESPONSE,
  RESPONSE_STATUS,
  DECRYPT_USER_DETAILS,
} from './userTypes';

export default (state, action) => {
  switch (action.type) {
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case GET_STATIC_PAGE:
      return {
        ...state,
        static_page: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    case DECRYPT_USER_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};
