import React, { useState } from 'react';
import { Button, Collapse } from '@material-ui/core';
import ConnectedRefinementCheckbox from './connected-refinement-checkbox';

const ExtraFilters = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button size={'small'} onClick={() => setOpen(!open)}>
        {open ? 'Less Filters' : 'More Filters'}
      </Button>
      <Collapse in={open}>
        <ConnectedRefinementCheckbox
          attribute={'Is Functional'}
          title={'Is Functional'}
          isExpanded={false}
        />
        <ConnectedRefinementCheckbox
          attribute={'Assembly Required'}
          title={'Assembly Required'}
          isExpanded={false}
        />
        <ConnectedRefinementCheckbox
          attribute={'Is Damaged'}
          title={'Is Damaged'}
          isExpanded={false}
        />
        <ConnectedRefinementCheckbox
          attribute={'Missing Parts'}
          title={'Is Missing Parts'}
          isExpanded={false}
        />
        <ConnectedRefinementCheckbox
          attribute={'In Package'}
          title={'In Original Packaging'}
          isExpanded={false}
        />
        <ConnectedRefinementCheckbox
          attribute={'Item Condition'}
          title={'Condition of Item'}
          isExpanded={false}
        />
      </Collapse>
    </>
  );
};

export default ExtraFilters;
