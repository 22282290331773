import {
  GET_ALL_SEARCH,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_AVAILABLE_APPOINT,
  GET_SINGLE_CART,
} from './cartTypes';
export default (state, action) => {
  switch (action.type) {
    case GET_ALL_SEARCH:
      return {
        ...state,
        search_allproducts: action.payload,
      };
    case GET_SINGLE_CART:
      return {
        ...state,
        search_singlecart: action.payload,
      };
    case GET_AVAILABLE_APPOINT:
      return {
        ...state,
        available_appointments: action.payload,
      };

    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    default:
      return state;
  }
};
