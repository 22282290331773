import React, { useMemo, useReducer } from 'react';
import ProductContext from './productContext';
import ProductReducer from './productReducer';
import { apiCall } from '../../../custom/common/api';
import { response } from '../../../custom/context/common';
import {
  GET_ALL_INVOICE,
  GET_ALL_SEARCH,
  GET_ALL_SIMILAR,
  GET_ALL_BID_HISTORY,
  GET_ALL_ACTIVE_SORTS,
  GET_ALL_DASHBOARD,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_ALL_USER_INVOICES,
  GET_ALL_USER_RETURNS,
  GET_CART_ITEMS,
  GET_ALL_RETURN_INVOICE,
  GET_SAVED_SEARCHES,
  ALGOLIA_PRODUCT_HITS,
} from './productTypes';

import { connectHits } from 'react-instantsearch-dom';

const ProductState = ({ children, hits }) => {
  const initialState = {
    algolia_producthits: {
      records: [],
      totalRecords: 0,
    },
    search_alldashboardproducts: {
      records: [],
      totalRecords: 0,
      setDisp: '',
      sorts: {},
    },
    active_sorts: {
      sorts: {},
    },
    search_allsimilar: {
      records: [],
      totalRecords: 0,
      setDisp: '',
    },
    search_allproducts: {
      records: [],
      totalRecords: 0,
      setDisp: '',
      sorts: {},
    },
    search_allbidhistory: {
      records: [],
      totalRecords: 0,
      setDisp: '',
      sorts: {},
    },
    search_allinvoiceproducts: {
      invoicerecords: [],
      transactionrecords: [],
      appointmentrecord: {},
      locationrecord: {},
      cartvalues: {},
    },
    search_allreturninvoiceproducts: {
      invoicerecords: [],
      transactionrecords: [],
      appointmentrecord: {},
      locationrecord: {},
      cartvalues: {},
    },
    search_alluserinvoices: {
      records: [],
      totalRecords: 0,
      setDisp: '',
    },
    search_alluserreturns: {
      records: [],
      totalRecords: 0,
      setDisp: '',
    },
    cart_items: [],
    saved_searches: {
      records: [],
      totalRecords: 0,
    },
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  let resp = new response(dispatch, RESPONSE_STATUS);

  const getAllDashboardProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'dashboard', formData, '', 'product')]);
      const from = 'search';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_DASHBOARD,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
            sorts: res.data.data.responseData.sorts,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllInvoiceProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'product')]);
      const from = 'invoicesearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_INVOICE,
          payload: {
            invoicerecords: res.data.data.responseData.invoiceItems.length
              ? res.data.data.responseData.invoiceItems
              : [],
            transactionrecords: res.data.data.responseData.allTransactions.length
              ? res.data.data.responseData.allTransactions
              : [],
            appointmentrecord: res.data.data.responseData.appointmentDetail,
            locationrecord: res.data.data.responseData.locationDetail,
            cartvalues: res.data.data.responseData.cartValues,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllReturnInvoiceProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'returninvoice', formData, '', 'product')]);
      const from = 'invoicesearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_RETURN_INVOICE,
          payload: {
            invoicerecords: res.data.data.responseData.invoiceItems.length
              ? res.data.data.responseData.invoiceItems
              : [],
            transactionrecords: res.data.data.responseData.allTransactions.length
              ? res.data.data.responseData.allTransactions
              : [],
            appointmentrecord: res.data.data.responseData.appointmentDetail,
            locationrecord: res.data.data.responseData.locationDetail,
            cartvalues: res.data.data.responseData.cartValues,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllUserInvoices = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'allinvoices', formData, '', 'product')]);
      const from = 'allinvoices';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_USER_INVOICES,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const getAllUserReturns = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'allreturns', formData, '', 'product')]);
      const from = 'allreturns';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_USER_RETURNS,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const fetchProductDetailsForHits = async (hits) => {
    const projectIds = hits.map((h) => h.objectID).filter((x) => !!x);

    if (projectIds.length === 0) {
      return;
    }

    try {
      const res = await apiCall('post', 'fetchByIds', { projectIds }, '', 'product');
      const from = 'fetchByIds';
      if (res.data.status === 'success') {
        const { records } = res.data.data.responseData;
        const productIndex = records.reduce(
          (result, record) => ({ ...result, [record.id]: record }),
          {}
        );
        const hitIndex = hits.reduce(
          (result, hit) => ({ ...result, [parseInt(hit.objectID, 10)]: hit }),
          {}
        );
        const result = projectIds.map((id) => ({ ...hitIndex[id], ...productIndex[id] }));

        dispatch({
          type: ALGOLIA_PRODUCT_HITS,
          payload: {
            records: result,
            totalRecords: result.length,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllSearchProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'product')]);
      const from = 'search';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_SEARCH,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
            sorts: res.data.data.responseData.sorts,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllActiveSorts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'activeSorts', formData, '', 'product')]);
      const from = 'search';
      if (res.data.status === 'success') {
        const respData = res.data.data.responseData.sorts;
        dispatch({
          type: GET_ALL_ACTIVE_SORTS,
          payload: {
            sorts: {
              totalactive: respData.totalactive,
              categorysort: respData.sortcategoryTypes,
              conditionsort: respData.sortconditionTypes,
              damageTypesort: respData.sortdamageTypes,
              packageTypesort: respData.sortpackageTypes,
              locationsort: respData.sortlocations,
              starRatingFilter: respData.sortitems,
            },
            minPrice: res.data.data.responseData.minPrice,
            maxPrice: res.data.data.responseData.maxPrice,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllSimilarProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'product')]);
      const from = 'similar';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_SIMILAR,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addWatchlist = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'addWatchlist', formData, '', 'user')]);
      resp.commonResponse(res.data, 'watchlist');
    } catch (err) {
      resp.commonErrorResponse('watchlist');
    }
  };

  const removeWatchlist = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'removeWatchlist', formData, '', 'user')]);
      resp.commonResponse(res.data, 'watchlist');
    } catch (err) {
      resp.commonErrorResponse('watchlist');
    }
  };

  const getAllBidHistory = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'bidhistory', formData, '', 'product')]);
      const from = 'search';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_ALL_BID_HISTORY,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
            sorts: res.data.data.responseData.sorts,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getCartItems = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('get', 'getCartItems', formData, '', 'product')]);
      const from = 'search';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_CART_ITEMS,
          payload: {
            items: res.data.data.items,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addSavedSearch = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'addSavedSearch', formData, '', 'user')]);
      resp.commonResponse(res.data, 'saved_search_msg');
    } catch (err) {
      resp.commonErrorResponse('saved_search_msg');
    }
  };

  const getSavedSearch = async (formData, hideAlert) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'getSavedSearch', formData, '', 'user')]);
      if (hideAlert) {
        dispatch({
          type: GET_SAVED_SEARCHES,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords,
            setDisp: res.data.data.responseData.setDisp,
          },
        });
      } else {
        resp.commonResponse(res.data, 'saved_search');
      }
    } catch (err) {
      resp.commonErrorResponse('saved_search');
    }
  };

  const removeSavedSearch = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'removeSavedSearch', formData, '', 'user')]);
      resp.commonResponse(res.data, 'saved_search_msg');
    } catch (err) {
      resp.commonErrorResponse('saved_search_msg');
    }
  };

  const cancelItem = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'product')]);
      console.log('RESPONSE STATUS: ', res.data);
      if (res.data.status === 'success') {
        resp.commonResponse(res.data, 'cart');
      } else {
        resp.commonErrorResponse('cart');
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const checkoutCart = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'authorize', formData, '', 'payment')]);
      resp.commonResponse(res.data, 'payment');
    } catch (err) {
      resp.commonErrorResponse('payment');
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  const refreshAlgoliaProductDetails = () => fetchProductDetailsForHits(hits);

  React.useEffect(() => {
    fetchProductDetailsForHits(hits).then(() => {});
  }, [hits]);

  return (
    <ProductContext.Provider
      value={{
        algolia_producthits: state.algolia_producthits,
        search_allproducts: state.search_allproducts,
        search_alldashboardproducts: state.search_alldashboardproducts,
        search_allreturninvoiceproducts: state.search_allreturninvoiceproducts,
        search_allinvoiceproducts: state.search_allinvoiceproducts,
        search_alluserinvoices: state.search_alluserinvoices,
        search_alluserreturns: state.search_alluserreturns,
        active_sorts: state.active_sorts,
        search_allbidhistory: state.search_allbidhistory,
        search_allsimilar: state.search_allsimilar,
        cart_items: state.cart_items,
        saved_searches: state.saved_searches,
        responseStatus: state.responseStatus,
        clearResponse,
        getAllDashboardProducts,
        getAllSearchProducts,
        getAllInvoiceProducts,
        getAllReturnInvoiceProducts,
        getAllUserInvoices,
        getAllUserReturns,
        getAllSimilarProducts,
        addWatchlist,
        removeWatchlist,
        getAllActiveSorts,
        getAllBidHistory,
        getCartItems,
        cancelItem,
        addSavedSearch,
        getSavedSearch,
        removeSavedSearch,
        checkoutCart,
        refreshAlgoliaProductDetails,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default connectHits(ProductState);
