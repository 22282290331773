import React from 'react';
import ListViewSkeleton from '../../custom/components/molecules/ProductCard/ProductCardSkeletons/ListViewSkeleton';
import Skeletons from '../../custom/components/Skeletons';

function CheckoutSkeleton() {
  return (
    <div className="checkoutSkeletonBody">
      <Skeletons type="title" />
      <div className="csbItem">
        <div>
          <ListViewSkeleton />
          <ListViewSkeleton />
        </div>
        <div>
          <Skeletons type="title" />
          <Skeletons type="title" />
          <Skeletons type="actionButton" />
        </div>
      </div>
    </div>
  );
}

export default CheckoutSkeleton;
