import React from 'react';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import './ErrorPages.css';
import { handleRedirectInternal } from '../../product/common/components';
import { useHistory } from 'react-router-dom';

const Error404 = () => {
  const history = useHistory();

  return (
    <div className="errorPages">
      <lottie-player
        src="https://assets4.lottiefiles.com/packages/lf20_vopx7vkh.json"
        background="transparent"
        speed="1"
        class="error404"
        loop
        // controls
        autoplay
      ></lottie-player>
      <h2>Error 404 - Page not found.</h2>
      <p>Sorry, Our dog has eaten the page you were looking for.</p>
      <p>
        You can still try exploring thousands of other products or stay back and see how sorry our
        dog is.
      </p>
      <PrimaryButton
        label="Browse Products"
        onClick={() => handleRedirectInternal(history, 'search')}
      />
    </div>
  );
};

export default Error404;
