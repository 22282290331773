const styles = () => ({
  productDrawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      width: '100vm',
      maxWidth: '100vm',
      padding: '20px',
      maxHeight: '90vh',
    },

    '& .MuiDrawer-paperAnchorRight': {
      padding: '20px',
      width: '50vmax',
      maxWidth: '800px',
    },
  },
});

export default styles;
