import React, { useState, useContext, useEffect, useRef } from 'react';
import InvoiceCard from '../../custom/components/molecules/InvoiceCard';
import DashboardLayout from './DashboardLayout';
import ProductContext from '../../custom/context/product/productContext';
import { Pagination } from '@material-ui/lab';

function MyInvoices() {
  const productContext = useContext(ProductContext);

  const { search_alluserreturns, getAllUserReturns } = productContext;

  let [viewProduct, setViewProduct] = useState([]);

  const [search, setSearch] = useState({
    limit: 10,
    page: 1,
    orderby: 1,
    checkout: 1,
  });

  useEffect(() => {
    getAllUserReturns(search);
  }, [search]);

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    setViewProduct(search_alluserreturns.records.length ? search_alluserreturns.records : []);
  }, [search_alluserreturns]);

  useEffect(() => {
    setViewProduct([]);
    return () => {
      setViewProduct([]);
    };
  }, []);

  return (
    <DashboardLayout>
      <div className="myInvoices">
        <div className="d-flex justify-content-between align-items-start w-100">
          <div>
            <h2 className="dashTitle">
              My Returns
              <span>
                {viewProduct.length ? (
                  <h6>{search_alluserreturns.totalRecords} Invoice(s)</h6>
                ) : null}
              </span>
            </h2>
          </div>
        </div>

        {viewProduct.length ? (
          <div className="searchResults d-flex justify-content-start flex-wrap">
            {viewProduct.map((data, index) => (
              <>
                <InvoiceCard data={data} type="return" />
              </>
            ))}
            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
              <h6>
                Showing {search_alluserreturns.setDisp} of {search_alluserreturns.totalRecords}
              </h6>
              <Pagination
                count={Math.ceil(search_alluserreturns.totalRecords / search.limit)}
                page={search.page}
                onChange={onHandlePage}
                siblingCount={3}
                showFirstButton
                showLastButton
                boundaryCount={2}
              />
            </div>
          </div>
        ) : (
          <div className="np-pro-box"> No Records Found! </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default MyInvoices;
