import React, { useState, useContext, useEffect, useRef } from 'react';
import './Dashboard.css';
import DashboardLayout from './DashboardLayout';
import CheckBox from '../../product/components/atoms/CheckBox';
import { Button } from '@material-ui/core';
import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import CustomDialog from '../../custom/components/organisms/Dialog';
import ProductContext from '../../custom/context/product/productContext';
import { handleRedirectInternal, dateFormatFront } from '../../product/common/components';
import CommonContext from '../../custom/context/common/commonContext';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

function SavedSearch() {
  const productContext = useContext(ProductContext);
  const commonContext = useContext(CommonContext);
  const history = useHistory();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(15);
  const [record, setRecord] = useState([]);

  const { responseStatus, getSavedSearch, removeSavedSearch, saved_searches } = productContext;
  const { setSearchValue } = commonContext;

  const loadTable = (page = currentPage) => {
    getSavedSearch({ page: page, limit: limit }, true);
  };

  const removeRecord = (id) => {
    removeSavedSearch({ id });
  };

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'saved_search_msg' && responseStatus.data.status === 'removed') {
        loadTable();
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    if (saved_searches.records.length > 0) {
      setRecord(saved_searches.records);
      setTotalPage(saved_searches.totalRecords);
    } else {
      setRecord([]);
      setTotalPage(0);
    }
  }, [saved_searches]);

  // SEARCH
  const getSearchBarObject = (value) => {
    setSearchValue({
      filters: {
        searchbar: {
          value: value,
        },
      },
    });
    handleRedirectInternal(history, 'search');
  };

  const onHandlePage = async (event, value) => {
    setCurrentPage(value);
    loadTable(value);
  };

  const changeDialogStatus = () => {
    setToggleDialog(!toggleDialog);
  };
  return (
    <DashboardLayout title="My Saved Searches">
      <div className="dashboardInner mySavedSearches">
        <div className="table-responsive dpTable">
          <h2 className="dashTitle">Saved Search</h2>
          {record.length ? (
            <>
              <table className="table text-left">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Date Added</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {record.map((data, index) => (
                    <tr key={`saved_${index}`}>
                      <td>{data.search_text}</td>
                      <td>{data.city}</td>
                      <td>{data.state}</td>
                      <td>{dateFormatFront(data.created_at)}</td>
                      <td>
                        <div className="d-flex justify-content-center ssActBtn">
                          <Button onClick={() => getSearchBarObject(data.search_text)}>
                            <span className="material-icons">open_in_new</span>
                          </Button>
                          <Button onClick={() => removeRecord(data.id)}>
                            <span className="material-icons">delete</span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                count={Math.ceil(totalPage / limit)}
                page={currentPage}
                onChange={onHandlePage}
                siblingCount={3}
                showFirstButton
                showLastButton
                boundaryCount={2}
              />
            </>
          ) : (
            <>
              <img
                src="./assets/svg/noResults.svg"
                alt="No results image"
                style={{ width: '180px' }}
              />
              <div className="mt-4">
                <h6>No Saved Seach Found!</h6>
                <div className="w-25 mx-auto mt-4">
                  <PrimaryButton
                    label="Browse Products"
                    onClick={() => handleRedirectInternal(history, 'search')}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CustomDialog title={'Confirm Delete'} open={toggleDialog} function={changeDialogStatus}>
        <h5>You are about to delete this saved search!</h5>
        <h5>Please confirm if you want to continue with this action.</h5>
        <div className="actionWrapper">
          <Button onClick={() => setToggleDialog(false)}>No, Cancel</Button>
          <PrimaryButton type="submit" label="Yes, Delete" />
        </div>
      </CustomDialog>
    </DashboardLayout>
  );
}

export default SavedSearch;
