import { connectCurrentRefinements } from 'react-instantsearch-dom';
import React from 'react';
import { Button } from '@material-ui/core';

const ClearRefinements = ({ items, refine }) => (
  <Button
    disabled={!items.length}
    size={'small'}
    onClick={() => refine(items)}
    variant={'text'}
    color={'primary'}
  >
    Clear All Filters
  </Button>
);

export default connectCurrentRefinements(ClearRefinements);
