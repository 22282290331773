import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useLocation, Link } from 'react-router-dom';

import './Registration.css';

import PrimaryButton from '../../product/components/atoms/PrimaryButton';
import { mapData, handleRedirectInternal } from '../../product/common/components';
import PhoneValidation from './phoneValidation';

import AuthContext from '../../custom/context/auth/authContext';
import AlertContext from '../../custom/context/alert/alertContext';
import CommonContext from '../../custom/context/common/commonContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginTop: '10px',
    width: '100%',
  },
}));

const getSteps = () => {
  return ['Basic Information', 'Communication Information'];
};

const Registration = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { USStates } = commonContext;

  const [phoneVerify, setPhoneVerify] = React.useState(false);

  const changePhoneVerify = () => {
    setPhoneVerify(!phoneVerify);
  };

  const { setAlert } = alertContext;
  const { register, checkValidation, responseStatus, clearResponse, isAuthenticated } = authContext;

  const loaded = React.useRef(false);

  // Loading Google Place API Script
  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (isAuthenticated) {
      handleRedirectInternal(history, '');
    }
  }, [isAuthenticated]);

  const validationArray = [
    Yup.object({
      email: Yup.string().email('Invalid email format').required('Required!'),
      confirm_email: Yup.string()
        .email('Invalid email format')
        .oneOf([Yup.ref('email')], "Email's not match")
        .required('Required!'),
      facebook_id: Yup.string(),
      google_id: Yup.string(),
      password: Yup.string().when(['facebook_id', 'google_id'], {
        is: (facebookID, googleID) => !facebookID && !googleID,
        then: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
      }),
      password_checker: Yup.number().when(['facebook_id', 'google_id'], {
        is: (facebookID, googleID) => !facebookID && !googleID,
        then: Yup.number().moreThan(1, 'Password has to be Fair'),
      }),
      confirm_password: Yup.string().when(['facebook_id', 'google_id'], {
        is: (facebookID, googleID) => !facebookID && !googleID,
        then: Yup.string()
          .oneOf([Yup.ref('password')], "Passwords don't match")
          .required('Required!'),
      }),
    }),
    Yup.object({
      first_name: Yup.string()
        .min(2, 'Mininum 2 characters')
        .max(15, 'Maximum 15 characters')
        .required('Required!'),
      last_name: Yup.string()
        .min(2, 'Mininum 2 characters')
        .max(15, 'Maximum 15 characters')
        .required('Required!'),
      address1: Yup.string().required('Required!'),
      phone: Yup.string()
        .test('isValid', 'Invalid Phone Number', (value) => {
          return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
        })
        .required('Required!'),
      city: Yup.string().required('Required!'),
      state: Yup.string().required('Required!'),
      zip: Yup.string()
        .min(4, 'Invalid Zip Code')
        .max(8, 'Invalid Zip Code')
        .test('isValidZip', 'Invalid Zip Code', (value) => {
          return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
        })
        .required('Required!'),
    }),
  ];

  const formik = useFormik({
    initialValues: {
      email: '',
      confirm_email: '',
      password: '',
      confirm_password: '',
      password_checker: 0,
      google_id: '',
      facebook_id: '',
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      phone: '',
      city: '',
      state: '',
      zip: '',
      phone_verified: 0,
      verified_phonenum: '',
      phone_verifysent: 0,
      verify_code: '',
      kount_session_id:
        window.kount_session_id && window.kount_session_id.MercSessId
          ? window.kount_session_id.MercSessId
          : '',
    },
    validationSchema: validationArray[activeStep],
    onSubmit: (values) => {
      if (activeStep === 0) {
        checkValidation({
          text: values.email,
          type: 'email',
        });
      } else {
        if (
          values.phone_verified === 1 &&
          formik.values.verified_phonenum === formik.values.phone
        ) {
          values.phone = values.phone.replace(/[^+0-9]/g, '');
          register(values, urlSearchParams);
        } else {
          setPhoneVerify(true);
        }
      }
    },
  });
  const [confPassDisabled, setConfPassDisabled] = useState(true);
  const basicInfo = [
    {
      label: 'Email address',
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email address',
      class: 'col-12',
      autoFocus: true,
      formik: formik,
      autoComplete: 'off',
    },
    {
      label: 'Confirm email address',
      name: 'confirm_email',
      type: 'email',
      placeholder: 'Re-enter your email address',
      class: 'col-12',
      formik: formik,
      autoComplete: 'off',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'Enter your password',
      class: 'col-12',
      formik: formik,
      inputStyle: { marginBottom: '10px' },
      autoComplete: 'off',
    },
    {
      name: 'password_checker',
      type: 'password_checker',
      formik: formik,
      class: 'col-12',
      hideMessage: true,
    },
    {
      label: 'Confirm password',
      name: 'confirm_password',
      type: 'password',
      placeholder: 'Re-enter your password',
      class: 'col-12',
      formik: formik,
      disabled: confPassDisabled,
      autoComplete: 'off',
    },
  ];

  const communicationInfo = [
    {
      label: 'First name',
      type: 'text',
      placeholder: 'Enter your first name',
      class: 'col-12',
      name: 'first_name',
      formik: formik,
    },
    {
      label: 'Last name',
      placeholder: 'Enter your last name',
      class: 'col-12',
      type: 'text',
      name: 'last_name',
      formik: formik,
    },
    {
      label: 'Address 1',
      placeholder: 'Enter your address',
      class: 'col-12',
      type: 'google_place_autocomplete',
      googlePlaceType: 'address',
      name: 'address1',
      formik: formik,
    },
    {
      label: 'Address 2',
      placeholder: 'Apt #, Unit# etc',
      class: 'col-12',
      type: 'text',
      name: 'address2',
      formik: formik,
    },
    {
      label: 'Phone number',
      placeholder: 'Enter your phone number',
      class: 'col-12',
      type: 'text',
      name: 'phone',
      formik: formik,
      countryCodeEditable: false,
    },
    {
      label: 'City',
      placeholder: 'Enter your city',
      class: 'col-12',
      type: 'google_place_autocomplete',
      name: 'city',
      googlePlaceType: '(cities)',
      formik: formik,
    },
    {
      label: 'State',
      placeholder: 'Enter your state',
      class: 'col-12',
      type: 'select',
      options: USStates,
      name: 'state',
      formik: formik,
    },
    {
      label: 'ZIP',
      placeholder: 'Enter your ZIP code',
      class: 'col-6',
      type: 'google_place_autocomplete',
      googlePlaceType: '(regions)',
      name: 'zip',
      formik: formik,
    },
  ];
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return Object.values(mapData(basicInfo));
      case 1:
        return Object.values(mapData(communicationInfo));
      default:
        return 'Unknown stepIndex';
    }
  };

  useEffect(() => {
    if (formik.errors['password_checker']) {
      if (formik.values.password && formik.values.password.length < 8) {
        formik.setFieldError('password', formik.errors['password']);
      } else {
        formik.setFieldError('password', formik.errors['password_checker']);
      }
    }
  }, [formik.errors['password'], formik.errors['password_checker']]);

  useEffect(() => {
    // this indicates the password strength is at least "Fair"
    if (formik.values.password_checker >= 2) {
      setConfPassDisabled(false);
    } else {
      setConfPassDisabled(true);
    }
  }, [formik.values.password_checker]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 200);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 200);
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.from === 'register') {
        setAlert(responseStatus.message, responseStatus.status);
        clearResponse();
        if (responseStatus.status === 'success') {
          handleRedirectInternal(history, 'registration/success');
        }
      } else if (responseStatus.from === 'checkValidation') {
        if (responseStatus.status !== 'success') {
          setAlert(responseStatus.message, responseStatus.status);
        } else {
          formik.touched.last_name = false;
          formik.touched.address1 = false;
          formik.touched.phone = false;
          formik.touched.city = false;
          formik.touched.state = false;
          formik.touched.zip = false;
          setActiveStep(1);
          window.scrollTo(0, 200);
        }
        clearResponse();
      }
    }
  }, [responseStatus]);

  const responseFacebook = (response) => {
    formik.values.facebook_id = response.id;
    formik.values.email = response.email;
    formik.values.confirm_email = response.email;
    let user_name = response.name.split(' ');
    if (user_name.length > 1) {
      formik.values.first_name = user_name[0];
      formik.values.last_name = user_name[1];
    } else {
      formik.values.first_name = response.name;
    }
    formik.values.password = '';
    formik.values.confirm_password = '';

    checkValidation({
      text: response.email,
      type: 'email',
    });
  };

  const responseGoogle = (response) => {
    formik.values.google_id = response.profileObj.googleId;
    formik.values.email = response.profileObj.email;
    formik.values.confirm_email = response.profileObj.email;
    formik.values.first_name = response.profileObj.givenName;
    formik.values.last_name = response.profileObj.familyName ? response.profileObj.familyName : '';
    formik.values.password = '';
    formik.values.confirm_password = '';
    checkValidation({
      text: response.profileObj.email,
      type: 'email',
    });
  };

  return (
    <div className="registration">
      <div className="regBox container">
        <h1>Welcome to Nellis Auction</h1>
        {/* <h2>The Fastest Growing Online Auction Site In North America.</h2> */}
        {activeStep === 0 ? (
          <>
            <div className="socialButtons d-flex justify-content-between align-items-center">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => (
                  <Button
                    variant="outlined"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    color="default"
                  >
                    <object
                      aria-label="Google Signin"
                      data="/assets/svg/google.svg"
                      type="image/svg+xml"
                    ></object>
                    Sign up with Google
                  </Button>
                )}
              />

              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button variant="outlined" onClick={renderProps.onClick} color="default">
                    <object
                      aria-label="Facebook Signin"
                      data="/assets/svg/facebook.svg"
                      type="image/svg+xml"
                    ></object>
                    Sign up with Facebook
                  </Button>
                )}
              />
            </div>
            <p className="registrationDivider"></p>
          </>
        ) : null}

        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div className="stepCompletion">
                <h3>You have succesfully registered !</h3>
                <p>You can now browse through thousands of unique products.</p>
                <object
                  aria-label="Facebook Signin"
                  data="/assets/svg/successfulReg.svg"
                  type="image/svg+xml"
                />
                <PrimaryButton
                  label="Browse Products"
                  onClick={() => handleRedirectInternal(history, 'search')}
                />
              </div>
            ) : (
              <div>
                <form onSubmit={formik.handleSubmit} autocomplete="nofill">
                  {formik.values.facebook_id || formik.values.google_id ? (
                    <>
                      <div className="row">{getStepContent(activeStep)}</div>
                      <div>
                        <PrimaryButton btnSize="large" type="submit" label={'Finish'} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">{getStepContent(activeStep)}</div>
                      <div>
                        {activeStep === steps.length - 1 ? (
                          <PrimaryButton btnSize="large" type="submit" label={'Finish'} />
                        ) : (
                          <PrimaryButton btnSize="large" type="submit" label={'Next'} />
                        )}
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            )}
          </div>
          <PhoneValidation
            phoneVerify={phoneVerify}
            formik={formik}
            setPhoneVerify={setPhoneVerify}
            changePhoneVerify={changePhoneVerify}
          />
        </div>
      </div>
    </div>
  );
};

export default Registration;
