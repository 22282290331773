import React, { useReducer } from 'react';
import BuyerContext from './buyerContext';
import BuyerReducer from './buyerReducer';
import { apiCall } from '../../common/api';
import { response } from '../common';

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './buyerTypes';

const BuyerState = (props) => {
  const initialState = {
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(BuyerReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  // Bid Confirm
  const bidConfirm = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'confirm', formData, '', 'bid')]);
    } catch (err) {
      resp.commonErrorResponse('bid');
    }
  };

  // Clear Response
  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <BuyerContext.Provider
      value={{
        responseStatus: state.responseStatus,
        clearResponse,
        bidConfirm,
      }}
    >
      {props.children}
    </BuyerContext.Provider>
  );
};

export default BuyerState;
