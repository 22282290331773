import { colors } from '../../../../utils/UI/theme';

const styles = (theme) => ({
  // For Ratings Menu
  ratingMenu: {
    '& .ais-RatingMenu-list': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      alignItems: 'flex-start',

      '& .ais-RatingMenu-item': {
        margin: '5px 0px',

        '& a': {
          '& svg': {
            fill: colors.starColor,
          },
        },

        '&.ais-RatingMenu-item--selected': {
          '& a': {
            '& svg': {
              fill: theme.palette.primary.main,
            },
          },
        },

        '&.ais-RatingMenu-item--disabled': {
          '& a': {
            '& svg, & .ais-RatingMenu-count': {
              opacity: 0.5,
            },
          },
        },

        '& .ais-RatingMenu-link': {
          color: theme.palette.primary.main,

          '&:hover': {
            textDecoration: 'none',
          },

          '& .ais-RatingMenu-count::before': {
            content: '"("',
          },

          '& .ais-RatingMenu-count': {
            fontSize: '12px',
          },

          '& .ais-RatingMenu-count::after': {
            content: '" items)"',
          },
        },
      },
    },
  },

  //  For accordion summary
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  skeletonCircle: {
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.primColor}`,
  },
});

export default styles;
