import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './utils/UI/utils.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN__BUYER,
  integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

if (navigator.serviceWorker) {
  // Uninstall all previously installed service workers
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration
        .unregister()
        .then(() => {
          console.log('Service worker removal succeeded:', registration.scope);
        })
        .catch((error) => {
          console.error('Service worker removal failed:', registration.scope);
          console.error(error);
        });
    });
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
