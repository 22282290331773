import {
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_MY_APPOINTMENTS,
  GET_AVAILABLE_APPOINTMENT,
  GET_APPOINTMENT_DETAILS,
} from './appointmentTypes';
export default (state, action) => {
  switch (action.type) {
    case GET_AVAILABLE_APPOINTMENT:
      return {
        ...state,
        available_appointments: action.payload,
      };

    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case GET_MY_APPOINTMENTS:
      return {
        ...state,
        myAppointments: action.payload,
      };
    case GET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    default:
      return state;
  }
};
