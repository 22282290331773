import React, { useReducer } from 'react';
import AppointmentContext from './appointmentContext';
import AppointmentReducer from './appointmentReducer';
import { apiCall } from '../../common/api';
import { response } from '../common';

import {
  CLEAR_RESPONSE,
  RESPONSE_STATUS,
  GET_AVAILABLE_APPOINTMENT,
  GET_MY_APPOINTMENTS,
  GET_APPOINTMENT_DETAILS,
} from './appointmentTypes';

const AppointmentState = (props) => {
  const initialState = {
    search_allproducts: {
      cartrecords: [],
      noncartrecords: [],
    },
    available_appointments: {
      records: [],
    },
    myAppointments: {
      records: [],
      totalRecords: 0,
      setDisp: '',
    },
    appointmentDetails: {
      records: [],
      generalInfo: {},
    },
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(AppointmentReducer, initialState);
  let resp = new response(dispatch, RESPONSE_STATUS);

  const getAvailbleAppointments = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'checkAppointments', formData, '', 'appointment'),
      ]);
      const from = 'cartsearch';
      if (res.data.status === 'success') {
        dispatch({
          type: GET_AVAILABLE_APPOINTMENT,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, from);
      } else {
        resp.commonErrorResponse(from);
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const getMyAppointments = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getMyAppointments', formData, '', 'appointment'),
      ]);
      if (res.data.status === 'success') {
        dispatch({
          type: GET_MY_APPOINTMENTS,
          payload: {
            records: res.data.data.responseData.records.length
              ? res.data.data.responseData.records
              : [],
            totalRecords: res.data.data.responseData.totalRecords
              ? res.data.data.responseData.totalRecords
              : 0,
            setDisp: res.data.data.responseData.setDisp ? res.data.data.responseData.setDisp : 0,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonResponse(res.data, '');
      } else {
        resp.commonErrorResponse('');
      }
    } catch (err) {
      resp.commonErrorResponse('cartsearch');
    }
  };

  const getAppointmentDetails = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getAppointmentDetails', formData, '', 'appointment'),
      ]);
      console.log(res.data);
      if (res.data.status === 'success') {
        console.log('API RESPONSE: ', res.data.data.appointmentDetails.length);
        dispatch({
          type: GET_APPOINTMENT_DETAILS,
          payload: {
            records: res.data.data.appointmentDetails.length
              ? res.data.data.appointmentDetails
              : [],
            generalInfo: res.data.data.generalAppointmentInfo,
          },
        });
      } else if (res.data.status === 'error') {
        resp.commonErrorResponse('appointmentDetails');
      }
    } catch (err) {
      console.log(err);
      resp.commonErrorResponse('appointmentDetails');
    }
  };

  const cancelAppointment = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'cancel', formData, '', 'appointment')]);
      console.log(res.data);
      let from = 'cancelAppointment';
      if (res.data.status === 'success') {
        resp.commonResponse(res.data, from);
      }
    } catch (err) {
      console.log(err);
      resp.commonErrorResponse('cancelAppointment');
    }
  };

  const cancelAppointmentItem = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'appointment')]);
      console.log(res.data);
      let from = 'cancelAppointment';
      if (res.data.status === 'success') {
        resp.commonResponse(res.data, from);
      }
    } catch (err) {
      console.log(err);
      resp.commonErrorResponse('cancelAppointment');
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  return (
    <AppointmentContext.Provider
      value={{
        available_appointments: state.available_appointments,
        myAppointments: state.myAppointments,
        responseStatus: state.responseStatus,
        appointmentDetails: state.appointmentDetails,
        getAvailbleAppointments,
        getMyAppointments,
        getAppointmentDetails,
        cancelAppointment,
        cancelAppointmentItem,
        clearResponse,
      }}
    >
      {props.children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentState;
