import { CLEAR_RESPONSE, RESPONSE_STATUS } from './autoPilotTypes';

export default (state, action) => {
  switch (action.type) {
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    default:
      return state;
  }
};
